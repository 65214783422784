import { Dialog as HexaDialog } from "@hexa-ui/components";
import React from "react";
import { ActionsContainer } from "./styles";
import { DialogProps } from "./types";

export const { Close } = HexaDialog;

export const Dialog = ({
  actions,
  ...otherProps
}: DialogProps): JSX.Element => {
  return (
    <HexaDialog.Root
      {...otherProps}
      actions={
        actions && (
          <ActionsContainer>
            {actions?.btnSecundary}
            {actions?.btnPrimary}
          </ActionsContainer>
        )
      }
    />
  );
};
