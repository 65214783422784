import { useEnvService, useHasPermission } from "admin-portal-shared-services";
import { Modal } from "components/Modal/Modal";
import { SegmentService } from "helpers";
import { SegmentFormatData } from "helpers/segmentService/segmentFormatData";
import { Env } from "interfaces/IEnv";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { postAuditLogEditUserAccounts } from "services/auditLog/AuditLogService";
import { UserService } from "services/user/UserService";
import { IUserDetails } from "services/bff-service";
import * as StyledModal from "../../../../components/Modal/styles";
import * as Styled from "./Styles";

type Props = {
  user: IUserDetails;
  userId: string;
  accounts: string[] | [];
  accountsUserAssociated: number;
  onComplete: (result: PromiseSettledResult<any>[]) => void;
  country: string;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
};

export type IDialogTexts = {
  title: string;
  body: string;
  confirmButton: string;
};

export interface IDisassociateParam {
  userId: string;
  value: string;
}

export const DisassociateButton = ({
  user,
  userId,
  onComplete,
  accounts,
  accountsUserAssociated,
  country,
  isLoading,
  setIsLoading,
}: Props): JSX.Element => {
  const { formatMessage } = useIntl();
  const canDisassociteAccountsOfUser = useHasPermission(
    "IdentityFE.UserPOC.Write"
  );
  const userService = new UserService();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [dialogTexts, setDialogTexts] = React.useState<IDialogTexts>();
  const envService = useEnvService();

  useEffect(() => {
    setDialogTexts({
      title: formatMessage(
        { id: "disassociateButton_dialog_title" },
        { amountOfAccounts: accounts.length }
      ),
      body: formatMessage(
        { id: "disassociateButton_dialog_body" },
        { amountOfAccounts: accounts.length }
      ),
      confirmButton: formatMessage({
        id: "disassociateButton_dialog_confirmButton",
      }),
    });
  }, [accounts, formatMessage]);

  const convertUserAndAccountsToPayload = (): IDisassociateParam[] => {
    if (accounts && userId) {
      return accounts.map((accountId) => ({
        userId,
        value: accountId,
      }));
    }
    return [];
  };

  const disassociate = async (): Promise<any> => {
    setIsLoading(true);
    const payloads = convertUserAndAccountsToPayload();
    const results = await userService.userDisassociate(country, payloads);
    const analyticsRemovalData =
      SegmentFormatData.formatAccountRemovalSubmitted(user, accounts.length);
    SegmentService.accountRemovalSubmitted(analyticsRemovalData);
    setIsOpen(false);
    if (results && results.length > 0) {
      const userAccountsMetadata = {
        wasApprovedBySystem: envService.getEnv() !== Env.PROD,
        account: { deleted: results.map((result) => result.value) },
      };
      await postAuditLogEditUserAccounts(
        country,
        userAccountsMetadata,
        user.id
      );
      SegmentService.accountRemovalSubmissionResult({
        ...analyticsRemovalData,
        associated_accounts:
          (analyticsRemovalData.associated_accounts as number) - results.length,
        removal_fail_count: accounts.length - results.length,
      });
    } else {
      SegmentService.accountRemovalSubmissionResult({
        ...analyticsRemovalData,
        associated_accounts: analyticsRemovalData.associated_accounts as number,
        removal_fail_count: accounts.length,
      });
    }
    onComplete(results);
  };

  const handleCancelDisassociteAccount = () => {
    setIsOpen(false);
    SegmentService.accountRemovalCanceled();
  };

  const handleOpenModalDisassociateAccount = () => {
    setIsOpen(true);
    SegmentService.accountRemovalStarted({
      associated_accounts: accountsUserAssociated,
      selected_accounts: accounts.length,
    });
  };

  return (
    <>
      {canDisassociteAccountsOfUser && accounts.length > 0 && (
        <Styled.StyledButton
          onClick={handleOpenModalDisassociateAccount}
          data-testid="disassociate-button"
          name="disassociate"
          disabled={isLoading}
        >
          <Styled.StyledButtonText>
            {formatMessage({ id: "userDetailsPage_disassociateButton" })}
          </Styled.StyledButtonText>
        </Styled.StyledButton>
      )}

      <Modal
        modalTitle={dialogTexts?.title || ""}
        data-testid="disassociate-modal"
        isModalOpen={isOpen}
        handleButtonCloseModal={() => handleCancelDisassociteAccount()}
      >
        <StyledModal.ModalSubtitle>
          {dialogTexts?.body}
        </StyledModal.ModalSubtitle>
        <StyledModal.ModalFooter>
          <StyledModal.ModalButton
            onClick={() => handleCancelDisassociteAccount()}
            data-testid="cancel-button"
            variant="outline"
            disabled={isLoading}
          >
            {formatMessage({ id: "disassociateButton_dialog_cancelButton" })}
          </StyledModal.ModalButton>
          <StyledModal.ModalButton
            onClick={disassociate}
            data-testid="confirm-button"
            disabled={isLoading}
          >
            {dialogTexts?.confirmButton}
          </StyledModal.ModalButton>
        </StyledModal.ModalFooter>
      </Modal>
    </>
  );
};
