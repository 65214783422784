import { ApplicationKeys } from "helpers/Applications/applications";
import React from "react";
import { useIntl } from "react-intl";
import { AppFilter } from "./Filters/AppFilter";
import * as Styled from "./styles";

interface FilterApplicationsProps {
  onChange: (apps: ApplicationKeys[]) => void;
  selectedApps: ApplicationKeys[];
}

export function FilterApplications({
  onChange,
  selectedApps,
}: FilterApplicationsProps): JSX.Element {
  const intl = useIntl();
  return (
    <Styled.StyledFilterContainer>
      <Styled.StyledFilterContainerHeader>
        <Styled.StyledFilterHeaderTitle>
          {intl.formatMessage({ id: "userPage_filterBy" })}
        </Styled.StyledFilterHeaderTitle>
      </Styled.StyledFilterContainerHeader>
      <Styled.StyledFiltersContainer>
        {!selectedApps.length && (
          <Styled.StyledAlertError
            type="error"
            message="Please select at last one application to continue with the search"
          />
        )}
        <AppFilter values={selectedApps} onChange={onChange} />
      </Styled.StyledFiltersContainer>
    </Styled.StyledFilterContainer>
  );
}
