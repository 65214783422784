export interface MetadataBase {
  wasApprovedBySystem: boolean;
  actionOwnerName?: string;
}
export interface UserStatusMetadata extends MetadataBase {
  currentStatus: "ACTIVE" | "INACTIVE";
}
export interface UserEditMetadata extends MetadataBase {
  permissionGroup: {
    added: string[];
    deleted: string[];
  };
  supportedCountry: {
    added: string[];
    deleted: string[];
  };
  vendorId: {
    added: string[];
    deleted: string[];
  };
  deliveryCenterId: {
    added: string[];
    deleted: string[];
  };
  logisticOperatorId: {
    added: string[];
    deleted: string[];
  };
  routeId?: string;
  bdrId?: string;
}

export interface UserAccountsMetadata extends MetadataBase {
  account: {
    deleted: string[];
  };
}

export type Metadata =
  | UserStatusMetadata
  | UserEditMetadata
  | UserAccountsMetadata;

export enum ActionOperation {
  UPDATE = "UPDATE",
  INSERT = "INSERT",
  DELETE = "DELETE",
  VIEW = "VIEW",
}

export interface Action {
  metadata: Metadata | { [key: string]: string };
  operation: ActionOperation;
}

export interface Target {
  entity: "USERS";
  entityId: string;
}

export interface AuditLog {
  action: Action;
  target: Target;
}

export interface CreateAuditLogParams {
  metadata: Metadata;
  operation: ActionOperation;
  editedUserId?: string;
}

export type Entity = "USERS" | "ACCOUNTS";

export interface IGetLogsRequestParams {
  entity: Entity;
  entityId: string;
  page?: string;
  pageSize?: string;
}

export interface IAudit {
  content: {
    action: {
      metadata: Record<string, string>;
      operation: ActionOperation;
    };
    country: string | null;
    creationDate: string;
    doneBy: {
      appId: string;
      userId: string;
    };
    id: string;
    target: {
      entity: Entity;
      entityId: string;
    };
  }[];
}

export interface IPostAuditLogUserStatus {
  wasApprovedBySystem: boolean;
  userCountry: string;
  status: "ACTIVE" | "INACTIVE";
  editedUserId: string;
}
