import { MessageMap } from "./types";

export const es419: MessageMap = {
  disassociateButton_dialog_body:
    "Este usuario ya no podrá comprar para {amountOfAccounts, plural, one {esta cuenta.} other {las cuentas seleccionadas.}}",
  disassociateButton_dialog_cancelButton: "Cancelar",
  disassociateButton_dialog_confirmButton: "Eliminar",
  disassociateButton_dialog_title:
    "¿Eliminar {amountOfAccounts, plural, one {esta cuenta} other {estas cuentas}}?",
  editUSerModal_fieldIsRequiredError: "Este campo es obligatorio",
  editUserModal_addNewSupervisorButton: "Agrega supervisor",
  editUserModal_cancelButton: "Cancelar",
  editUserModal_invalidEmailError: "Ingresa un correo electrónico válido.",
  editUserModal_managerEqualSupervisorError:
    "No se pueden asignar al mismo usuario los roles de supervisor y administrador.",
  editUserModal_managerFieldLabel: "Gestionado por",
  editUserModal_managerFieldPlaceholder:
    "Inserte el correo electrónico del gerente",
  editUserModal_nonExistentEmailError:
    "Este correo electrónico no existe o no se pudo validar.",
  editUserModal_optionalField: "opcional",
  editUserModal_pendingChangesSnackbar: "Hay cambios pendientes",
  editUserModal_requiredField: "requerido",
  editUserModal_saveButton: "Guardar cambios",
  editUserModal_supervisorFieldLabel: "Supervisor",
  editUserModal_supervisorFieldPlaceholder:
    "Inserte el correo electrónico del supervisor",
  editUserModal_title: "Editar información del usuario",
  test_missing: "Solo la opción en español disponible",
  test_translate: "Traducción",
  unblockUserModal_cancelButton: "Cancelar",
  unblockUserModal_subtitle:
    "{userName} obtendrá acceso a las plataformas BEES.",
  unblockUserModal_title: "¿Desbloquear acceso del usuario? ",
  unblockUserModal_unblockButton: "Desbloquear",
  userAccounts_emptyState: "No cuentas asociadas a este usuario.",
  userAccounts_list_accountId: "Account ID {accountId}",
  userAccounts_selectAll: "Seleccionar todo",
  userAccounts_snackbarFailureRemoveAccounts:
    "{amountOfAccounts, plural, one {# cuenta} other {# cuentas}} no pudieron ser eliminadas",
  userAccounts_snackbarFailureToRemoveAnyAccounts:
    "La cuenta(s) no se pudo eliminar",
  userAccounts_snackbarSuccessfullyRemoveAccounts:
    "{amountOfAccounts, plural, one {# cuenta eliminada} other {# cuentas eliminadas}} exitosamente",
  userAccounts_title: "Cuentas asociadas",
  userDetailsPage_blockButton: "Bloquear",
  userDetailsPage_customer: "Cliente",
  userDetailsPage_disassociateButton: "Eliminar",
  userDetailsPage_dtc: "DTC",
  userDetailsPage_editButton: "Editar",
  userDetailsPage_editUserModalCancelButton: "Cancelar",
  userDetailsPage_editUserModalDescriptionCompanyIds:
    "Utiliza comas para separar valores.",
  userDetailsPage_editUserModalLabelCompanyIds: "IDs de la compañía",
  userDetailsPage_editUserModalLabelPermissionGroups: "Permisos de los grupos",
  userDetailsPage_editUserModalLabelSupportedCountries: "Países habilitados",
  userDetailsPage_editUserModalSaveChangesButton: "Guardar cambios",
  userDetailsPage_hub: "ONE",
  userDetailsPage_modalBlockUserBlockButton: "Bloquear",
  userDetailsPage_modalBlockUserCancelButton: "Cancelar",
  userDetailsPage_modalBlockUserDescription:
    "{userName} perderá acceso a todas las plataformas BEES.",
  userDetailsPage_modalBlockUserTitle: "¿Bloquear acceso del usuario?",
  userDetailsPage_savedChangesSnackbar:
    "Cambios en la información del usuario guardados.",
  userDetailsPage_snackbarFailureBlockUser:
    "El usuario no pudo ser bloqueado en este momento",
  userDetailsPage_snackbarFailureUnblockUser:
    "El usuario no pudo ser desbloqueado en este momento",
  userDetailsPage_snackbarSuccessufullyBlockUser:
    "Usuario bloqueado exitosamente",
  userDetailsPage_snackbarSuccessufullyUnblockUser:
    "Usuario desbloqueado exitosamente",
  userDetailsPage_title: "Detalles del usuario",
  userDetailsPage_unblockButton: "Desbloquear",
  userDetailsPage_userInfoLabelBdrId: "BDR ID",
  userDetailsPage_userInfoLabelCompanyIds: "IDs de la compañía",
  userDetailsPage_userInfoLabelCountry: "País",
  userDetailsPage_userInfoLabelCreatedAt: "Creado el",
  userDetailsPage_userInfoLabelDeliveryCenterIds: "Delivery center ID",
  userDetailsPage_userInfoLabelEmail: "Email",
  userDetailsPage_userInfoLabelLastInvitedAt: "Última invitación el",
  userDetailsPage_userInfoLabelLogisticOperatorIds: "Logistic operator ID",
  userDetailsPage_userInfoLabelPermissionGroups: "Permisos de los grupos",
  userDetailsPage_userInfoLabelPhone: "Teléfono",
  userDetailsPage_userInfoLabelRegisteredAt: "Registrado el",
  userDetailsPage_userInfoLabelRouteId: "Route ID",
  userDetailsPage_userInfoLabelSupportedCountries: "Países habilitados",
  userDetailsPage_userInfoLabelUserId: "ID del usuario",
  userDetailsPage_userInfoLabelUserStatus: "Status del usuario",
  userDetailsPage_userStatus_ACTIVE: "Activo",
  userDetailsPage_userStatus_BLOCKED: "Bloqueado",
  userDetailsPage_userStatus_DEACTIVATED: "Desactivado",
  userDetailsPage_userStatus_PENDING: "Pendiente",
  userDetailsPage_userInfoTooltipCopy: "Copiar",
  userPage_addUsers: "Agregar usuarios",
  userPage_administrator: "Administrador",
  userPage_allCountries: "Todos los países",
  userPage_allRecentActivities: "Todas las actividades recientes",
  userPage_category: "Categoría",
  userPage_clearAll: "Borrar todos",
  userPage_country: "País",
  userPage_customer: "Cliente",
  userPage_dragFileHere: "Arrastrar un archivo aquí o",
  userPage_exportCSV: "Exportar CSV",
  userPage_filterAccessDenied:
    "No tienes acceso a uno o más de los filtros aplicados. Todos los filtros han sido reseteados.",
  userPage_filterBy: "Filtrar por",
  userPage_loadingResults: "Cargando resultados...",
  userPage_noFiltersApplied: "No se aplicaron filtros",
  userPage_recentSearches: "Búsquedas recientes",
  userPage_results:
    "Mostrando {amountOfResults, plural, one {# resultado} other {# resultados}}",
  userPage_search: "Buscar por ID, nombre, e-mail o teléfono",
  userPage_searchMessage:
    "Utiliza el campo de búsqueda encima para encontrar un usuario específico.",
  userPage_selectFile: "Seleccionar",
  userPage_settings: "Preferencias",
  userPage_showMore: "Mostrar más",
  userPage_tableColumnEmailUserList: "Email",
  userPage_tableColumnNameUserList: "Nombre",
  userPage_tableColumnPhoneUserList: "Teléfono",
  userPage_tableColumnStatusActiveUserList: "Activo",
  userPage_tableColumnStatusBlockedUserList: "Bloqueado",
  userPage_tableColumnStatusUserList: "Status",
  userPage_title: "Usuarios",
  userPage_wholesalerBranch: "Mayorista/Filial",
  userTableEmpty_description:
    'No se encontraron usuarios con "{searched}". Intentá cambiando los filtros. ',
  userTableEmpty_header: "No se encontraron resultados.",
};
