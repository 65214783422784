import axios, { AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";
import { getApiHost } from "./host/HostService";

export interface GetRegistrationLinkParams {
  inviteId: string;
  userId: string;
  userCountry: string;
  userApp: string;
}

interface IResendCustomerInviteParams {
  userId: string;
  country: string;
  appName: string;
  invitedUserName: string;
  requesterUserName: string;
  wholesalerName: string;
  accountsName: string[];
  wholesalerId: string;
}

interface IResendAdminInviteParams {
  userId: string;
  country: string;
  appName: string;
  invitedUserName: string;
  requesterUserName: string;
}

interface IGetUserDetailsParams {
  country?: string;
  appName: string;
  userId: string;
}

export enum UserTypes {
  Customer = "CUSTOMER",
  Admin = "ADMIN",
}

export type UserStatus = "BLOCKED" | "PENDING" | "ACTIVE";

export interface IUserDetails {
  readonly id: string;
  readonly displayName: string;
  readonly country: string;
  readonly email: string;
  readonly phone: string;
  readonly status: UserStatus;
  readonly createdAt: string;
  readonly registeredAt: string;
  readonly lastInvitedAt: string;
  readonly accounts: string[];
  readonly supportedCountries: string[];
  readonly application: string;
  readonly accountEnabled: boolean;
  readonly groups: string[];
  readonly bdrId: string;
  readonly vendors: string[];
  readonly routeId: string;
  readonly logisticOperatorIds: string[];
  readonly deliveryCenterIds: string[];
  readonly inviteId?: string;
}

export class BffService {
  private readonly axiosInstance = axios.create({
    headers: {
      Authorization: localStorage.getItem("authHeader"),
    },
  });

  private readonly baseUrl: string;

  constructor() {
    this.baseUrl = process.env.API_BASE_URL || "/api/identity-bff/v2";
  }

  async getRegistrationLink(
    params: GetRegistrationLinkParams
  ): Promise<string> {
    const { data } = await this.axiosInstance.get<string>(
      `${this.baseUrl}/user-management/registration-link`,
      {
        headers: {
          requestTraceId: uuidv4(),
        },
        params: {
          inviteId: params.inviteId,
          userId: params.userId,
          userCountry: params.userCountry,
          userApp: params.userApp,
        },
      }
    );
    return data;
  }

  async resendCustomerInvite(
    params: IResendCustomerInviteParams
  ): Promise<string> {
    const { data } = await this.axiosInstance.post<string>(
      `${this.baseUrl}/user-management/resend-customer-invite/${params.userId}`,
      {
        invitedUserName: params.invitedUserName,
        requesterUserName: params.requesterUserName,
        wholesalerName: params.wholesalerName,
        accountsName: params.accountsName,
        wholesalerId: params.wholesalerId,
      },
      {
        headers: {
          requestTraceId: uuidv4(),
        },
        params: {
          country: params.country,
          appName: params.appName,
        },
      }
    );
    return data;
  }

  async resendAdminInvite(params: IResendAdminInviteParams): Promise<string> {
    const { data } = await this.axiosInstance.post<string>(
      `${this.baseUrl}/user-management/resend-admin-invite/${params.userId}`,
      {
        invitedUserName: params.invitedUserName,
        requesterUserName: params.requesterUserName,
      },
      {
        headers: {
          requestTraceId: uuidv4(),
        },
        params: {
          country: params.country,
          appName: params.appName,
        },
      }
    );
    return data;
  }

  async getUserDetails(
    params: IGetUserDetailsParams
  ): Promise<AxiosResponse<IUserDetails>> {
    const { country, userId, appName } = params;
    const BASE_URL = getApiHost({ appName, country });
    const response = await this.axiosInstance.get<IUserDetails>(
      `${BASE_URL}/v2/user-management/details/${userId}`,
      {
        headers: {
          requestTraceId: uuidv4(),
        },
        params: {
          country,
          appName,
        },
      }
    );
    return response;
  }
}

export function useBFFService() {
  return new BffService();
}
