/* eslint-disable @typescript-eslint/naming-convention */ //This is a file created to supply user-mfe needs. Can be deleted in the future refactor
export enum Countries {
  Ar = "AR",
  Br = "BR",
  Ca = "CA",
  Dr = "DR",
  Ec = "EC",
  Hn = "HN",
  Mx = "MX",
  Pa = "PA",
  Pe = "PE",
  Py = "PY",
  Sv = "SV",
  Tz = "TZ",
  Us = "US",
  Uy = "UY",
  Za = "ZA",
  Null = "null",
}

export enum FilterCriteria {
  Application = "APPLICATION",
  Country = "COUNTRY",
  Null = "null",
}

export enum ListType {
  Users = "USERS",
  Null = "null",
}

export enum ScreenName {
  Accounts = "ACCOUNTS",
  AccountDetails = "ACCOUNT_DETAILS",
  AdminUserInvitation = "ADMIN_USER_INVITATION",
  Bulk = "BULK",
  CustomerUserInvitation = "CUSTOMER_USER_INVITATION",
  Users = "USERS",
  UserDetails = "USER_DETAILS",
  Null = "null",
  UsersChart = "USERS_CHART",
}

export enum SearchCriteria {
  Email = "EMAIL",
  Id = "ID",
  Name = "NAME",
  Other = "OTHER",
  Phone = "PHONE",
  Null = "null",
}

export enum UserStatus {
  Active = "ACTIVE",
  Blocked = "BLOCKED",
  ToBeOnboarded = "TO BE ONBOARDED",
  Null = "null",
}

export enum TabName {
  Completed = "COMPLETED",
  Pending = "PENDING",
  Null = "null",
}

export enum CopiedCriteria {
  Email = "EMAIL",
  Id = "ID",
  Phone = "PHONE",
  Null = "null",
}

export enum UserType {
  Customer = "CUSTOMER",
  Hub = "HUB",
  Null = "null",
}

export enum AppFilter {
  AdminPortal = "ADMIN_PORTAL",
  All = "ALL",
  Cmsadmin = "CMSADMIN",
  Deliveradmin = "DELIVERADMIN",
  Force = "FORCE",
  Oncustomer = "ONCUSTOMER",
  Social = "SOCIAL",
  Supplier = "SUPPLIER",
  Null = "null",
}

export enum FileType {
  EditAdminUser = "EDIT_ADMIN_USE",
  ManageCustomerUser = "MANAGE_CUSTOMER_USER",
  Null = "null",
}

export interface InformationItem {
  /**
   * The number of associated accounts. Sample: '1, 3, 5'
   */
  accounts_associated: number | null;
  /**
   * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
   */
  user_email: string | null;
  /**
   * The user phone number in the given context. Samples: +5519999841766
   */
  user_phone: string | null;
  /**
   * The user status in a given context. Permitted values. Samples: Active, To be onboarded, Blocked
   */
  user_status: UserStatus;
}
export enum ScreenName1 {
  Accounts1 = "ACCOUNTS",
  AccountDetails1 = "ACCOUNT_DETAILS",
  AdminUserInvitation1 = "ADMIN_USER_INVITATION",
  Bulk1 = "BULK",
  CustomerUserInvitation1 = "CUSTOMER_USER_INVITATION",
  Permissions = "PERMISSIONS",
  Users1 = "USERS",
  UserDetails1 = "USER_DETAILS",
  Null1 = "null",
}

export interface IChartNavigation {
  isManager: boolean;
  isReporter: boolean;
}

export interface IReporterData {
  country: string;
  email: string;
}

//These interfaces are used in the segment and should not be deleted.

export enum EUserTypeEnum {
  Customer = "CUSTOMER",
  "BEES Force" = "FORCE",
  "BEES One" = "ONE",
  DTC = "DTC",
}

export interface ICountryFiltered {
  country: string;
  userType: string | null;
}

export interface IUserClicked {
  clickedItemPosition: number;
  listedItems: number | null;
}

export interface IUserInformation {
  user_email: string | null;
  user_phone: string | null;
  user_status: string | null;
}

export interface IUserListViewed {
  country: string;
  information: IUserInformation[] | null;
  listedItems: number | null;
  searchCriteria: string;
  userType: string | null;
}
export interface IUserSearchCriteria {
  searchCriteria: string;
  userType: string | null;
}

export type UserApplications = "CUSTOMER" | "FORCE" | "ONE" | "DTC" | null;
