import { Box } from "@material-ui/core";
import styled from "styled-components";

export const StyledContainer = styled(Box)`
  background-color: white;
  border-radius: 2rem;
  box-shadow: 0 0.188rem 0.375rem rgba(0, 0, 0, 0.2);
  display: flex;
  height: calc(100vh - 9.375rem);
  width: -webkit-fill-available;
  overflow: hidden;
  flex-direction: row;
`;
