import { styled } from "@bees/vision-tokens";
import { Alert as AlertHexa } from "@hexa-ui/components";

export const Alert = styled(AlertHexa, {
  margin: "$4 0",
  width: "100%",
});

export const InputFileContainer = styled("div", {
  width: "27rem",
  height: "19.75rem",
  margin: "0 auto",
  display: "flex",
  justifyContent: "center",
});

export const DownloadTemplate = styled("a", {
  position: "absolute",
  bottom: "1.615rem",
  height: "24px",
  width: "156px",
  color: "$semanticInfoText",
  fontFamily: "Work Sans",
  fontSize: "16px",
  letterSpacing: "0.1px",
  lineHeight: "24px",
});

export const VendorSelectorWrapper = styled("div", {
  "&> *:not(:last-child)": {
    marginBottom: "$4",
  },
  a: {
    color: "$semanticInfoText",
  },
  "[aria-disabled=true]": {
    color: "$neutral50",
    pointerEvents: "none",
    textDecoration: "none",
  },
});
