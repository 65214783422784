import { styled } from "@bees/vision-tokens";
import { AuditLogsItemTypes } from "./types";

const TYPE_INDICATOR_COLORS = {
  [AuditLogsItemTypes.Insert]: "$semanticSuccessBasis",
  [AuditLogsItemTypes.Delete]: "$semanticErrorBasis",
  [AuditLogsItemTypes.Update]: "$semanticInfoBasis",
};

const PARAGRAPH_COLORS = {
  primary: "$neutral100",
  secondary: "$neutral90",
  terciary: "$neutral50",
};

export const ItemWrapper = styled("div", {
  display: "flex",
  width: "100%",
  marginBottom: "$4",
});

export const TypeIndicator = styled("div", {
  width: "0.25rem",
  minWidth: "0.25rem",
  borderRadius: "$2",
  variants: {
    type: {
      [AuditLogsItemTypes.Insert]: {
        backgroundColor: TYPE_INDICATOR_COLORS[AuditLogsItemTypes.Insert],
      },
      [AuditLogsItemTypes.Delete]: {
        backgroundColor: TYPE_INDICATOR_COLORS[AuditLogsItemTypes.Delete],
      },
      [AuditLogsItemTypes.Update]: {
        backgroundColor: TYPE_INDICATOR_COLORS[AuditLogsItemTypes.Update],
      },
    },
  },
});

export const ItemContent = styled("div", {
  padding: "0 0.5rem",
  "p:not(:last-child)": {
    marginBottom: "0.5rem",
  },
});

export const Paragraph = styled("p", {
  variants: {
    type: {
      primary: { color: PARAGRAPH_COLORS.primary, fontWeight: "bold" },
      secondary: { color: PARAGRAPH_COLORS.secondary },
      terciary: { color: PARAGRAPH_COLORS.terciary },
    },
  },
});
