import React from "react";
import { useIntl } from "react-intl";
import { CountryFilter } from "./Filters/CountryFilter";
import * as Styled from "./styles";

interface Props {
  countryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  country: string;
}

export function Filter({ countryChange, country }: Props): JSX.Element {
  const intl = useIntl();

  return (
    <Styled.StyledFilterContainer>
      <Styled.StyledFilterContainerHeader>
        <Styled.StyledFilterHeaderTitle>
          {intl.formatMessage({ id: "userPage_filterBy" })}
        </Styled.StyledFilterHeaderTitle>
      </Styled.StyledFilterContainerHeader>
      <Styled.StyledFiltersContainer>
        <CountryFilter value={country} onHandleChange={countryChange} />
      </Styled.StyledFiltersContainer>
    </Styled.StyledFilterContainer>
  );
}
