import { Skeleton } from "@material-ui/lab";
import styled from "styled-components";

export const StyledRectangleSkeleton = styled(Skeleton)`
  transform: scale(1, 1);
  border-radius: 0.5rem;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`;

export const StyledItemRowSkeleton = styled.div`
  transform: scale(1, 1);
  padding: 0.5rem 1.5rem;
  justify-content: space-between;
  display: flex;
`;
