import { CircularProgress, Dialog, FormControl } from "@material-ui/core";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import styled, { css } from "styled-components";

export const ChipsContainer = styled.div`
  display: "flex";
  width: "95%";
  overflow: "hidden";
`;

interface IButton {
  variant?: string;
}

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalTitle = styled.h2`
  font: 600 2rem/2.5rem "Barlow", sans-serif;
  color: rgba(0, 0, 0, 0.9);
  margin-bottom: 1.5rem;
`;

export const ModalFooter = styled.div`
  display: flex;
  place-content: flex-end;
  cursor: pointer;
  margin-top: 2rem;
`;

export const ModalButtonClose = styled.button`
  width: 2rem;
  height: 2rem;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.15);
  cursor: pointer;

  & svg {
    color: rgba(0, 0, 0, 0.9);
    pointer-events: none;
  }
`;

const setVariantOutline = (variant: string | undefined) =>
  variant === "outline" &&
  css`
    color: #000;
    border: 0.063rem solid #000000;
    border-radius: 1.5rem;
    background-color: #ffffff;
  `;

export const ModalButton = styled.button<IButton>`
  height: 3rem;
  border: 0;
  border-radius: 1.5rem;
  display: flex;
  place-items: center;
  font: 500 1.125rem/1.5rem "Work Sans", sans-serif;
  color: #fff;
  background-color: #000000;
  padding: 0 1.5rem;
  cursor: pointer;
  text-decoration: none;

  &:first-child {
    margin-right: 1rem;
  }

  &[disabled] {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    color: rgba(0, 0, 0, 0.55);
    cursor: not-allowed;
  }

  ${({ variant }) => setVariantOutline(variant)}
`;

export const ModalBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
`;

export const DialogEditUser = styled(Dialog)`
  font-size: 16px;
  & .admin-portal-identity-user-mfe-MuiPaper-rounded {
    width: 52.25rem;
    height: auto;
    border-radius: 1.5rem;
    box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.16);
    padding: 2rem;
    position: static;
  }
`;

export const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1.5rem;
  pre {
    font-family: '"Work Sans"';
    text-wrap: wrap;
  }
`;

export const LoadingBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const Loading = styled(CircularProgress)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  color: #ffff00;
`;

export const FormContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

export const FormControlField = styled(FormControl)<{ width?: string }>`
  margin-right: 1rem;

  & > * {
    margin-bottom: 24px;
  }

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
`;

export const Autocomplete = styled(MuiAutocomplete)`
  min-width: 25rem;
`;

interface IButton {
  variant?: string;
}
