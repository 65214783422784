import { useAuthenticationService as authService } from "admin-portal-shared-services";
import axios, { AxiosInstance } from "axios";
import { getCountry, getToken } from "helpers";
import { getApiHost } from "services/host/HostService";
import { v4 } from "uuid";
import {
  ActionOperation,
  AuditLog,
  CreateAuditLogParams,
  IAudit,
  IGetLogsRequestParams,
  IPostAuditLogUserStatus,
  UserAccountsMetadata,
  UserEditMetadata,
  UserStatusMetadata,
} from "./types";

const axiosInstance: AxiosInstance = axios.create();

const getHeaderParams = (country = getCountry()) => ({
  requestTraceId: v4(),
  Authorization: getToken(),
  country,
});

export const createAuditLog = ({
  metadata,
  operation,
  editedUserId,
}: CreateAuditLogParams): AuditLog => {
  const metadataEncoded: { [key: string]: string } = {};
  const metadataKeys = Object.keys(metadata);
  const metadataValues = Object.values(metadata);

  metadataKeys.forEach((key, index) => {
    const encoder = typeof metadataValues[index] === "object";
    metadataEncoded[key] = encoder
      ? JSON.stringify(metadataValues[index])
      : String(metadataValues[index]);
  });
  return {
    action: {
      metadata: metadataEncoded,
      operation,
    },
    target: {
      entity: "USERS",
      entityId: editedUserId,
    },
  };
};

const postAuditLogUserStatus = async (
  params: IPostAuditLogUserStatus
): Promise<boolean> => {
  const { userCountry, editedUserId, status, wasApprovedBySystem } = params;
  const headers = getHeaderParams(userCountry);

  const metadata: UserStatusMetadata = {
    currentStatus: status,
    wasApprovedBySystem,
  };

  const auditLog: AuditLog = createAuditLog({
    metadata,
    operation: ActionOperation.UPDATE,
    editedUserId,
  });

  const BASE_URL = getApiHost();
  const URL = `${BASE_URL}/audit`;

  try {
    const response = await authService()
      .enhancedAxios(axiosInstance)
      .post(URL, auditLog, { headers });
    return response.status === 202;
  } catch {
    return false;
  }
};

const postAuditLogEditUser = async (
  userCountry: string,
  metadata: UserEditMetadata,
  editedUserId?: string
): Promise<boolean> => {
  const headers = getHeaderParams(userCountry);

  const auditLog: AuditLog = createAuditLog({
    metadata,
    operation: ActionOperation.UPDATE,
    editedUserId,
  });
  const BASE_URL = getApiHost();
  const URL = `${BASE_URL}/audit`;

  try {
    const response = await authService()
      .enhancedAxios(axiosInstance)
      .post(URL, auditLog, { headers });
    return response.status === 202;
  } catch {
    return false;
  }
};

const postAuditLogEditUserAccounts = async (
  userCountry: string,
  metadata: UserAccountsMetadata,
  editedUserId?: string
) => {
  const headers = getHeaderParams(userCountry);

  const auditLog: AuditLog = createAuditLog({
    metadata,
    operation: ActionOperation.UPDATE,
    editedUserId,
  });
  const BASE_URL = getApiHost();
  const URL = `${BASE_URL}/audit`;

  try {
    const response = await authService()
      .enhancedAxios(axiosInstance)
      .post(URL, auditLog, { headers });
    return response.status === 202;
  } catch {
    return false;
  }
};

const getAuditLogs = async (
  params: IGetLogsRequestParams
): Promise<IAudit | undefined> => {
  const BASE_URL = getApiHost();
  const headers = getHeaderParams();

  try {
    const response = await authService()
      .enhancedAxios(axiosInstance)
      .get(`${BASE_URL}/audit`, { headers, params });

    return response.data.data;
  } catch {
    return undefined;
  }
};

export {
  axiosInstance,
  getAuditLogs,
  postAuditLogEditUser,
  postAuditLogEditUserAccounts,
  postAuditLogUserStatus,
};
