import { TableCell, TableContainer } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import { StatusEnum } from "pages/UserDetails/Components/Status/types";
import styled, { css } from "styled-components";

interface StyledStatusProps {
  status: string;
}

const statusColor = css<StyledStatusProps>`
  ${(props) => {
    switch (props.status) {
      case StatusEnum.ACTIVE:
        return "#267a78";
      case StatusEnum.PENDING:
        return "#faff5f";
      case StatusEnum.BLOCKED:
        return "#C9201D";
      default:
        return "#424242";
    }
  }};
`;

export const StyledStatus = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${statusColor};
`;

export const StyledStatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const alternativeElement = css`
  & [data-avatar] {
    display: none;
  }

  & [data-checkbox] {
    display: block;
  }
`;

const highlightRow = css`
  background-color: #f0ecfc;
`;

export const StyledTableContainer = styled(TableContainer)`
  width: 100%;
  overflow-y: overlay;
  max-height: calc(100vh - 304px);
  height: calc(100vh - 304px);
  &::-webkit-scrollbar-track {
    margin-bottom: 8px;
  }
`;

export const StyledTable = styled(Table)`
  max-width: 100%;
  th {
    height: 36px;
    background-color: #f2f2f2;
    font: 600 16px/20px "Barlow";
    letter-spacing: normal;
    padding: 0 16px;

    &:first-child {
      text-align: center;
      padding: 0 28px;
    }

    & [data-checkbox] {
      svg {
        fill: #212121;
        color: #212121;
      }
    }
  }

  & tr:not(thead tr) {
    height: 64px;
  }

  & tr.active {
    ${alternativeElement}
    background-color: #F0ECFC;
  }
`;

export const StyledTableRow = styled(TableRow)<{ $isActive: boolean }>`
  transition: background-color 200ms linear;

  &:last-child {
    & td {
      border-bottom: 0;
    }
  }

  & [data-checkbox] {
    display: none;

    &:active,
    svg {
      fill: #212121;
      color: #212121;
    }
  }

  &:hover {
    ${alternativeElement}
    background-color: #e4e4e4;
    cursor: pointer;
  }

  ${({ $isActive }: { $isActive: boolean }) => {
    if ($isActive) return alternativeElement.concat(highlightRow);
    return null;
  }}
`;

export const StyledTableCell = styled(TableCell)`
  font: 400 14px/20px "Work Sans";
  letter-spacing: 0.1px;

  &:first-child {
    padding: 0 28px;
  }

  &.font-bold {
    font: 600 14px/20px "Work Sans";
    text-transform: capitalize;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const StyledTableBody = styled(TableBody)`
  width: -webkit-fill-available;
  ::-webkit-scrollbar-track {
    background: #fff;
  }
`;
