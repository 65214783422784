interface IGroups {
  skipApprovalFlowGroupList: string[];
  approvalFlowGroupList: string[];
}

interface IseparateGroupsByApproversParams {
  userGroupsLogged: string[];
  addedGroups: string[];
  isApprovalFlowEnabled: boolean;
  callback: () => boolean;
}

export const separateGroupsByApprovers = ({
  userGroupsLogged,
  addedGroups,
  isApprovalFlowEnabled,
  callback,
}: IseparateGroupsByApproversParams): IGroups => {
  let skipApprovalFlowGroupList = [];
  const isMyOwnUser = callback();
  const approvalFlowGroupList: string[] = [];

  if (isMyOwnUser || !isApprovalFlowEnabled) {
    return {
      skipApprovalFlowGroupList: addedGroups,
      approvalFlowGroupList,
    };
  }

  const authApprovers = userGroupsLogged
    .filter((item) => {
      const group = item.split(".")[1];

      return group === "Auth Approvers" && item;
    })
    .map((item) => ({
      app: item.split(".")[0],
      group: item.split(".")[1],
    }));

  skipApprovalFlowGroupList = addedGroups.filter((item) => {
    const addedApp = item.split(".")[0];

    const isApprover = authApprovers.find(
      (approverApp) => approverApp.app === addedApp
    );

    if (isApprover) {
      return true;
    }

    approvalFlowGroupList.push(item);
    return false;
  });

  return {
    skipApprovalFlowGroupList,
    approvalFlowGroupList,
  };
};
