import {
  Filter,
  FilterOption,
} from "@admin-portal-shared-components/search-field";
import { IUserTypes } from "components/Forms/Search/userTypes";
import { UserType } from "pages/Users/types";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { SearchField } from "./styles";

interface InputProps {
  placeholder?: string;
  value: string;
  onChangeSearchField: (value: string) => void;
  debouce?: number;
  onClearSearchField: () => void;
  onChangeUserType: (value: UserType) => void;
  userTypeValue?: string;
  isActiveSelectUserType?: boolean;
  options?: IUserTypes[];
}

export function Search({
  value,
  onChangeSearchField,
  debouce,
  onClearSearchField,
  placeholder,
  onChangeUserType,
  userTypeValue,
  isActiveSelectUserType,
  options,
  ...otherProps
}: InputProps): JSX.Element {
  const { formatMessage } = useIntl();

  const [searchValue, setSearchValue] = useState(value);

  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const handleOnChangeSearchField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: searchInputValue } = event.target;
    setSearchValue(searchInputValue);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(
      () => onChangeSearchField(searchInputValue),
      debouce || 500
    );
  };

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, [timeoutRef]);

  const handleOnClearSearchField = () => {
    onClearSearchField();
    setSearchValue("");
  };

  useEffect(() => {
    setSearchValue(value);
  }, [value]);
  return (
    <SearchField
      {...otherProps}
      placeholder={placeholder}
      value={searchValue}
      width={550}
      size="medium"
      onClear={handleOnClearSearchField}
      onChange={(event) =>
        handleOnChangeSearchField(event as React.ChangeEvent<HTMLInputElement>)
      }
    >
      {isActiveSelectUserType ? (
        <Filter
          placeholder="Filter"
          value={userTypeValue}
          onChange={onChangeUserType}
        >
          {options?.map(({ value: optionValue }: IUserTypes) => (
            <FilterOption key={optionValue} value={optionValue}>
              {formatMessage({
                id: `userDetailsPage_${optionValue.toLowerCase()}`,
              })}
            </FilterOption>
          ))}
        </Filter>
      ) : undefined}
    </SearchField>
  );
}
