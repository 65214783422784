import { Button, Paragraph } from "@hexa-ui/components";
import DefaultEmptyStateFigure from "assets/exclamation-icon.svg";
import React from "react";
import { EmptyStateContainer, EmptyStateMessageContainer } from "./styles";

type IEmptyProps = {
  csvTemplate?: string;
  downloadTemplateFunction: () => void;
};

export const EmptyState = ({
  csvTemplate,
  downloadTemplateFunction,
}: IEmptyProps): JSX.Element => {
  return (
    <EmptyStateContainer className="empty-state-container">
      <img alt="" src={DefaultEmptyStateFigure} />
      <EmptyStateMessageContainer>
        <Paragraph allowFullScreen size="basis">
          You haven&apos;t uploaded a CSV file yet,
        </Paragraph>
        {csvTemplate ? (
          <a href={csvTemplate} download onClick={downloadTemplateFunction}>
            download template here to start.
          </a>
        ) : (
          <Button onClick={downloadTemplateFunction}>
            download template here to start.
          </Button>
        )}
      </EmptyStateMessageContainer>
    </EmptyStateContainer>
  );
};
