/* eslint-disable camelcase */
import { useHasPermission } from "admin-portal-shared-services";
import { Filter } from "components/Filter/Filter";
import { FilterApplications } from "components/FilterApplications/FilterApplications";
import { filterAplications } from "components/FilterApplications/Filters/AppFilter";
import { Search } from "components/Forms/Search/Search";
import { IUserTypes, userTypes } from "components/Forms/Search/userTypes";
import {
  StyledAppName,
  StyledHeaderContainer,
  StyledHeaderTitle,
} from "components/Shared/styles";
import { SnackBar } from "components/Snackbar/Snackbar";
import { SegmentService, getCountries } from "helpers";
import {
  ApplicationKeys,
  applications,
} from "helpers/Applications/applications";
import { SegmentFormatData } from "helpers/segmentService/segmentFormatData";
import queryString from "query-string";
import React, { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { isHubDtc } from "services/host/HostService";
import {
  UserType as AnalyticsUserType,
  AppFilter,
} from "identity-admin-mfe/modules/user-management/services/AnalyticService";
import { AddUsersButton } from "./Components/AddUsersButton/AddUsersButton";
import { SearchMessage } from "./Components/SearchMessage/SearchMessage";
import { UserTable } from "./Components/UserTable/UserTable";
import { USER_TYPES } from "./consts";
import {
  StyledContainer,
  StyledHeaderActions,
  StyledScreen,
  StyledTableContainer,
} from "./styles";
import { UserType } from "./types";

// eslint-disable-next-line no-shadow
enum ApplicationsToSegment {
  dtc_courier = "DtcCourier",
  dtc_seller = "DtcSeller",
  dtc_consumer = "DtcConsumer",
  adminportal = "AdminPortal",
  cmsadmin = "Cmsadmin",
  deliveradmin = "Deliveradmin",
  force = "Force",
  oncustomer = "Oncustomer",
  social = "Social",
  supplier = "Supplier",
  b2b = "Null",
}

const countries = getCountries();

export const UsersPage = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const location = useLocation();
  const isUsEnv = window.location.host.includes("us");
  const hasUserReadPermission = useHasPermission("IdentityFE.User.Read");
  const hasUserWritePermission = useHasPermission([
    "IdentityFE.User.Write",
    "Identity.User.Write",
  ]);
  const hasCustomerUserReadPermission = useHasPermission(
    ["IdentityFE.CustomerUser.Read", "Identity.User.Read"],
    "AND"
  );

  const hasPermissionToAccess =
    hasUserReadPermission ||
    hasUserWritePermission ||
    hasCustomerUserReadPermission;

  const canInviteToOneApp = useHasPermission("IdentityFE.AdminUser.Invite");
  const canInviteToForceApp = useHasPermission("IdentityFE.ForceUser.Write");
  const hasPermissionToInvite = canInviteToOneApp || canInviteToForceApp;

  const isHubDtcHost = isHubDtc();

  const params = useMemo(
    () => queryString.parse(location.search),
    [location.search]
  );
  const appsParamValue = params.apps
    ? String(params.apps).split(",")
    : Object.keys(applications);

  const [searchUser, setSearchUser] = React.useState<string>(
    (params.search as string) || ""
  );
  const [openSnackBar, setOpenSnackBar] = React.useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = React.useState<string>(
    countries.includes(params.country as string)
      ? (params.country as string)
      : countries[0]
  );

  const verifyUserType = (): string => {
    const isCustomerUserSupportL1 = hasCustomerUserReadPermission;

    if (isCustomerUserSupportL1) {
      return USER_TYPES.CUSTOMER;
    }
    return USER_TYPES.HUB;
  };

  const renderOptionsUserType = (): IUserTypes[] => {
    const [hubOption, customerOption, dtcOption] = userTypes;

    if (isHubDtcHost) {
      return [dtcOption, hubOption];
    }

    return hasCustomerUserReadPermission
      ? [customerOption]
      : [hubOption, customerOption];
  };

  const [selectedUserType, setSelectedUserType] = React.useState<string>(
    verifyUserType()
  );

  const [selectedApps, setSelectedApps] = React.useState<ApplicationKeys[]>(
    appsParamValue as ApplicationKeys[]
  );

  const setParamsUrl = useCallback(() => {
    const newParams = {
      search: searchUser,
      userType: selectedUserType,
      country: selectedCountry ?? "",
      apps: selectedApps.toString(),
    };

    history.replace({
      search: new URLSearchParams(newParams).toString(),
    });
  }, [history, selectedUserType, selectedCountry, searchUser, selectedApps]);

  useEffect(() => {
    SegmentService.userViewed();
  }, []);

  useEffect(() => {
    if (!hasPermissionToAccess) history.push("/error/403");
  }, [hasPermissionToAccess, history]);

  useEffect(() => {
    if (
      (params.country && !countries.includes(params.country as string)) ||
      (params.userType &&
        !(Object.values(USER_TYPES) as string[]).includes(
          params.userType as string
        ))
    ) {
      setOpenSnackBar(true);
    }
  }, [params.country, params.userType]);

  useEffect(() => {
    const filteredData = SegmentFormatData.countryFiltered(selectedCountry);
    SegmentService.countryFiltered(filteredData);
  }, [selectedCountry]);

  useEffect(() => {
    // TODO: we should consider change the names in the segment enum, or just move the map logic to another file
    const areAllAppsSelected =
      selectedApps.length === Object.keys(applications).length;
    const formattedApplications: Record<"app_filter", AppFilter>[] =
      selectedApps.map((app: ApplicationKeys) => ({
        app_filter: AppFilter[ApplicationsToSegment[app]],
      }));

    if (areAllAppsSelected)
      formattedApplications.push({ app_filter: AppFilter.All });

    SegmentService.applicationFiltered(
      formattedApplications.length ? formattedApplications : null
    );
  }, [selectedApps, selectedCountry]);

  useEffect(() => {
    const adminportalApp = ["adminportal"];
    const allApps = filterAplications(Object.keys(applications));
    const dtcCustomerApps = ["dtc_courier", "dtc_consumer", "dtc_seller"];

    const getApps = () => {
      switch (selectedUserType) {
        case USER_TYPES.HUB:
          return isUsEnv || isHubDtcHost ? adminportalApp : allApps;
        case USER_TYPES.DTC:
          return dtcCustomerApps;
        default:
          setSelectedUserType(USER_TYPES.CUSTOMER);
          return ["b2b"];
      }
    };

    const apps = getApps();
    setSelectedApps(apps as ApplicationKeys[]);
    SegmentService.userTypeSelected(selectedUserType as AnalyticsUserType);
  }, [selectedUserType, selectedCountry, isHubDtcHost, isUsEnv]);

  const handleCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const country = event.currentTarget.value;
    setSelectedCountry(country);
  };

  const handleAppsChange = (apps: ApplicationKeys[]) => {
    setSelectedApps(apps);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackBar(false);
  };

  const handleChangeSearchUser = (value: string) => {
    setSearchUser(value);
  };

  const handleChangeUserType = (userType: UserType) => {
    if (selectedUserType !== userType) {
      setSelectedUserType(userType);
    }
  };

  useEffect(() => {
    setParamsUrl();
  }, [setParamsUrl]);

  const handleClickRedirectUserToInvitationWorkflow = () => {
    history.push("/invitation");
    SegmentService.userInvitationStarted();
  };

  const canFilterApplications =
    !isHubDtcHost &&
    selectedUserType === USER_TYPES.HUB &&
    !hasCustomerUserReadPermission;

  const canFilterByCountry = selectedUserType !== USER_TYPES.HUB;

  return (
    <StyledScreen className="container_mfe">
      <StyledAppName>BEES User Management</StyledAppName>
      <StyledHeaderContainer>
        <StyledHeaderTitle>
          {formatMessage({ id: "userPage_title" })}
        </StyledHeaderTitle>

        <StyledHeaderActions>
          {hasPermissionToInvite && (
            <AddUsersButton
              onClick={handleClickRedirectUserToInvitationWorkflow}
            />
          )}
          <Search
            onChangeSearchField={handleChangeSearchUser}
            onClearSearchField={() => handleChangeSearchUser("")}
            value={searchUser}
            userTypeValue={selectedUserType}
            onChangeUserType={handleChangeUserType}
            placeholder={formatMessage({ id: "userPage_search" })}
            aria-label="search-input"
            data-testid="search"
            isActiveSelectUserType
            options={renderOptionsUserType()}
          />
        </StyledHeaderActions>
      </StyledHeaderContainer>

      <StyledContainer data-testid="container">
        {!isUsEnv && (
          <>
            {canFilterApplications && (
              <FilterApplications
                onChange={handleAppsChange}
                selectedApps={selectedApps}
              />
            )}
            {canFilterByCountry && (
              <Filter
                countryChange={handleCountryChange}
                country={selectedCountry}
              />
            )}
          </>
        )}
        {searchUser && (
          <StyledTableContainer>
            <UserTable
              country={selectedCountry}
              value={searchUser}
              selectedApps={selectedApps}
              userType={selectedUserType}
            />
          </StyledTableContainer>
        )}
        {!searchUser && <SearchMessage />}
      </StyledContainer>
      <SnackBar
        open={openSnackBar}
        onClose={handleCloseSnackbar}
        message={formatMessage({ id: "userPage_filterAccessDenied" })}
        severity="warning"
      />
    </StyledScreen>
  );
};
