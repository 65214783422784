import { MessageMap } from "./types";

export const koKR: MessageMap = {
  disassociateButton_dialog_body:
    "This user won't be able to buy for {amountOfAccounts, plural, one {this account} other {the selected accounts}} anymore.",
  disassociateButton_dialog_cancelButton: "취소",
  disassociateButton_dialog_confirmButton: "제거",
  disassociateButton_dialog_title:
    "Remove {amountOfAccounts, plural, one {this account} other {these accounts}}",
  editUSerModal_fieldIsRequiredError: "This field is required",
  editUserModal_addNewSupervisorButton: "Add supervisor",
  editUserModal_cancelButton: "취소",
  editUserModal_invalidEmailError: "Enter a valid email.",
  editUserModal_managerEqualSupervisorError:
    "The same user cannot be assigned both the supervisor and manager roles.",
  editUserModal_managerFieldLabel: "Managed By",
  editUserModal_managerFieldPlaceholder: "Insert the manager's email",
  editUserModal_nonExistentEmailError:
    "This email doesn't exist or couldn't be validated.",
  editUserModal_optionalField: "optional",
  editUserModal_pendingChangesSnackbar: "There are pending changes",
  editUserModal_requiredField: "required",
  editUserModal_saveButton: "Save changes",
  editUserModal_supervisorFieldLabel: "Supervisor",
  editUserModal_supervisorFieldPlaceholder: "Insert the supervisor's email",
  editUserModal_title: "Edit user information",
  test_missing: "Just English option available",
  test_translate: "Translate",
  unblockUserModal_cancelButton: "취소",
  unblockUserModal_subtitle: "{userName}은 BEES 플랫폼에 액세스할 수 있습니다.",
  unblockUserModal_title: "사용자 액세스 차단을 해제하시겠습니까?",
  unblockUserModal_unblockButton: "차단 해제",
  userAccounts_emptyState: "이 사용자에게 추가된 계정이 없습니다.",
  userAccounts_list_accountId: "계정 ID {accountId}",
  userAccounts_selectAll: "모두 선택",
  userAccounts_snackbarFailureRemoveAccounts:
    "{amountOfAccounts, plural, one {# 계정을 삭제할 수 없습니다} other {# 개의 계정을 삭제할 수 없습니다.}}",
  userAccounts_snackbarFailureToRemoveAnyAccounts: "계정을 삭제할 수 없습니다.",
  userAccounts_snackbarSuccessfullyRemoveAccounts:
    "{amountOfAccounts, plural, one {# 계정이 성공적으로 제거되었습니다.} other {# 개의 계정이 성공적으로 제거되었습니다}}.",
  userAccounts_title: "추가된 계정",
  userDetailsPage_blockButton: "차단",
  userDetailsPage_customer: "고객",
  userDetailsPage_disassociateButton: "제거",
  userDetailsPage_dtc: "DTC",
  userDetailsPage_editButton: "수정",
  userDetailsPage_editUserModalCancelButton: "취소",
  userDetailsPage_editUserModalDescriptionCompanyIds:
    "Use commas to separate values.",
  userDetailsPage_editUserModalLabelCompanyIds: "Company IDs (optional)",
  userDetailsPage_editUserModalLabelPermissionGroups: "Permission groups",
  userDetailsPage_editUserModalLabelSupportedCountries: "Supported countries",
  userDetailsPage_editUserModalSaveChangesButton: "Save changes",
  userDetailsPage_hub: "ONE",
  userDetailsPage_modalBlockUserBlockButton: "차단",
  userDetailsPage_modalBlockUserCancelButton: "취소",
  userDetailsPage_modalBlockUserDescription:
    "{userName}은 모든 BEES 플랫폼에 대한 액세스 권한을 잃게 됩니다.",
  userDetailsPage_modalBlockUserTitle: "사용자의 액세스를 차단하시겠습니까?",
  userDetailsPage_savedChangesSnackbar: "User information changes saved.",
  userDetailsPage_snackbarFailureBlockUser: "사용자를 지금 차단할 수 없습니다.",
  userDetailsPage_snackbarFailureUnblockUser:
    "사용자를 지금 차단 해제할 수 없습니다.",
  userDetailsPage_snackbarSuccessufullyBlockUser:
    "사용자가 성공적으로 차단되었습니다.",
  userDetailsPage_snackbarSuccessufullyUnblockUser:
    "사용자가 성공적으로 차단 해제되었습니다.",
  userDetailsPage_title: "사용자 세부정보",
  userDetailsPage_unblockButton: "차단 해제",
  userDetailsPage_userInfoLabelBdrId: "BDR ID",
  userDetailsPage_userInfoLabelCompanyIds: "Company IDs",
  userDetailsPage_userInfoLabelCountry: "국가",
  userDetailsPage_userInfoLabelCreatedAt: "생성일자",
  userDetailsPage_userInfoLabelDeliveryCenterIds: "Delivery center ID",
  userDetailsPage_userInfoLabelEmail: "이메일",
  userDetailsPage_userInfoLabelLastInvitedAt: "마지막으로 초대된 날짜",
  userDetailsPage_userInfoLabelLogisticOperatorIds: "Logistic operator ID",
  userDetailsPage_userInfoLabelPermissionGroups: "Permission groups",
  userDetailsPage_userInfoLabelPhone: "핸드폰",
  userDetailsPage_userInfoLabelRegisteredAt: "등록일",
  userDetailsPage_userInfoLabelRouteId: "Route ID",
  userDetailsPage_userInfoLabelSupportedCountries: "Supported countries",
  userDetailsPage_userInfoLabelUserId: "사용자 ID",
  userDetailsPage_userInfoLabelUserStatus: "상태",
  userDetailsPage_userStatus_ACTIVE: "Active",
  userDetailsPage_userStatus_BLOCKED: "Blocked",
  userDetailsPage_userStatus_DEACTIVATED: "Deactivated",
  userDetailsPage_userStatus_PENDING: "Pending",
  userDetailsPage_userInfoTooltipCopy: "복사",
  userPage_addUsers: "사용자 추가",
  userPage_administrator: "관리자",
  userPage_allCountries: "모든 나라들",
  userPage_allRecentActivities: "모든 최근 활동",
  userPage_category: "카테고리",
  userPage_clearAll: "모두 지우기",
  userPage_country: "국가",
  userPage_customer: "고객",
  userPage_dragFileHere: "여기로 파일을 드래그하거나",
  userPage_exportCSV: "CSV 내보내기",
  userPage_filterAccessDenied:
    "You don't have access to one or more applied filters. All filters have been reset.",
  userPage_filterBy: "필터링 기준",
  userPage_loadingResults: "결과 로드 중…",
  userPage_noFiltersApplied: "적용된 필터 없음",
  userPage_recentSearches: "최근 검색",
  userPage_results: "{amountOfResults}개의 결과를 표시합니다.",
  userPage_search: "사용자 이름 또는 이메일로 검색",
  userPage_searchMessage: "Use the search field above to find a specific user.",
  userPage_selectFile: "선택",
  userPage_settings: "설정",
  userPage_showMore: "자세히보기",
  userPage_tableColumnEmailUserList: "이메일",
  userPage_tableColumnNameUserList: "이름",
  userPage_tableColumnPhoneUserList: "핸드폰",
  userPage_tableColumnStatusActiveUserList: "활성화",
  userPage_tableColumnStatusBlockedUserList: "차단됨",
  userPage_tableColumnStatusUserList: "상태",
  userPage_title: "사용자",
  userPage_wholesalerBranch: "도매/지점",
  userTableEmpty_description:
    "No users found with “{searched}”. Try changing the filters.",
  userTableEmpty_header: "No results found",
};
