import { Dialog } from "components/Dialog/Dialog";
import React, { useCallback, useMemo, useState } from "react";
import { DialogProps, IDialogElement, OpenDialogParams } from "../types";
import { DialogContext } from "./DialogContext";

export const DialogProvider = ({ children }: IDialogElement): JSX.Element => {
  const [dialogConfig, setDialogConfig] = useState<DialogProps>();

  const closeDialog = useCallback(
    () =>
      setDialogConfig((previousConfigs) => ({
        ...previousConfigs,
        open: false,
        body: null,
        title: null,
        actions: null,
      })),
    []
  );

  const openDialog = useCallback(
    (config: OpenDialogParams) =>
      setDialogConfig((previousConfigs) => ({
        ...previousConfigs,
        ...config,
        open: true,
      })),
    []
  );

  const providedValue = useMemo(
    () => ({ openDialog, closeDialog }),
    [openDialog, closeDialog]
  );

  return (
    <DialogContext.Provider value={providedValue}>
      <Dialog {...dialogConfig}>{dialogConfig?.body}</Dialog>
      {children}
    </DialogContext.Provider>
  );
};
