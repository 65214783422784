import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 600px;
  margin: auto;
  height: calc(100vh - 232px);
  display: flex;
  flex-direction: column;
  align-items: center;
  &::-webkit-scrollbar-track {
    margin: 8px;
  }
`;

export const SearchImg = styled.img`
  justify-content: center;
  width: 240px;
  margin: 15% auto 32px;
`;

export const SearchText = styled.div`
  height: 48px;
  width: 290px;
  color: #0000008c;
  font-family: "Work Sans";
  font-size: 16px;
  letter-spacing: 0.1px;
  line-height: 24px;
  text-align: center;
`;
