import { Copy as CopyIcon } from "@hexa-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import { SegmentService } from "helpers";
import React from "react";
import { useIntl } from "react-intl";
import { CopiedCriteria } from "identity-admin-mfe/modules/user-management/services/SegmentService";
import { Output, StyledOutput, StyledSvgContainer } from "./styles";

interface IProp {
  label?: string;
  text: string | undefined;
  criteria: CopiedCriteria;
  color?: string;
}

export function OutputCopy({
  text,
  label,
  criteria,
  color,
}: IProp): JSX.Element {
  const intl = useIntl();
  const copyToClipboard = () => {
    navigator.clipboard.writeText(String(text));
    if (text) SegmentService.userInfoCopied(criteria);
  };

  return (
    <StyledOutput color={color}>
      <Output>{label || text}</Output>
      <Tooltip
        title={`${intl.formatMessage({
          id: "userDetailsPage_userInfoTooltipCopy",
        })}`}
        data-testid="tooltip"
      >
        <StyledSvgContainer onClick={copyToClipboard}>
          {text && (
            <CopyIcon
              fr={undefined}
              size="medium"
              style={{ color: "#047AF1", marginLeft: "0.5rem" }}
            />
          )}
        </StyledSvgContainer>
      </Tooltip>
    </StyledOutput>
  );
}
