import { IDecodedToken } from "interfaces/IDecodedToken";

function parseJwt(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function getToken(): string {
  const token = localStorage.getItem("authHeader");
  return token as string;
}

export function getCountry(): string {
  const token = getToken().split(" ")[1];
  const decodedToken: { country: string } = parseJwt(token);
  const { country } = decodedToken;
  return country;
}

export function getDecodedToken(): IDecodedToken {
  const token = getToken().split(" ")[1];
  const {
    app,
    sub,
    country,
    name,
    email,
    scopes,
    supportedCountries,
    preferredLanguage,
    vendorId,
    ...rest
  } = parseJwt(token);
  return {
    appName: app,
    userId: sub,
    country,
    name,
    givenName: rest.given_name,
    email,
    scopes,
    supportedCountries,
    preferredLanguage,
    vendorId,
  };
}

export function getCountries(): string[] {
  const disabledCountries = ["CZ", "IN"];
  const token = getToken().split(" ")[1];
  const decodedToken: { supportedCountries: string[] } = parseJwt(token);
  const { supportedCountries } = decodedToken;
  const myCountries: string[] = supportedCountries
    .filter((country) => !disabledCountries.includes(country))
    .sort((a, b) => a.localeCompare(b));

  return [...new Set(myCountries)];
}
