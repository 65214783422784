import styled from "styled-components";

export const StyledSelect = styled.select`
  min-width: 125px;
  height: 32px;
  padding: 0 12px;
  border-radius: 50px;
  border: 1px solid #e4e4e4;
  background-color: #fff;
  transition: all 200ms linear;

  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #e4e4e4;
  }
`;
