import { USER_TYPES } from "pages/Users/consts";
import { UserType } from "pages/Users/types";

export interface IUserTypes {
  label: UserType;
  value: UserType;
  searchableLabel: UserType;
}

export const userTypes: IUserTypes[] = [
  {
    value: USER_TYPES.HUB,
    label: USER_TYPES.HUB,
    searchableLabel: USER_TYPES.HUB,
  },
  {
    value: USER_TYPES.CUSTOMER,
    label: USER_TYPES.CUSTOMER,
    searchableLabel: USER_TYPES.CUSTOMER,
  },
  {
    value: USER_TYPES.DTC,
    label: USER_TYPES.DTC,
    searchableLabel: USER_TYPES.DTC,
  },
];
