const reader = (file: File): Promise<string> => {
  const fileReader = new FileReader();
  return new Promise((resolve) => {
    fileReader.readAsBinaryString(file);
    fileReader.onload = (event: any) => {
      const data = event.target.result;
      resolve(data);
    };
  });
};

const countFileRows = async (file: File): Promise<number> => {
  const columnHeader = 1;
  const data: string = await reader(file);
  const lines = data.split(/\r?\n/);
  return lines.length - columnHeader;
};

export { reader, countFileRows };
