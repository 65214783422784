import { axiosClient } from "@shared/api";
import {
  IGetIsUserRegisteredParams,
  IGetIsUserRegisteredResponse,
} from "identity-admin-contracts/user-management";

export const getIsUserRegistered = async ({
  userEmail,
  adminOnly,
}: IGetIsUserRegisteredParams) =>
  await axiosClient.get<IGetIsUserRegisteredResponse>(
    "/user-management/is-user-registered",
    {
      params: {
        userEmail,
        adminOnly,
      },
    },
  );
