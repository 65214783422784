import { styled } from "@bees/vision-tokens";

export const EmptyStateContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "$4 0",
  "&> *:not(:last-child)": {
    marginBottom: "$4",
  },
  img: {
    width: "7.5rem",
  },
});

export const EmptyStateMessageContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  p: {
    marginRight: "$2",
  },
  "a, button": {
    color: "$semanticInfoText",
  },
  button: {
    height: "auto",
    backgroundColor: "transparent",
    textDecoration: "underline",
    fontWeight: "$normal",
    padding: 0,

    "&:hover": {
      color: "$semanticInfoText",
      boxShadow: "none",
    },
  },
});
