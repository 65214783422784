import {
  SkeletonLoader,
  Table as TableHexa,
  TableProps,
} from "@hexa-ui/components";
import React from "react";

interface Skeleton extends TableProps<any> {
  skeletonLines?: number;
}
export const Table = ({
  columns,
  skeletonLines,
  loading,
  data,
  ...otherProps
}: Skeleton): JSX.Element => {
  const skeletonLinesDefault = skeletonLines || 10;
  const skeletonColumns = columns.map((column) => ({
    Header: column.Header,
    disableSortBy: true,
    customRender: () => {
      return (
        <div data-testid="skeleton-table">
          <SkeletonLoader height="1rem" width="100%" />
        </div>
      );
    },
  }));

  const skeletonRows = Array.from(
    { length: skeletonLinesDefault },
    (_, i) => i
  );

  const columnsInUse = loading ? skeletonColumns : columns;
  const rowsDataInUse = loading ? skeletonRows : data;

  return (
    <TableHexa
      cellHeight="cozy"
      columns={columnsInUse}
      data={rowsDataInUse}
      {...otherProps}
    />
  );
};
