import { Input as SharedInput } from "@admin-portal-shared-components/input";
import React, { useEffect, useRef, useState } from "react";
import { InputProps } from "./types";

export const Input = ({
  value,
  debounce,
  onChange,
  id = "",
  optionalText = "",
  labelProps = {},
  ...rest
}: InputProps): JSX.Element => {
  const [inputValue, setInputValue] = useState(value);

  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const handleDebouncedChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInputValue(event.target.value);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => onChange?.(event), debounce);
  };

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, [timeoutRef]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <SharedInput
      id={id}
      value={inputValue}
      onChange={debounce ? handleDebouncedChange : onChange}
      optionalText={optionalText}
      labelProps={{ htmlFor: id, ...labelProps }}
      {...rest}
    />
  );
};
