import { UserEditMetadata } from "services/auditLog/types";

interface UserEditMetadataFormatParam {
  wasApprovedBySystem: boolean;
  newGroups: string[];
  removedGroups: string[];
  newSupportedCountries: string[];
  removedSupportedCountries: string[];
  newCompanyIds: string[];
  removedCompanyIds: string[];
  deliveryIds: string[];
  oldDeliveryIds: string[];
  bdrId: string;
  oldBdrId: string;
  routeId: string;
  oldRouteId: string;
  logisticIds: string[];
  oldLogisticIds: string[];
  errorMessages: string[];
}

const userEditMetadataFormat = ({
  wasApprovedBySystem,
  newGroups,
  removedGroups,
  errorMessages,
  newSupportedCountries,
  removedSupportedCountries,
  newCompanyIds,
  removedCompanyIds,
  deliveryIds,
  oldDeliveryIds,
  bdrId,
  oldBdrId,
  routeId,
  oldRouteId,
  logisticIds,
  oldLogisticIds,
}: UserEditMetadataFormatParam): UserEditMetadata => {
  const userEditMetadata: UserEditMetadata = {
    wasApprovedBySystem,
    permissionGroup: {
      added: newGroups.filter(
        (group) => !errorMessages.toString().includes(group)
      ),
      deleted: removedGroups.filter(
        (group) => !errorMessages.toString().includes(group)
      ),
    },
    supportedCountry: {
      added: newSupportedCountries.filter(
        (country) => !errorMessages.toString().includes(country)
      ),
      deleted: removedSupportedCountries.filter(
        (country) => !errorMessages.toString().includes(country)
      ),
    },
    vendorId: {
      added: newCompanyIds.filter(
        (companyId) => !errorMessages.toString().includes(companyId)
      ),
      deleted: removedCompanyIds.filter(
        (companyId) => !errorMessages.toString().includes(companyId)
      ),
    },
    deliveryCenterId: {
      added: deliveryIds.filter(
        (deliveryId) =>
          !oldDeliveryIds.includes(deliveryId) &&
          !errorMessages.toString().includes(deliveryId)
      ),
      deleted: oldDeliveryIds.filter(
        (deliveryId) =>
          !deliveryIds.includes(deliveryId) &&
          !errorMessages.toString().includes(deliveryId)
      ),
    },
    logisticOperatorId: {
      added: logisticIds.filter(
        (logisticId) =>
          !oldLogisticIds.includes(logisticId) &&
          !errorMessages.toString().includes(logisticId)
      ),
      deleted: oldLogisticIds.filter(
        (logisticId) =>
          !logisticIds.includes(logisticId) &&
          !errorMessages.toString().includes(logisticId)
      ),
    },
  };
  if (bdrId !== oldBdrId) {
    userEditMetadata.bdrId = bdrId;
  }
  if (routeId !== oldRouteId) {
    userEditMetadata.routeId = routeId;
  }
  return userEditMetadata;
};

export { userEditMetadataFormat };
