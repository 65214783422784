import { ChangeEvent, ReactNode } from "react";

export enum ChipSeparatorKeys {
  SPACE = " ",
  TAB = "Tab",
  ENTER = "Enter",
  COMMA = ",",
}

export type ChipsFieldProps = {
  chipList: string[];
  placeholder?: string;
  description?: ReactNode;
  inputLabel?: ReactNode;
  inputId?: string;
  setChipList: (chips: string[]) => void;
  onInputChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};
