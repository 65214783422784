interface Action {
  name: string;
  action(): void;
}

export enum FileTypes {
  Authorization = "AUTHORIZATION",
  Invitation = "INVITATION",
}

export interface FileUploadErrors {
  message: string;
  action?: Action;
}

export interface DialogUploadFileProps {
  open: boolean;
  fileType: FileTypes;
  onClose(): void;
  onConfirm(fileName: string): void;
}
