import { styled as hexaStyled } from "@bees/vision-tokens";
import { Heading } from "@hexa-ui/components";
import styled from "styled-components";

export const Header = hexaStyled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "$6",
});

export const CardContainer = hexaStyled("div", {
  backgroundColor: "$neutral0",
  width: "100%",
  borderRadius: "$8",
  boxShadow: "$4",
  overflow: "hidden",
  height: "calc(100vh - 16rem)",
});

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

export const InnerCardContainer = styled.div`
  overflow-y: auto;
  height: 100%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & button {
    margin-left: 1rem;
  }
`;

export const StyledScreen = styled.div`
  padding: 2rem 2rem 2rem 7rem;
  @media (min-width: 90rem) {
    margin: 0 auto;
  }
`;

export const TitleModalAuditLogs = hexaStyled(Heading, {
  lineHeight: "$3",
  "div[data-state]": {
    display: "inline-block",
  },
});
