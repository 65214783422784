import styled from "styled-components";

export const StyledFilterContainer = styled.div`
  width: 308px;
  height: 100%;
  border-right: 1px solid #e0e0e0;
`;
export const StyledFiltersContainer = styled.div`
  overflow-y: overlay;
  height: calc(100vh - 304px);
`;

export const StyledFilterContainerHeader = styled.div`
  width: 100%;
  height: 72px;
  border-bottom: 1px solid #e0e0e0;
  padding: 16px;
  color: rgba(0, 0, 0, 0.55);
`;
export const StyledTableContainerHeader = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  padding: 16px;
  color: rgba(0, 0, 0, 0.55);
  font-family: "Work Sans";
  font-size: 14px;
  align-items: center;
`;

export const StyledFilterHeaderTitle = styled.h2`
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.9);
  font: 600 20px/24px "Barlow";
`;
