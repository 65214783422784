import styled from "styled-components";

export const StyledButton = styled.button`
  background-color: #000;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  border: none;
  outline: none;
  cursor: pointer;
  height: 2rem;
`;

export const StyledButtonText = styled.span`
  font-size: 0.875rem;
  line-height: 1rem;
  font-family: Work Sans;
  color: rgba(255, 255, 255, 0.95);
`;
