import { MessageMap } from "./types";

export const enUS: MessageMap = {
  disassociateButton_dialog_body:
    "This user won't be able to buy for {amountOfAccounts, plural, one {this account} other {the selected accounts}} anymore.",
  disassociateButton_dialog_cancelButton: "Cancel",
  disassociateButton_dialog_confirmButton: "Remove",
  disassociateButton_dialog_title:
    "Remove {amountOfAccounts, plural, one {this account} other {these accounts}}",
  editUSerModal_fieldIsRequiredError: "This field is required",
  editUserModal_addNewSupervisorButton: "Add supervisor",
  editUserModal_cancelButton: "Cancel",
  editUserModal_invalidEmailError: "Enter a valid email.",
  editUserModal_managerEqualSupervisorError:
    "The same user cannot be assigned both the supervisor and manager roles.",
  editUserModal_managerFieldLabel: "Managed By",
  editUserModal_managerFieldPlaceholder: "Insert the manager's email",
  editUserModal_nonExistentEmailError:
    "This email doesn't exist or couldn't be validated.",
  editUserModal_optionalField: "optional",
  editUserModal_pendingChangesSnackbar: "There are pending changes",
  editUserModal_requiredField: "required",
  editUserModal_saveButton: "Save changes",
  editUserModal_supervisorFieldLabel: "Supervisor",
  editUserModal_supervisorFieldPlaceholder: "Insert the supervisor's email",
  editUserModal_title: "Edit user information",
  test_missing: "Just English option available",
  test_translate: "Translate",
  unblockUserModal_cancelButton: "Cancel",
  unblockUserModal_subtitle: "{userName} will gain access to BEES platforms.",
  unblockUserModal_title: "Unblock user's access?",
  unblockUserModal_unblockButton: "Unblock",
  userAccounts_emptyState: "No accounts associated to this user.",
  userAccounts_list_accountId: "Account ID {accountId}",
  userAccounts_selectAll: "Select all",
  userAccounts_snackbarFailureRemoveAccounts:
    "{amountOfAccounts, plural, one {# account} other {# accounts}} couldn't be removed",
  userAccounts_snackbarFailureToRemoveAnyAccounts:
    "The account(s) couldn't be removed",
  userAccounts_snackbarSuccessfullyRemoveAccounts:
    "{amountOfAccounts, plural, one {# account} other {# accounts}} successfully removed",
  userAccounts_title: "Associated accounts",
  userDetailsPage_blockButton: "Block",
  userDetailsPage_customer: "Customer",
  userDetailsPage_disassociateButton: "Remove",
  userDetailsPage_dtc: "DTC",
  userDetailsPage_editButton: "Edit",
  userDetailsPage_editUserModalCancelButton: "Cancel",
  userDetailsPage_editUserModalDescriptionCompanyIds:
    "Use commas to separate values.",
  userDetailsPage_editUserModalLabelCompanyIds: "Company IDs (optional)",
  userDetailsPage_editUserModalLabelPermissionGroups: "Permission groups",
  userDetailsPage_editUserModalLabelSupportedCountries: "Supported countries",
  userDetailsPage_editUserModalSaveChangesButton: "Save changes",
  userDetailsPage_hub: "ONE",
  userDetailsPage_modalBlockUserBlockButton: "Block",
  userDetailsPage_modalBlockUserCancelButton: "Cancel",
  userDetailsPage_modalBlockUserDescription:
    "{userName} will lose access to all BEES platforms.",
  userDetailsPage_modalBlockUserTitle: "Block user access?",
  userDetailsPage_savedChangesSnackbar: "User information changes saved.",
  userDetailsPage_snackbarFailureBlockUser: "The user couldn't be blocked now",
  userDetailsPage_snackbarFailureUnblockUser:
    "The user couldn't be unblocked now",
  userDetailsPage_snackbarSuccessufullyBlockUser: "User successfully blocked",
  userDetailsPage_snackbarSuccessufullyUnblockUser:
    "User successfully unblocked",
  userDetailsPage_title: "User details",
  userDetailsPage_unblockButton: "Unblock",
  userDetailsPage_userInfoLabelBdrId: "BDR ID",
  userDetailsPage_userInfoLabelCompanyIds: "Company IDs",
  userDetailsPage_userInfoLabelCountry: "Country",
  userDetailsPage_userInfoLabelCreatedAt: "Created at",
  userDetailsPage_userInfoLabelDeliveryCenterIds: "Delivery center ID",
  userDetailsPage_userInfoLabelEmail: "Email",
  userDetailsPage_userInfoLabelLastInvitedAt: "Last invited at",
  userDetailsPage_userInfoLabelLogisticOperatorIds: "Logistic operator ID",
  userDetailsPage_userInfoLabelPermissionGroups: "Permission groups",
  userDetailsPage_userInfoLabelPhone: "Phone",
  userDetailsPage_userInfoLabelRegisteredAt: "Registered at",
  userDetailsPage_userInfoLabelRouteId: "Route ID",
  userDetailsPage_userInfoLabelSupportedCountries: "Supported countries",
  userDetailsPage_userInfoLabelUserId: "User ID",
  userDetailsPage_userInfoLabelUserStatus: "User Status",
  userDetailsPage_userStatus_ACTIVE: "Active",
  userDetailsPage_userStatus_BLOCKED: "Blocked",
  userDetailsPage_userStatus_DEACTIVATED: "Deactivated",
  userDetailsPage_userStatus_PENDING: "Pending",
  userDetailsPage_userInfoTooltipCopy: "Copy",
  userPage_addUsers: "Add users",
  userPage_administrator: "Administrator",
  userPage_allCountries: "All countries",
  userPage_allRecentActivities: "All recent activities",
  userPage_category: "Category",
  userPage_clearAll: "Clear all",
  userPage_country: "Country",
  userPage_customer: "Customer",
  userPage_dragFileHere: "Drag a file here or",
  userPage_exportCSV: "Export CSV",
  userPage_filterAccessDenied:
    "You don't have access to one or more applied filters. All filters have been reset.",
  userPage_filterBy: "Filter by",
  userPage_loadingResults: "Loading results…",
  userPage_noFiltersApplied: "No filters applied",
  userPage_recentSearches: "Recent searches",
  userPage_results:
    "Showing {amountOfResults, plural, one {# result} other {# results}}",
  userPage_search: "Search by ID, name, e-mail or phone",
  userPage_searchMessage: "Use the search field above to find a specific user.",
  userPage_selectFile: "Select",
  userPage_settings: "Settings",
  userPage_showMore: "Show more",
  userPage_tableColumnEmailUserList: "Email",
  userPage_tableColumnNameUserList: "Name",
  userPage_tableColumnPhoneUserList: "Phone",
  userPage_tableColumnStatusActiveUserList: "Active",
  userPage_tableColumnStatusBlockedUserList: "Blocked",
  userPage_tableColumnStatusUserList: "Status",
  userPage_title: "Users",
  userPage_wholesalerBranch: "Wholesaler/Branch",
  userTableEmpty_description:
    "No users found with “{searched}”. Try changing the filters.",
  userTableEmpty_header: "No results found",
};
