import React from "react";
import { StyledItemRowSkeleton, StyledRectangleSkeleton } from "./styles";

export function RecentActivitiesSkeleton(): JSX.Element {
  return (
    <>
      <div
        style={{ display: "block", width: "416px" }}
        data-testid="recent-activities-skeleton"
      >
        <StyledRectangleSkeleton
          height="32px"
          width="166px"
          style={{ margin: "24px 0px 16px 24px" }}
        />
        <StyledItemRowSkeleton>
          <StyledRectangleSkeleton height="24px" width="184px" />
          <StyledRectangleSkeleton height="24px" width="64px" />
        </StyledItemRowSkeleton>
        <StyledItemRowSkeleton>
          <StyledRectangleSkeleton height="24px" width="166px" />
          <StyledRectangleSkeleton height="24px" width="64px" />
        </StyledItemRowSkeleton>
        <StyledItemRowSkeleton>
          <StyledRectangleSkeleton height="24px" width="201px" />
          <StyledRectangleSkeleton height="24px" width="64px" />
        </StyledItemRowSkeleton>
        <StyledItemRowSkeleton>
          <StyledRectangleSkeleton height="24px" width="153px" />
          <StyledRectangleSkeleton height="24px" width="64px" />
        </StyledItemRowSkeleton>
      </div>
    </>
  );
}
