import { Checkbox } from "@admin-portal-shared-components/checkbox";
import {
  ApplicationKeys,
  applications,
} from "helpers/Applications/applications";
import React from "react";
import { isHubDtc } from "services/host/HostService";
import {
  StyledFilterSessionContainer,
  StyledFilterSessionHeader,
  StyledSessionOptionsContainer,
} from "./styles";

interface AppFilterProps {
  onChange: (apps: ApplicationKeys[]) => void;
  values: ApplicationKeys[];
}

const dtcAplications = ["dtc_courier", "dtc_seller", "dtc_consumer"];

export const filterAplications = (apps: string[]): ApplicationKeys[] => {
  const isHubDtcHost = isHubDtc();
  return (
    isHubDtcHost ? apps : apps.filter((app) => !dtcAplications.includes(app))
  ) as ApplicationKeys[];
};

export function AppFilter({ onChange, values }: AppFilterProps): JSX.Element {
  const applicationKeysList = filterAplications(Object.keys(applications));
  const appValues = filterAplications(values);

  const handleToggleCheckAll = () => {
    if (appValues.length === applicationKeysList.length) {
      onChange([]);
    } else {
      onChange(applicationKeysList);
    }
  };

  const handleClick = (appKey: ApplicationKeys) => {
    if (appValues.includes(appKey)) {
      const appsUpdate = appValues.filter(
        (app: ApplicationKeys) => app !== appKey
      );
      onChange(appsUpdate);
    } else {
      onChange([...appValues, appKey]);
    }
  };

  return (
    <StyledFilterSessionContainer>
      <StyledFilterSessionHeader>Applications</StyledFilterSessionHeader>
      <StyledSessionOptionsContainer key="all">
        <Checkbox
          onClick={handleToggleCheckAll}
          id="all"
          checked={appValues.length === applicationKeysList.length}
          label="All applications"
        />
      </StyledSessionOptionsContainer>
      {applicationKeysList.map((appKey, index: number) => {
        return (
          <StyledSessionOptionsContainer key={index as number}>
            <Checkbox
              onClick={() => handleClick(appKey)}
              id={appKey}
              checked={appValues.includes(appKey)}
              label={applications[appKey]}
            />
          </StyledSessionOptionsContainer>
        );
      })}
    </StyledFilterSessionContainer>
  );
}
