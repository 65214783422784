import { AllSupportedCountryKeys } from "./types";
import { allSupportedCountries } from "./entities";

export const formatCountry = (country: string): string =>
  allSupportedCountries[country as AllSupportedCountryKeys] || country;

export const sortCountryListAlphabetically = (
  countries: string[]
): string[] => {
  return countries.sort((countryA, countryB) => {
    return formatCountry(countryA).localeCompare(formatCountry(countryB));
  });
};

export const sortCountryListAlphabeticallyByName = (
  countries: { code: string; name: string }[]
): { code: string; name: string }[] => {
  return countries.sort((a, b) => a.name.localeCompare(b.name));
};
