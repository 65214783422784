import DeveloperBoardOutlined from "@material-ui/icons/DeveloperBoardOutlined";
import styled, { css } from "styled-components";

const Output = styled.output``;

const StyledOutput = styled.div`
  width: max-content;
  height: 1.5rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.9);
  line-height: 1.5rem;
  letter-spacing: 0.006rem;

  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}
`;

const StyledIcon = styled(DeveloperBoardOutlined)``;

const StyledSvgContainer = styled.div`
  cursor: pointer;
`;

export { StyledOutput, Output, StyledIcon, StyledSvgContainer };
