import { formatDate } from "helpers/FormatDate/FormatDate";
import { IAudit } from "services/auditLog/types";
import { AuditLogsItemTypes } from "../AuditLogsHistory/item";
import { IAuditLogsItem } from "./types";

type metadataValues = { added: string[]; deleted: string[] };

const keyOfListParse: { [key: string]: string } = {
  permissionGroup: "group(s)",
  supportedCountry: "supported Countries",
  vendorId: "vendor id",
  deliveryCenterId: "delivery center id(s)",
  logisticOperatorId: "logistic operator id(s)",
  currentStatus: "user status",
  bdrId: "bdr id",
  routeId: "route id",
};

const listIgnoredMetadataKeys = ["actionOwnerName", "wasApprovedBySystem"];

export const AuditLogsListFormat = ({ content }: IAudit): IAuditLogsItem[] => {
  const jsonRegex = /^\s*(\{[\w\W]*\}|\[[\w\W]*\])\s*$/;

  const auditLogsItems: IAuditLogsItem[] = [];
  content?.forEach((log) => {
    const { action } = log;
    const metadataKeys = Object.keys(action.metadata);
    const filterMetadataKeys = metadataKeys.filter(
      (key) => !listIgnoredMetadataKeys.includes(key)
    );
    filterMetadataKeys.forEach((key) => {
      const newAuditLogsItem: IAuditLogsItem = {
        creationDate: formatDate(log.creationDate, "MMM dd, p"),
        createdAt: log.creationDate,
        actionOwnerName: log.action.metadata.actionOwnerName ?? "",
        fieldLabel: `Updated ${keyOfListParse[key] ?? key}`,
        fieldName: key,
        actionOwnerId: log.doneBy.userId,
        id: log.id,
        operation: AuditLogsItemTypes.Update,
        wasApprovedBySystem: log.action.metadata.wasApprovedBySystem,
        data: "",
      };

      if (jsonRegex.test(log.action.metadata[key])) {
        const metadata: metadataValues = JSON.parse(log.action.metadata[key]);
        if (metadata?.added?.length > 0) {
          const addedData = metadata?.added?.join(", ") || "";
          const logItemsAdded = {
            ...newAuditLogsItem,
            data: addedData,
            operation: AuditLogsItemTypes.Insert,
            fieldLabel: `Added ${metadata?.added?.length} ${
              keyOfListParse[key] ?? key
            }`,
          };
          auditLogsItems.push(logItemsAdded);
        }
        if (metadata?.deleted?.length > 0) {
          const deletedData = metadata?.deleted?.join(", ") || "";
          const logItemsDeleted = {
            ...newAuditLogsItem,
            data: deletedData,
            operation: AuditLogsItemTypes.Delete,
            fieldLabel: `Removed ${metadata?.deleted?.length} ${
              keyOfListParse[key] ?? key
            }`,
          };
          auditLogsItems.push(logItemsDeleted);
        }
      } else {
        newAuditLogsItem.data = log.action.metadata[key];
        newAuditLogsItem.operation = AuditLogsItemTypes.Update;
        auditLogsItems.push(newAuditLogsItem);
      }
    });
  });
  return auditLogsItems;
};
