import { InputChip } from "components/Chip/InputChip";
import { Input } from "components/Input";
import React, { ChangeEvent, KeyboardEvent, useState } from "react";
import { v4 as uuid } from "uuid";
import { CHIP_SEPARATORS, isChipSeparatorKey } from "./const";
import {
  ChipsFieldDescription,
  ChipsFieldLabel,
  ChipsFieldWrapper,
  InputChipContainer,
} from "./styles";
import { ChipsFieldProps } from "./types";

export const ChipsField = ({
  onInputChange,
  chipList,
  setChipList,
  placeholder,
  description,
  inputLabel,
  inputId,
}: ChipsFieldProps): JSX.Element => {
  const [value, setValue] = useState("");

  const formatChips = () => {
    if (!value) return;

    const newChips = value.split(CHIP_SEPARATORS).filter(Boolean);
    setChipList([...new Set([...chipList, ...newChips])]);
    setValue("");
  };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (isChipSeparatorKey(event.key)) {
      event.preventDefault();
      formatChips();
    }
  };

  const onBlurCapture = () => formatChips();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value: updatedValue },
    } = event;

    setValue(updatedValue);
    onInputChange?.(event);
  };

  const onRemoveChip = (label: string) =>
    setChipList(chipList.filter((chipLabel) => chipLabel !== label));

  return (
    <ChipsFieldWrapper>
      <ChipsFieldLabel htmlFor={inputId}>{inputLabel}</ChipsFieldLabel>
      <ChipsFieldDescription>{description}</ChipsFieldDescription>
      <InputChipContainer>
        {chipList.map((chipLabel) => (
          <InputChip key={uuid()} label={chipLabel} onRemove={onRemoveChip} />
        ))}
        <Input
          type="text"
          value={value}
          onChange={onChange}
          onBlurCapture={onBlurCapture}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          tabIndex={0}
          autoComplete="off"
          id={inputId}
        />
      </InputChipContainer>
    </ChipsFieldWrapper>
  );
};
