import { Button, Heading, Paragraph } from "@hexa-ui/components";
import DefaultEmptyStateFigure from "assets/default-empty-state.svg";
import React from "react";
import { EmptyStateContainer } from "./styles";
import { EmptyStateProps } from "./types";

export const EmptyState = ({
  title,
  message,
  reload,
  figure,
}: EmptyStateProps): JSX.Element => {
  const emptyStateFigure = figure || DefaultEmptyStateFigure;

  return (
    <EmptyStateContainer>
      <img alt="" src={emptyStateFigure} />
      <Heading>{title}</Heading>
      {message && <Paragraph>{message}</Paragraph>}
      {reload && <Button onClick={reload.callback}>{reload.label}</Button>}
    </EmptyStateContainer>
  );
};
