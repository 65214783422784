import { styled } from "@bees/vision-tokens";

export const EmptyStateContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "$4",
  "&> *:not(:last-child)": {
    marginBottom: "$4",
  },
});
