import { X } from "@admin-portal-shared-components/icons";
import React from "react";
import * as Styled from "./styles";

export interface IModal {
  modalTitle: string;
  isModalOpen: boolean;
  handleButtonCloseModal: () => void;
  children: JSX.Element | JSX.Element[];
}

export function Modal({
  isModalOpen,
  handleButtonCloseModal,
  modalTitle,
  children,
}: IModal): JSX.Element {
  function handleBackgroundClose(event: React.MouseEvent<HTMLDivElement>) {
    const isSafeArea = event.target.closest('[data-safe-area="true"]');

    if (!isSafeArea) {
      handleButtonCloseModal();
    }
  }

  return (
    <>
      {isModalOpen && (
        <Styled.ModalBackground
          onClick={handleBackgroundClose}
          data-testid="modal-background"
        >
          <Styled.ModalContainer data-safe-area="true">
            <Styled.ModalHeader>
              <Styled.ModalTitle>{modalTitle}</Styled.ModalTitle>
              <Styled.ModalButtonClose
                onClick={handleButtonCloseModal}
                data-testid="close-modal"
              >
                <X size="medium" />
              </Styled.ModalButtonClose>
            </Styled.ModalHeader>
            {children}
          </Styled.ModalContainer>
        </Styled.ModalBackground>
      )}
    </>
  );
}
