import React from "react";
import { useIntl } from "react-intl";
import { Container } from "./styles";

interface IStatusProps {
  userStatus: string;
}

export function Status({ userStatus }: IStatusProps): JSX.Element {
  const intl = useIntl();
  return (
    <Container status={userStatus}>
      {intl.formatMessage({
        id: `userDetailsPage_userStatus_${userStatus}`,
      })}
    </Container>
  );
}
