import { ILocalStorageService } from "./LocalStorageService.types";

export class LocalStorageService implements ILocalStorageService {
  private localData: Record<string, unknown> | undefined =
    this.readLocalStorageData();

  private updateLocalStorageData(updatedValue: Record<string, unknown>): void {
    this.localData = { ...(this.localData || {}), ...updatedValue };

    localStorage.setItem("read", JSON.stringify(this.localData));
  }

  private readLocalStorageData(): Record<string, unknown> {
    if (this.localData) return this.localData;

    const readStorage = localStorage.getItem("read");

    if (!readStorage || readStorage === "undefined") return {};

    this.localData = JSON.parse(readStorage) as Record<string, unknown>;

    return this.localData;
  }

  public storeValue(
    storageKey: string,
    payload: unknown | ((previous: unknown) => unknown)
  ): boolean {
    const readStorage = this.readLocalStorageData();

    if (typeof payload !== "function") {
      readStorage[storageKey] = payload;
      this.updateLocalStorageData(readStorage);

      return true;
    }

    try {
      readStorage[storageKey] = payload(readStorage[storageKey]);
      this.updateLocalStorageData(readStorage);

      return true;
    } catch {
      return false;
    }
  }

  public getValue(storageKey: string): unknown {
    return this.localData?.[storageKey];
  }
}
