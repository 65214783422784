export enum FileStatus {
  FAILED = "failed",
  PROCESSING = "processing",
  PARTIALLY_SUCCESSFUL = "partiallySuccessful",
  SUCCESSFUL = "successful",
}

export enum BulkActionsEventCodes {
  AuthEvent = "NEW_IDENTITY_AUTHORIZATION_BULK_ACTION_FILE_AVAILABLE",
  InvitationEvent = "NEW_IDENTITY_CUSTOMER_INVITATION_BULK_ACTION_FILE_AVAILABLE",
}

export interface Metadata {
  description: string;
  userId: string;
  status: FileStatus;
  expirationDate: string;
  fileName: string;
}

export interface FileInfo {
  creationDate: string;
  linkExpirationTime: number;
  metadata: Metadata;
  title: string;
  type: string;
  url: string;
  urlExpiresAt: string;
}

export interface Pagination {
  page: number;
  pageSize: number;
  total: number;
}

export interface FileData {
  content: FileInfo[];
  pagination: Pagination;
}

export type FileResponse = Promise<FileData>;
