import React from "react";
import { RecentActivitiesSkeleton } from "./RecentActivitiesSkeleton/RecentActivitiesSkeleton";
import { StyledContainer } from "./styles";
import { UserDetailsSkeleton } from "./UserDetailSkeleton/UserDetailsSkeleton";

export function Skeleton(): JSX.Element {
  return (
    <StyledContainer data-testid="user-deatails-page-skeleton">
      <UserDetailsSkeleton />
      <div style={{ height: "100%", width: "1px", background: "#ddd" }} />
      <RecentActivitiesSkeleton />
    </StyledContainer>
  );
}
