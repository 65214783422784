import { StyleAvatar } from "components";
import { SnackBar } from "components/Snackbar/Snackbar";
import { initialLetters, SegmentService } from "helpers";
import { SegmentFormatData } from "helpers/segmentService/segmentFormatData";
import { IAccount, IUser } from "interfaces";
import queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { UserService } from "services/user";
import { IUserDetails } from "services/bff-service";
import { DisassociateButton } from "../DisassociationButton/DisassociateButton";
import { AccountListSkeleton } from "../Skeletons/AccountListSkeleton/AccountListSkeleton";
import * as Styled from "./styles";

const userService = new UserService();

type UserAccountsProps = {
  user: IUserDetails;
  userAccountList: (list: IAccount[]) => void;
};

export const UserAccounts = ({
  user,
  userAccountList,
}: UserAccountsProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const userCountry = params.country as string;
  const userId = params.value as string;
  const [accountsSelected, setAccountsSelected] = useState<string[]>([]);
  const [accountList, setAccountList] = useState<IAccount[]>([]);
  const [successDisassociationMessage, setSuccessDisassociationMessage] =
    useState("");
  const [errorDisassociationMessage, setErrorDisassociationMessage] =
    useState("");
  const [emptyState, setEmptyState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getUserAccounts = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await userService.userAccounts(userCountry, userId);
      const { data } = response;

      const userAccounts: IAccount[] = Array.isArray(data)
        ? data.filter((account) => account)
        : [];
      const isEmptyState = userAccounts.length === 0;

      setEmptyState(isEmptyState);
      setAccountList(userAccounts);
      userAccountList(userAccounts);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCountry, userId]);

  useEffect(() => {
    getUserAccounts();
  }, [getUserAccounts]);

  const handleSelectAll = () => {
    const accounts = accountList.map((account) => account.accountId);
    const dataAnalytics = SegmentFormatData.formatAccountSelection(user);

    if (accountsSelected.length === 0) {
      setAccountsSelected([...accounts]);
      SegmentService.allAccountSelection(dataAnalytics);
    } else {
      setAccountsSelected([]);
      SegmentService.allAccountDeselection(dataAnalytics);
    }
  };

  const handleSelectRow = (id: string): void => {
    const isSelected = accountsSelected.includes(id);
    const dataAnalytics = SegmentFormatData.formatAccountSelection(user);
    if (!isSelected) {
      setAccountsSelected([...accountsSelected, id]);
      SegmentService.userAccountSelection(dataAnalytics);
    } else {
      setAccountsSelected((accounts) =>
        accounts.filter((accountId) => accountId !== id)
      );
      SegmentService.userAccountDeselection(dataAnalytics);
    }
  };

  const callbackDisassociationResult = (
    results: PromiseSettledResult<unknown>[]
  ) => {
    if (Array.isArray(results)) {
      const numberOfSuccess = results.length;
      const numberOfErrors = accountsSelected.length - numberOfSuccess;

      if (numberOfSuccess > 0) {
        setSuccessDisassociationMessage(
          `${formatMessage(
            {
              id: "userAccounts_snackbarSuccessfullyRemoveAccounts",
            },
            { amountOfAccounts: numberOfSuccess }
          )}`
        );
      }

      if (numberOfErrors > 0) {
        setErrorDisassociationMessage(
          `${formatMessage(
            {
              id: "userAccounts_snackbarFailureRemoveAccounts",
            },
            { amountOfAccounts: numberOfErrors }
          )}`
        );
      }
    } else {
      setSuccessDisassociationMessage(
        `${formatMessage({
          id: "userAccounts_snackbarFailureToRemoveAnyAccounts",
        })}`
      );
    }

    setAccountsSelected([]);
    getUserAccounts();
  };

  return (
    <Styled.AccountContainer>
      <Styled.AccountHeader>
        <h3>
          {formatMessage({ id: "userAccounts_title" })}
          {accountList.length > 0 && (
            <span data-testid="counter">{accountList.length}</span>
          )}
        </h3>

        <Styled.AccountHeaderContainer>
          <Styled.SelectAll disabled={accountList.length === 0}>
            <Styled.CustomCheckbox
              size="small"
              data-checkbox
              data-testid="selectall"
              disabled={accountList.length === 0}
              onClick={handleSelectAll}
              checked={accountsSelected.length > 0}
              indeterminate={
                accountsSelected.length < accountList.length &&
                accountsSelected.length > 0
              }
            />
            {formatMessage({ id: "userAccounts_selectAll" })}
          </Styled.SelectAll>
          <DisassociateButton
            user={user}
            userId={userId}
            accounts={accountsSelected}
            accountsUserAssociated={accountList.length}
            onComplete={callbackDisassociationResult}
            country={userCountry}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </Styled.AccountHeaderContainer>
      </Styled.AccountHeader>

      {accountList.length === 0 && !isLoading && (
        <Styled.EmptySpace>
          {formatMessage({ id: "userAccounts_emptyState" })}
        </Styled.EmptySpace>
      )}

      {isLoading && <AccountListSkeleton />}

      {accountList.length > 0 && !isLoading && !emptyState && (
        <Styled.AccountList>
          {accountList.map((account, index) => (
            <Styled.AccountListItem
              key={account?.accountId}
              active={accountsSelected.includes(account?.accountId)}
              onClick={() => handleSelectRow(account?.accountId)}
            >
              <Styled.CustomCheckbox
                size="small"
                data-checkbox
                data-testid={`checkbox-item-${index}`}
                checked={accountsSelected.includes(account?.accountId)}
                aria-hidden="true"
              />
              <StyleAvatar
                data-avatar
                size="48px"
                fontSize="20px"
                style={{ marginLeft: 0 }}
              >
                {initialLetters(account.name)}
              </StyleAvatar>

              <div role="contentinfo">
                <h4>{account.name}</h4>
                <p>
                  {formatMessage(
                    { id: "userAccounts_list_accountId" },
                    { accountId: account?.customerAccountId }
                  )}
                </p>
              </div>
            </Styled.AccountListItem>
          ))}
        </Styled.AccountList>
      )}
      <SnackBar
        open={
          Boolean(successDisassociationMessage) ||
          Boolean(errorDisassociationMessage)
        }
        onClose={() => {
          setSuccessDisassociationMessage("");
          setErrorDisassociationMessage("");
        }}
        message={successDisassociationMessage || errorDisassociationMessage}
        severity={successDisassociationMessage ? "success" : "error"}
      />
    </Styled.AccountContainer>
  );
};
