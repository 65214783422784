export const sortByName = (a: any, b: any) => {
  const name1 = String(a.app ? `${a.app}.${a.name}` : a.name).trim();
  const name2 = String(b.app ? `${b.app}.${b.name}` : b.name).trim();
  if (name1 > name2) {
    return 1;
  }
  if (name1 < name2) {
    return -1;
  }
  return 0;
};
