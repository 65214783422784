import { IUserMetadata } from "interfaces";
import { useMemo } from "react";
import { useUserMetadata } from "admin-portal-shared-services";

/**
 * Accesses the user info metadata to retrieve its scopes and
 * check if has global manager scope.
 * @returns boolean
 */

export function useIsGlobalManager(): boolean {
  const { data } = useUserMetadata();

  return (
    data?.authorization?.scopes?.includes(
      "IdentityMetadata.Authorization.Write"
    ) || false
  );
}

export function useLoggedUser(): IUserMetadata | undefined {
  const { data } = useUserMetadata();
  const loggedUser: unknown = useMemo(() => data, [data]);
  return loggedUser as IUserMetadata;
}

export function useUserGroupsList(): string[] | undefined {
  const groups = useLoggedUser()?.authorization.groups;
  const memoGroups = useMemo(
    () =>
      groups?.reduce((acc: string[], group: string) => {
        const [, app] = group.split(".").reverse();
        if (app?.toUpperCase() !== "TIER") {
          acc.push(group);
        }
        return acc;
      }, []),
    [groups]
  );
  return memoGroups;
}

export function useUserSupportedCountries(): string[] | undefined {
  const supportedCountries = useLoggedUser()?.supportedCountries;
  const memoSupportedCountries = useMemo(
    () => supportedCountries,
    [supportedCountries]
  );
  return memoSupportedCountries;
}
