import {
  IGetUserEditableDataResponse,
  IPutUserDataParams,
} from "identity-admin-contracts/user-management";
import { axiosClient } from "@shared/api";

export const putUserData = ({
  userId,
  countries,
  groups,
  managedBy,
  userApplication,
  userCountry,
  supervisors,
}: IPutUserDataParams) =>
  axiosClient.put<IGetUserEditableDataResponse>(
    "/users/edit-details",
    {
      userId,
      managedBy,
      countries,
      groups,
      supervisors,
    },
    {
      params: {
        userApplication,
        userCountry,
      },
    },
  );
