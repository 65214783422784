import { StyleAvatar } from "components";
import { initialLetters } from "helpers";
import { allApplications } from "helpers/Applications/applications";
import { formatDate } from "helpers/FormatDate/FormatDate";
import React, { ReactNode } from "react";
import { useIntl } from "react-intl";
import { formatCountry, sortCountryListAlphabetically } from "country-utils";
import { IUserDetails } from "services/bff-service";
import { IAccount } from "interfaces";
import { CopiedCriteria } from "identity-admin-mfe/modules/user-management/services/AnalyticService";
import { OutputCopy } from "../OutputCopy/OutputCopy";
import { Skeleton } from "../Skeletons/Skeleton";
import { Status } from "../Status/Status";
import * as Styled from "./styles";
import { UserInviteActions } from "../UserInviteActions";
import { StatusEnum } from "../Status/types";

interface PropItems {
  user: IUserDetails;
  isLoading: boolean;
  accounts: IAccount[];
}

interface IUserItemInfo {
  label: string;
  description?: string;
  children?: ReactNode;
}

export function UserListInfo({
  user,
  isLoading,
  accounts,
}: PropItems): JSX.Element {
  const intl = useIntl();

  const status = user.accountEnabled ? user.status : StatusEnum.BLOCKED;
  function UserItemInfo({
    label,
    description,
    children,
  }: IUserItemInfo): JSX.Element {
    const childProps = children?.props;
    const hasText = childProps?.text;
    const hasUserStatus = childProps?.userStatus;
    const hasItems = childProps?.items && childProps.items.length > 0;

    const childValidated =
      hasText || hasUserStatus || hasItems ? children : null;
    const output = description || childValidated || "-";

    return (
      <li>
        <label>{label}</label>
        <div>{output}</div>
      </li>
    );
  }

  interface IChipListProps {
    items: string[];
  }
  function ChipList({ items }: IChipListProps): JSX.Element {
    return (
      <Styled.CustomChipContainer>
        {items.map((item) => (
          <Styled.CustomChip key={item}>{item}</Styled.CustomChip>
        ))}
      </Styled.CustomChipContainer>
    );
  }

  return (
    <>
      {isLoading && <Skeleton />}

      {user && !isLoading && (
        <Styled.Container>
          <Styled.Header>
            <Styled.UserNameContainer>
              <StyleAvatar size="72px" fontSize="20px">
                {initialLetters(user.displayName)}
              </StyleAvatar>
              <ul>
                <li role="heading">{user.displayName}</li>
                {user.application && (
                  <li role="complementary">
                    {allApplications[
                      user.application as keyof typeof allApplications
                    ] ||
                      `${intl.formatMessage({
                        id: "userDetailsPage_customer",
                      })}`}
                  </li>
                )}
              </ul>
            </Styled.UserNameContainer>

            <UserInviteActions user={user} accounts={accounts} />
          </Styled.Header>
          <Styled.UserInfoHeaderSection>
            <Styled.List>
              <UserItemInfo
                label={`${intl.formatMessage({
                  id: "userDetailsPage_userInfoLabelEmail",
                })}`}
              >
                <OutputCopy text={user.email} criteria={CopiedCriteria.Email} />
              </UserItemInfo>
              <UserItemInfo
                label={`${intl.formatMessage({
                  id: "userDetailsPage_userInfoLabelCountry",
                })}`}
                description={formatCountry(user.country)}
              />
            </Styled.List>

            <Styled.List>
              <UserItemInfo
                label={`${intl.formatMessage({
                  id: "userDetailsPage_userInfoLabelPhone",
                })}`}
              >
                <OutputCopy text={user.phone} criteria={CopiedCriteria.Phone} />
              </UserItemInfo>
              <UserItemInfo
                label={`${intl.formatMessage({
                  id: "userDetailsPage_userInfoLabelUserId",
                })}`}
              >
                <OutputCopy text={user.id} criteria={CopiedCriteria.Id} />
              </UserItemInfo>
            </Styled.List>
          </Styled.UserInfoHeaderSection>

          <Styled.UserInfoSection>
            <Styled.List>
              <UserItemInfo
                label={`${intl.formatMessage({
                  id: "userDetailsPage_userInfoLabelSupportedCountries",
                })}`}
              >
                <ChipList
                  items={sortCountryListAlphabetically(
                    user.supportedCountries
                  ).map((country) => formatCountry(country))}
                />
              </UserItemInfo>
            </Styled.List>
          </Styled.UserInfoSection>

          <Styled.UserInfoSection>
            <Styled.List>
              <UserItemInfo
                label={`${intl.formatMessage({
                  id: "userDetailsPage_userInfoLabelPermissionGroups",
                })}`}
              >
                <ChipList items={user.groups} />
              </UserItemInfo>
            </Styled.List>
          </Styled.UserInfoSection>

          <Styled.UserInfoSection>
            <Styled.List>
              <UserItemInfo
                label={`${intl.formatMessage({
                  id: "userDetailsPage_userInfoLabelCompanyIds",
                })}`}
              >
                <ChipList items={user.vendors} />
              </UserItemInfo>
            </Styled.List>
          </Styled.UserInfoSection>

          <Styled.UserInfoSection>
            <Styled.List>
              {user.routeId && (
                <UserItemInfo
                  label={`${intl.formatMessage({
                    id: "userDetailsPage_userInfoLabelRouteId",
                  })}`}
                  description={user.routeId}
                />
              )}
            </Styled.List>
          </Styled.UserInfoSection>

          {user.bdrId && (
            <Styled.UserInfoSection>
              <Styled.List>
                <UserItemInfo
                  label={`${intl.formatMessage({
                    id: "userDetailsPage_userInfoLabelBdrId",
                  })}`}
                  description={user.bdrId}
                />
              </Styled.List>
            </Styled.UserInfoSection>
          )}

          {Boolean(user?.logisticOperatorIds?.length) && (
            <Styled.UserInfoSection>
              <Styled.List>
                <UserItemInfo
                  label={`${intl.formatMessage({
                    id: "userDetailsPage_userInfoLabelLogisticOperatorIds",
                  })}`}
                >
                  <ChipList items={user.logisticOperatorIds} />
                </UserItemInfo>
              </Styled.List>
            </Styled.UserInfoSection>
          )}

          {Boolean(user?.deliveryCenterIds?.length) && (
            <Styled.UserInfoSection>
              <Styled.List>
                <UserItemInfo
                  label={`${intl.formatMessage({
                    id: "userDetailsPage_userInfoLabelDeliveryCenterIds",
                  })}`}
                >
                  <ChipList items={user.deliveryCenterIds} />
                </UserItemInfo>
              </Styled.List>
            </Styled.UserInfoSection>
          )}

          <Styled.List grid>
            <UserItemInfo
              label={`${intl.formatMessage({
                id: "userDetailsPage_userInfoLabelUserStatus",
              })}`}
            >
              <Status userStatus={status} />
            </UserItemInfo>

            <UserItemInfo
              label={`${intl.formatMessage({
                id: "userDetailsPage_userInfoLabelCreatedAt",
              })}`}
              description={formatDate(user.createdAt)}
            />
            <UserItemInfo
              label={`${intl.formatMessage({
                id: "userDetailsPage_userInfoLabelLastInvitedAt",
              })}`}
              description={formatDate(user.lastInvitedAt)}
            />
            <UserItemInfo
              label={`${intl.formatMessage({
                id: "userDetailsPage_userInfoLabelRegisteredAt",
              })}`}
              description={
                user.registeredAt ? formatDate(user.registeredAt) : "-"
              }
            />
          </Styled.List>
        </Styled.Container>
      )}
    </>
  );
}
