import { Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";

function AccountItemSkeleton(): JSX.Element {
  return (
    <Box
      display="flex"
      alignItems="center"
      height="72px"
      padding="0 32px"
      component="li"
      data-testid="skeleton item"
    >
      <Skeleton variant="circle" width={48} height={48} />

      <Box marginLeft="16px">
        <Skeleton variant="text" width={160} height={20} />
        <Skeleton variant="text" width={224} height={20} />
      </Box>
    </Box>
  );
}

export function AccountListSkeleton(): JSX.Element {
  const accountListSkeleton = [1, 2];

  return (
    <ul data-testid="skeleton list">
      {accountListSkeleton.map((account) => (
        <AccountItemSkeleton key={account} />
      ))}
    </ul>
  );
}
