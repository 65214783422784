import { SkeletonLoader } from "@hexa-ui/components";
import React from "react";

export const SkeletonAuditLogs = (): JSX.Element => {
  const skeletonItems = Array.from({ length: 10 }, (_, index) => index);

  return (
    <div data-testid="skeleton-audit-log-modal">
      {skeletonItems.map((item) => (
        <div style={{ marginTop: "1rem" }} key={item}>
          <SkeletonLoader
            height="4rem"
            width={`${Math.floor(Math.random() * (100 - 50 + 1) + 50)}%`}
          />
        </div>
      ))}
    </div>
  );
};
