import styled, { css } from "styled-components";
import { StatusEnum } from "./types";

interface StyledStatusProps {
  status: string;
}

const statusColor = css<StyledStatusProps>`
  ${({ status }) => {
    switch (status) {
      case StatusEnum.ACTIVE:
        return css`
          background-color: #d1f7f6;
        `;
      case StatusEnum.PENDING:
        return css`
          background-color: #faff5f;
        `;
      case StatusEnum.BLOCKED:
        return css`
          background-color: #c9201d;
          color: #fff;
        `;
      default:
        return css`
          background-color: #424242;
          color: #fff;
        `;
    }
  }};
`;

export const Container = styled.div<StyledStatusProps>`
  ${statusColor}
  width: max-content;
  font-size: 0.875rem;
  border-radius: 1rem;
  text-align: center;
  padding: 0 0.5rem;
`;
