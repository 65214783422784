import styled, { css } from "styled-components";

interface Props {
  grid?: boolean;
}

const gridList = css`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;

  & li {
    margin-bottom: 0;
  }
`;

export const Container = styled.div`
  padding: 2rem 2rem 0 2rem;
`;

export const UserNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  & ul {
    list-style: none;
    margin-left: 1rem;

    li:first-child {
      color: rgba(0, 0, 0, 0.9);
      font-family: "Barlow";
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 2rem;
    }
    li:last-child {
      color: rgba(0, 0, 0, 0.55);
      font-family: "Work Sans";
      font-size: 1rem;
      letter-spacing: 0.006rem;
      line-height: 1.5rem;
    }
  }
`;

export const List = styled.ul<Props>`
  list-style: none;

  li {
    & label {
      color: rgba(0, 0, 0, 0.55);
      font-size: 0.875rem;
      letter-spacing: 0.006rem;
      line-height: 1.25rem;
      margin-bottom: 0.25rem;
    }

    & p {
      color: rgba(0, 0, 0, 0.9);
      font-size: 1rem;
      letter-spacing: 0.006rem;
      line-height: 1.5rem;
    }
  }

  ${(props) => props.grid && gridList}
`;

export const UserInfoSection = styled.section`
  margin-bottom: 1rem;
`;

export const UserInfoHeaderSection = styled.section`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const CustomChipContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  list-style: none;
`;

export const CustomChip = styled.li`
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.16);
  width: max-content;
  color: #000000;
  font-family: "Work Sans";
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1rem;
`;

export const UserInviteActionsContainer = styled.div`
  display: flex;
`;
