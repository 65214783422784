import { IGetUserDetailsParams } from "identity-admin-contracts/user-management";
import { axiosClient } from "@shared/api";

export const getUserDetailsV2 = ({
  appName,
  country,
  userId,
}: IGetUserDetailsParams) =>
  axiosClient.get(`/user-management/v2/details/${userId}`, {
    params: {
      appName,
      country,
    },
  });
