import { axiosClient } from "@shared/api";
import {
  IGetUsersByTypeParams,
  IGetUsersByTypeResponse,
} from "identity-admin-contracts/user-management";

export const getUsersByUserType = ({
  appName,
  value,
  country,
}: IGetUsersByTypeParams) =>
  axiosClient.get<IGetUsersByTypeResponse>(`/user-management/search`, {
    params: {
      value,
      country,
      filter: appName,
    },
  });
