import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  flex: 1;
  height: 100%;
`;

export const Description = styled.p`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.9);
  margin-top: 24px;
`;
