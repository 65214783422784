import { TableBody } from "@material-ui/core";
import React from "react";
import * as Styled from "./styles";

interface Props {
  active: boolean;
}

export const UserListSkeleton = ({ active }: Props): JSX.Element => {
  const listSize = [1, 2, 3, 4, 5, 6, 7, 8];

  return (
    <TableBody>
      {listSize.map((item) => (
        <Styled.StyledTableRow $isActive={active} key={item} data-id={item}>
          <Styled.StyledTableCell />
          <Styled.StyledTableCell align="left">
            <Styled.StyledSkeleton />
          </Styled.StyledTableCell>
          <Styled.StyledTableCell align="left">
            <Styled.StyledSkeleton />
          </Styled.StyledTableCell>
          <Styled.StyledTableCell align="left">
            <Styled.StyledSkeleton />
          </Styled.StyledTableCell>
          <Styled.StyledTableCell align="left">
            <Styled.StyledSkeleton />
          </Styled.StyledTableCell>
        </Styled.StyledTableRow>
      ))}
    </TableBody>
  );
};
