import { styled } from "@bees/vision-tokens";

export const ChipsFieldWrapper = styled("div", {
  width: "100%",
});

export const ChipsFieldLabel = styled("label", {
  fontSize: "$2",
  fontWeight: "$semibold",
  lineHeight: "$5",
  color: "$neutral90",
});

export const ChipsFieldDescription = styled("p", {
  fontSize: "$1",
  lineHeight: "$3",
  color: "$neutral50",
});

export const InputChipContainer = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "baseline",
  cursor: "text",
  boxSizing: "border-box",
  width: "100%",
  minHeight: "6rem",
  borderWidth: "$hairline",
  borderColor: "$neutral30",
  borderStyle: "solid",
  borderRadius: "$2",
  backgroundColor: "$neutral0",
  padding: "$4 $2",
  gap: "$2",
  input: {
    borderWidth: "$none",
    ":focus": {
      outline: "none",
    },
  },
});
