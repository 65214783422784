import { useAuthenticationService as authService } from "admin-portal-shared-services";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { getToken } from "helpers";
import { IUser, IVendor } from "interfaces";
import { IDisassociateParam } from "pages/UserDetails/Components/DisassociationButton/DisassociateButton";
import { getApiHost } from "services/host/HostService";
import { v4 } from "uuid";
import {
  DeleteMetadataByTypeResponse,
  SearchUsersResponse,
  UserAccountsResponse,
  UserDetailsParams,
  UserDetailsResponse,
  UserSearchParams,
} from "./types";

function validateIgnoreCountry(appName: string): boolean {
  const apps = ["b2b", "dtc_courier", "dtc_consumer", "dtc_seller"];
  return !apps.includes(appName);
}

export class UserService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create();
  }

  async searchUsers(
    country: string,
    { searchQuery, appName }: UserSearchParams
  ): SearchUsersResponse {
    const ignoreCountry = validateIgnoreCountry(appName);
    const BASE_URL = getApiHost({ appName, country });
    const URL = `${BASE_URL}/users/`;
    const headers = {
      country,
      requestTraceId: v4(),
      Authorization: getToken(),
    };

    const params = {
      value: searchQuery,
      appName,
      ignoreCountry,
    };
    const { data } = await authService()
      .enhancedAxios(this.axiosInstance)
      .get(URL, { params, headers });

    if (data?.length) {
      return data.map((user: IUser) => ({ ...user, appName }));
    }

    return [];
  }

  async userDetails(
    userCountry: string,
    { userId, appName }: UserDetailsParams
  ): UserDetailsResponse {
    const BASE_URL = getApiHost({ appName, country: userCountry });
    const URL = `${BASE_URL}/user-mfe/users/details?value=${userId}&appName=${appName}`;

    const headers = {
      country: userCountry,
      requestTraceId: v4(),
      Authorization: getToken(),
    };

    try {
      const { data, status } = await authService()
        .enhancedAxios(this.axiosInstance)
        .get(URL, { headers });
      return { ...data, statusCode: status };
    } catch (error: any) {
      return { ...error?.response?.data, statusCode: error?.response?.status };
    }
  }

  async getUserVendors(): Promise<IVendor[]> {
    const BASE_URL = getApiHost();
    const headers = {
      requestTraceId: v4(),
      Authorization: getToken(),
    };

    const URL = `${BASE_URL}/users/vendors/`;

    try {
      const { data } = await authService()
        .enhancedAxios(this.axiosInstance)
        .get(URL, { headers });
      return data;
    } catch (err: any) {
      throw new Error(err.message);
    }
  }

  async userAccounts(
    userCountry: string,
    userId: string
  ): UserAccountsResponse {
    const BASE_URL = getApiHost();
    const URL = `${BASE_URL}/user-mfe/users/accounts?userId=${userId}`;

    const headers = {
      country: userCountry,
      requestTraceId: v4(),
      Authorization: getToken(),
    };

    const { data } = await authService()
      .enhancedAxios(this.axiosInstance)
      .get(URL, { headers });

    return data;
  }

  async userDisassociate(
    userCountry: string,
    payload: IDisassociateParam[]
  ): Promise<Array<any>> {
    const BASE_URL = getApiHost();
    const URL = `${BASE_URL}/user-mfe/users/disassociate`;

    const headers = {
      country: userCountry,
      requestTraceId: v4(),
      Authorization: getToken(),
    };

    try {
      const { data } = await authService()
        .enhancedAxios(this.axiosInstance)
        .delete(URL, { headers, data: payload });
      return data;
    } catch (err: any) {
      return err;
    }
  }

  async userBlock(
    userCountry: string,
    userId: string,
    blocked: boolean,
    appName?: string
  ): Promise<any> {
    const BASE_URL = getApiHost();
    const URL = `${BASE_URL}/user-mfe/users/block`;

    const headers = {
      country: userCountry,
      requestTraceId: v4(),
      Authorization: getToken(),
      appName,
    };

    const payload = {
      userId,
      blocked,
    };

    const { data } = await authService()
      .enhancedAxios(this.axiosInstance)
      .post(URL, payload, { headers });
    return data;
  }

  async postMetadataByType(
    type: "SUPPORTED_COUNTRY" | "VENDOR",
    userId: string | undefined,
    country: string,
    value: string
  ): Promise<boolean> {
    const BASE_URL = getApiHost();
    const URL = `${BASE_URL}/user-mfe/users/metadata`;
    const headers = {
      country,
      requestTraceId: v4(),
      Authorization: getToken(),
    };

    const payload = {
      type,
      userId,
      value,
    };

    try {
      const { status }: AxiosResponse = await authService()
        .enhancedAxios(this.axiosInstance)
        .post(URL, payload, { headers });
      return status == 200;
    } catch (error: any) {
      return false;
    }
  }

  async deleteMetadataByType(
    type: "SUPPORTED_COUNTRY" | "VENDOR",
    userId: string | undefined,
    country: string,
    value: string
  ): DeleteMetadataByTypeResponse {
    const BASE_URL = getApiHost();
    const URL = `${BASE_URL}/user-mfe/users/metadata`;
    const headers = {
      country,
      requestTraceId: v4(),
      Authorization: getToken(),
    };

    const payload = {
      type,
      userId,
      value,
    };

    try {
      const { status }: AxiosResponse = await authService()
        .enhancedAxios(this.axiosInstance)
        .delete(URL, { headers, data: payload });
      return status === 200;
    } catch {
      return false;
    }
  }

  async postMetadataSeveralValues(
    country: string,
    users: {
      type: string;
      userId: string;
      value: string;
    }[]
  ): Promise<boolean> {
    if (!users.length) return true;

    const BASE_URL = getApiHost();
    const URL = `${BASE_URL}/user-mfe/users/metadata`;
    const headers = {
      country,
      requestTraceId: v4(),
      Authorization: getToken(),
    };

    const payload = users;

    try {
      const { status }: AxiosResponse = await authService()
        .enhancedAxios(this.axiosInstance)
        .post(URL, payload, { headers });
      return status === 200;
    } catch {
      return false;
    }
  }

  async deleteMetadataSeveralValues(
    country: string,
    users: {
      type: string;
      userId: string;
      value: string;
    }[]
  ): DeleteMetadataByTypeResponse {
    if (!users.length) return true;

    const BASE_URL = getApiHost();
    const URL = `${BASE_URL}/user-mfe/users/metadata`;
    const headers = {
      country,
      requestTraceId: v4(),
      Authorization: getToken(),
    };

    const payload = users;

    try {
      const { status }: AxiosResponse = await authService()
        .enhancedAxios(this.axiosInstance)
        .delete(URL, { headers, data: payload });
      return status === 200;
    } catch {
      return false;
    }
  }

  async updateMetadataEntry(
    country: string,
    payload: Record<string, unknown>
  ): Promise<boolean> {
    const BASE_URL = getApiHost();
    const URL = `${BASE_URL}/user-mfe/users/metadata`;
    const headers = {
      country,
      requestTraceId: v4(),
      Authorization: getToken(),
    };

    try {
      const { status }: AxiosResponse = await authService()
        .enhancedAxios(this.axiosInstance)
        .put(URL, payload, { headers });
      return status === 200;
    } catch {
      return false;
    }
  }
}
