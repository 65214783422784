/* eslint-disable react-hooks/rules-of-hooks */
/* Since the admin-portal uses the enhancedAxios as a hook, there is no other alternative than to call is as a hook */
import axios from "axios";
import { useAuthenticationService } from "admin-portal-shared-services";
import { v4 as uuid } from "uuid";

const generateAxiosClient = () => {
  const beesAuth = useAuthenticationService();
  const authToken = localStorage.getItem("authHeader") || undefined;

  const instance = axios.create({
    baseURL: process.env.API_BASE_URL ?? "/api/identity-bff/v2",
    headers: {
      Authorization: authToken,
      requestTraceId: uuid(),
    },
  });

  beesAuth.enhancedAxios(instance);

  return instance;
};

export const axiosClient = generateAxiosClient();
