import { X } from "@admin-portal-shared-components/icons";
import React from "react";
import { Chip } from "./styles";
import { InputChipProps } from "./types";

export const InputChip = ({
  label,
  icon,
  onRemove,
  isDisabled = false,
}: InputChipProps): JSX.Element => (
  <Chip isDisabled={isDisabled}>
    {icon}
    <p>{label}</p>
    {Boolean(onRemove) && (
      <button
        type="button"
        onClick={() => onRemove?.(label)}
        disabled={isDisabled}
        aria-label="remove"
      >
        <X size="tiny" />
      </button>
    )}
  </Chip>
);
