import {
  IChartNavigation,
  IReporterData,
  ScreenName,
  UserApplications,
  ICountryFiltered,
  IUserClicked,
  IUserListViewed,
  IUserSearchCriteria,
} from "./types";

import segmentAnalytics from "../../../shared/analytics";

export class AnalyticService {
  static chartNavigation = ({
    isManager,
    isReporter,
  }: IChartNavigation): void => {
    segmentAnalytics.chartNavigation({
      screen_name: ScreenName.UsersChart,
      manager_redirection: isManager,
      reporter_redirection: isReporter,
    });
  };

  static chartUserEmailCopied = ({ country, email }: IReporterData): void => {
    segmentAnalytics.reporterEmailCopy({
      screen_name: ScreenName.UsersChart,
      reporter_country: country,
      reporter_email: email,
    });
  };

  static chartUserDetailsRedirection = ({
    country,
    email,
  }: IReporterData): void => {
    segmentAnalytics.reporterDetailsRedirection({
      screen_name: ScreenName.UsersChart,
      reporter_country: country,
      reporter_email: email,
    });
  };

  static userViewedPage = (): void => {
    segmentAnalytics.userViewed({
      screen_name: ScreenName.Users,
    });
  };

  static userTypeSelected = (userType: UserApplications): void => {
    segmentAnalytics.userTypeSelected({
      screen_name: ScreenName.Users,
      user_type: userType,
    });
  };

  static countryFiltered({ country, userType }: ICountryFiltered): void {
    segmentAnalytics.countryFiltered({
      country,
      user_type: userType,
      screen_name: ScreenName.Users,
    });
  }

  static userListViewed({
    country,
    information,
    listedItems,
    searchCriteria,
    userType,
  }: IUserListViewed): void {
    segmentAnalytics.userListViewed({
      country,
      information,
      listed_items: listedItems,
      search_criteria: searchCriteria,
      user_type: userType,
      screen_name: ScreenName.Users,
    });
  }

  static userClicked({ clickedItemPosition, listedItems }: IUserClicked): void {
    segmentAnalytics.userClicked({
      clicked_item_position: clickedItemPosition,
      listed_items: listedItems,
      screen_name: ScreenName.Users,
    });
  }

  static userSearchCriteria({
    searchCriteria,
    userType,
  }: IUserSearchCriteria): void {
    segmentAnalytics.userSearchCriteria({
      screen_name: ScreenName.Users,
      search_criteria: searchCriteria,
      user_type: userType,
    });
  }
}
