import React from "react";
import * as Styled from "./styles";

export function DotSpinner(): JSX.Element {
  return (
    <Styled.DotSpinnerContainer>
      <Styled.Dot />
      <Styled.Dot />
      <Styled.Dot />
    </Styled.DotSpinnerContainer>
  );
}
