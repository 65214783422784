import {
  IGetUserEditableDataParams,
  IGetUserEditableDataResponse,
} from "identity-admin-contracts/user-management";
import { axiosClient } from "@shared/api";

export const getUserEditableData = ({ userId }: IGetUserEditableDataParams) =>
  axiosClient.get<IGetUserEditableDataResponse>(`/users/edit-params`, {
    params: {
      userId,
    },
  });
