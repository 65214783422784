import {
  IGetDirectReportsDetailsParams,
  TGetDirectReportsDetailsResponse,
} from "identity-admin-contracts/user-management";
import { axiosClient } from "@shared/api";

export const getDirectReportsDetails = ({
  directReportsEncodedString,
}: IGetDirectReportsDetailsParams) =>
  axiosClient.get<TGetDirectReportsDetailsResponse>(
    `/user-management/direct-reports-details`,
    {
      params: { directReportsEncodedString },
    },
  );
