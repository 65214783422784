import { Plus } from "@admin-portal-shared-components/icons";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { Container, ContainerIcon } from "./styles";

interface IAddUsersButton {
  onClick: () => void;
}

export const AddUsersButton: FC<IAddUsersButton> = ({ onClick }) => {
  const intl = useIntl();
  return (
    <Container onClick={onClick}>
      <ContainerIcon>
        <Plus size="medium" />
      </ContainerIcon>
      {intl.formatMessage({ id: "userPage_addUsers" })}
    </Container>
  );
};
