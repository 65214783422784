import { IUser } from "interfaces";
import { UserContactTypes } from "interfaces/IUser";
import { IUserDetails } from "services/bff-service";
import {
  Countries,
  FilterCriteria,
  InformationItem,
  ListType,
  ScreenName,
  SearchCriteria,
  UserStatus,
} from "identity-admin-mfe/modules/user-management/services/AnalyticService";
import {
  AccountDeselection,
  AccountRemovalSubmitted,
  AccountSelection,
  AllAccountDeselection,
  AllAccountSelection,
  CountryFiltered,
  UserBlocked,
  UserClicked,
  UserDetailsViewed,
  UserEditingSubmissionResult,
  UserEditingSubmitted,
  UserListViewed,
  UserSearchCriteria,
  UserUnblocked,
} from "../../analytics";

import {
  EnumFilterValue,
  FormatUserEditingSubmittedParams,
  IPermissionFormatUserBlocked,
} from "./types";

enum SegmentUserStatus {
  Active = "ACTIVE",
  Blocked = "BLOCKED",
}

export class SegmentFormatData {
  private static validateValueType(value: string): SearchCriteria {
    const regexEmail =
      /^([a-zA-Z0-9+_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const regexPhone =
      /^([+]{0,1})?([0-9]{0,3})?([ ])?([(]{0,1})?([0-9]{1,3})?([)]{0,1})?([ ])?([0-9]{4,5})?([ ])?([-])?([ ])?([0-9]{4})$/;
    const regexId =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    const regexName = /^[A-Za-zÀ-ú.\s]+$/;

    if (regexEmail.test(value)) {
      return SearchCriteria.Email;
    }
    if (regexPhone.test(value)) {
      return SearchCriteria.Phone;
    }
    if (regexId.test(value)) {
      return SearchCriteria.Id;
    }
    if (regexName.test(value)) {
      return SearchCriteria.Name;
    }
    return SearchCriteria.Other;
  }

  private static parseInformationsAnalitycs(
    users: IUser[] | undefined
  ): InformationItem[] {
    const informations = users?.reduce((ccInfos: InformationItem[], user) => {
      const infos: InformationItem[] = user.contacts.map((contact) => {
        return {
          user_email:
            contact.type === UserContactTypes.emailAddress
              ? contact.value
              : null,
          user_phone:
            contact.type === UserContactTypes.phoneNumber
              ? contact.value
              : null,
          user_status: user.status as UserStatus,
          accounts_associated: user?.customData?.accounts?.length || 0,
        };
      });
      ccInfos.push(...infos);
      return ccInfos;
    }, []);
    return informations || [];
  }

  static countryFiltered(selectedCountry: string): CountryFiltered {
    return {
      country: selectedCountry as Countries,
      filter_criteria: FilterCriteria.Country,
      list_type: ListType.Users,
      screen_name: ScreenName.Users,
    };
  }

  static formatUserBlocked(
    userDetail: IUserDetails,
    permissions: IPermissionFormatUserBlocked
  ): UserUnblocked | UserBlocked {
    return {
      account_remove_permission: permissions.canDisassociteAccountsOfUser,
      associated_accounts: userDetail?.accounts.length || 0,
      block_permission: permissions.hasPermissionToBlock,
      edit_permission: permissions.hasUserWritePermission,
      screen_name: ScreenName.UserDetails,
      "supported country": userDetail?.supportedCountries?.map((sc) => ({
        supported_countries: sc,
      })),
      country: userDetail.country as Countries,
      permissions: userDetail?.groups?.map((group: string) => ({
        permissions_groups: group,
      })),
      user_email: userDetail?.email || null,
      user_phone: userDetail?.phone || null,
      user_status: userDetail.accountEnabled
        ? SegmentUserStatus.Active
        : SegmentUserStatus.Blocked,
      companies: userDetail?.vendors?.map((vendor: string) => ({
        company_ids: vendor,
      })),
    };
  }

  static formatUserClicked(data: {
    userCounts: number;
    clickedItemPosition: number;
  }): UserClicked {
    return {
      screen_name: ScreenName.Users,
      clicked_item_position: data.clickedItemPosition || null,
      listed_items: data.userCounts || 0,
    };
  }

  static formatUserSearchCriteria(searchCriteria: string): UserSearchCriteria {
    return {
      screen_name: ScreenName.Users,
      search_criteria: this.validateValueType(searchCriteria),
      list_type: ListType.Users,
    };
  }

  static formatUserListViewed(data: {
    ["search_criteria"]: string;
    country: string;
    users: IUser[];
  }): UserListViewed {
    return {
      country: data.country as Countries,
      screen_name: ScreenName.Users,
      information: this.parseInformationsAnalitycs(data.users),
      listed_items: data?.users?.length || 0,
      search_criteria: this.validateValueType(data.search_criteria),
    };
  }

  static formatUserDetailsViewed(
    user: IUserDetails,
    permissions: {
      hasUserBlockPermission: boolean;
      hasUserWritePermission: boolean;
    }
  ): UserDetailsViewed {
    return {
      "supported country":
        user?.supportedCountries.map((supportedCountry) => ({
          supported_countries: supportedCountry,
        })) || null,
      account_remove_permission: permissions.hasUserWritePermission,
      associated_accounts: user?.accounts.length || 0,
      block_permission: permissions.hasUserBlockPermission,
      country: user?.country as Countries,
      edit_permission: permissions.hasUserWritePermission,
      permissions: user?.groups?.map((group) => ({
        permissions_groups: group,
      })),
      screen_name: ScreenName.Users,
      user_email: user?.email || null,
      user_phone: user?.phone || null,
      user_status: user?.accountEnabled
        ? SegmentUserStatus.Active
        : SegmentUserStatus.Blocked,
      companies: user?.vendors?.map((vendorId: string) => ({
        company_ids: vendorId,
      })),
      delivery: user?.deliveryCenterIds?.map((deliveryCenterId: string) => ({
        delivery_center_id: deliveryCenterId,
      })),
      logistic: user?.logisticOperatorIds?.map(
        (logisticOperatorId: string) => ({
          logistic_operator_id: logisticOperatorId,
        })
      ),
      route_id: user?.routeId,
    };
  }

  static formatAccountRemovalSubmitted(
    user: IUserDetails,
    selectedAccounts: number
  ): AccountRemovalSubmitted {
    return {
      "supported country":
        user?.supportedCountries.map((supportedCountry) => ({
          supported_countries: supportedCountry,
        })) || null,
      account_remove_permission: false,
      associated_accounts: 1,
      block_permission: false,
      companies: user?.vendors?.map((vendor: string) => ({
        company_ids: vendor,
      })),
      country: "BR" as Countries,
      edit_permission: false,
      permissions: [],
      screen_name: ScreenName.UserDetails,
      user_email: user?.email || null,
      user_phone: user?.phone || null,
      user_id: user.id,
      user_status: user?.accountEnabled
        ? SegmentUserStatus.Active
        : SegmentUserStatus.Blocked,
      selected_accounts: selectedAccounts,
    };
  }

  static formatUserEditingSubmitted(
    data: FormatUserEditingSubmittedParams
  ): UserEditingSubmitted {
    return {
      account_remove_permission: data.permissions.hasUserWritePermission,
      edit_permission: data.permissions.hasUserWritePermission,
      block_permission: data.permissions.hasUserBlockPermission,
      "supported country": data.selectedSupportedCountries.map(
        (supportedCountry) => ({
          supported_countries: supportedCountry,
        })
      ),
      associated_accounts: data?.user?.accounts?.length || 0,
      country: data.country as Countries,
      screen_name: ScreenName.UserDetails,
      permissions: data.selectedPermissionGroups.map((group) => ({
        permissions_groups: group,
      })),
      user_email: data?.user?.email || null,
      user_phone: data?.user?.phone || null,
      user_status: data?.user?.accountEnabled
        ? SegmentUserStatus.Active
        : SegmentUserStatus.Blocked,
      user_id: data?.user?.id ?? null,
      logistic: data?.logisticIDs?.map((id) => ({ logistic_operator_id: id })),
      companies: data.companyIds.map((id) => ({ company_ids: id })),
      route_id: data.routeID,
      delivery: data.deliveryIDs.map((id) => ({ delivery_center_id: id })),
    };
  }

  static formatUserEditingSubmissionResult(
    userEditingSubmittedData: UserEditingSubmitted,
    errorMessages: string[]
  ): UserEditingSubmissionResult {
    const filterAmountErrorsOfEditUserInformation = (
      errorList: string[],
      filterValue: EnumFilterValue
    ) => {
      const errors = errorList.filter((error) => error.includes(filterValue));
      return errors.length;
    };
    return {
      ...userEditingSubmittedData,
      group_association_fail_count: filterAmountErrorsOfEditUserInformation(
        errorMessages,
        EnumFilterValue.PERMISSION_GROUP
      ),
      country_association_fail_count: filterAmountErrorsOfEditUserInformation(
        errorMessages,
        EnumFilterValue.SUPPORTED_COUNTRY
      ),
      company_association_fail_count: filterAmountErrorsOfEditUserInformation(
        errorMessages,
        EnumFilterValue.COMPANY_ID
      ),
    };
  }

  static formatAccountSelection(
    user: IUserDetails
  ):
    | AllAccountSelection
    | AllAccountDeselection
    | AccountSelection
    | AccountDeselection {
    return {
      "supported country":
        user?.supportedCountries.map((supportedCountry) => ({
          supported_countries: supportedCountry,
        })) || null,
      account_remove_permission: false,
      associated_accounts: 1,
      block_permission: false,
      companies: user?.vendors?.map((vendor: string) => ({
        company_ids: vendor,
      })),
      country: "BR" as Countries,
      edit_permission: false,
      permissions: [],
      screen_name: ScreenName.Users,
      user_email: user?.email || null,
      user_phone: user?.phone || null,
      user_id: user.id,
      user_status: user?.accountEnabled
        ? SegmentUserStatus.Active
        : SegmentUserStatus.Blocked,
    };
  }
}
