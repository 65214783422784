import { Select, SelectOption } from "@admin-portal-shared-components/select";
import React from "react";
import { SelectProps } from "./types";

export const SingleSelect = ({
  options = [],
  ...rest
}: SelectProps): JSX.Element => {
  return (
    <Select {...rest}>
      {options.map((option) => (
        <SelectOption key={option.label} value={option.value}>
          {option.label}
        </SelectOption>
      ))}
    </Select>
  );
};
