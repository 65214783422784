import React from "react";
import { UserDetailsContext } from "contexts/useUserDetails/userDetailsContext";
import { useGetUserDetails } from "hooks/useGetUserDetails/useGetUserDetails";
import { UserDetailsProviderProps } from "../types";

export const UserDetailsProvider = ({
  children,
}: UserDetailsProviderProps): JSX.Element => {
  const userDetailsInfo = useGetUserDetails();

  return (
    <UserDetailsContext.Provider value={userDetailsInfo}>
      {children}
    </UserDetailsContext.Provider>
  );
};
