import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    body {
        font-family: 'Work sans', sans-serif;
        font-weight: 400;
        background-color: #fafafa;

        .container_mfe {
            padding: 32px 32px 32px 114px;
            @media (min-width: 1440px) {
                margin: 0 auto;
            }
        }
    }

    h1, h2, h3, h4,h5,h6 {
        font-family: 'Barlow', sans-serif;
    }

    fieldset {
        border: none ;
    }

    /* width */
    ::-webkit-scrollbar {
    width: 10px;
    border-radius: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 8px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background: #555;
    }
    .admin-portal-identity-user-mfe-MuiTooltip-popper > div {
        margin-top: -22px;
        margin-left: 50px;
        padding: 10px;
        opacity: 1;
        border-radius: 0 8px 8px 8px;
        background-color: #0A0A0A;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.16);
        color: rgba(255,255,255,0.95);
        font-family: "Work Sans";
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 16px;
    }

    div[role=dialog]{
        max-height: 90%;
    }
`;
