import { getClusterByCountry } from "helpers/Cluster/cluster";

export interface IIsRestrictClusterParams {
  country?: string;
  appName?: string;
}

export const getApiHost = (
  params: IIsRestrictClusterParams = {}
): string | undefined => {
  const urlParams = new URLSearchParams(window.location.search);
  const country = params.country ? params.country : urlParams.get("country");
  const appName = params.appName ? params.appName : urlParams.get("appName");

  const isRestrictCluster =
    getClusterByCountry(String(country)) === "EU" && appName === "b2b";

  return isRestrictCluster ? "/api/eu/identity-bff" : "/api/identity-bff";
};

export const isHubDtc = (): boolean => {
  return location.host.includes("dtc");
};
