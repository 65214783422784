import {
  FormControlLabel,
  Radio,
  RadioGroup,
  SvgIcon,
} from "@material-ui/core";
import { getCountries } from "helpers";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import {
  sortCountryListAlphabeticallyByName,
  supportedCountries,
} from "country-utils";
import {
  StyledFilterSessionContainer,
  StyledFilterSessionHeader,
  StyledSessionOptionsContainer,
} from "./styles";

interface Props {
  onHandleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

export function CountryFilter({ onHandleChange, value }: Props): JSX.Element {
  const intl = useIntl();
  const [countries] = React.useState<string[]>(getCountries());

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onHandleChange(event);
  };

  const countriesListSorted = useMemo(
    () =>
      sortCountryListAlphabeticallyByName(
        countries.map((country) => ({
          name: supportedCountries[country as keyof typeof supportedCountries],
          code: country,
        }))
      ),
    [countries]
  );

  return (
    <StyledFilterSessionContainer>
      <StyledFilterSessionHeader>
        {intl.formatMessage({ id: "userPage_country" })}
      </StyledFilterSessionHeader>
      <StyledSessionOptionsContainer>
        <RadioGroup
          name="countries"
          value={value}
          onChange={handleChange}
          data-testid="select-country"
        >
          {countriesListSorted.map((country) => {
            return (
              <FormControlLabel
                value={country.code}
                control={
                  <Radio
                    style={{ color: "black", fontWeight: 50 }}
                    checkedIcon={
                      <SvgIcon>
                        <ellipse
                          strokeWidth="1.2px"
                          ry="10"
                          rx="10"
                          cy="11"
                          cx="11"
                          stroke="#000"
                          fill="#fff"
                        />
                        <ellipse
                          strokeWidth="1px"
                          ry="3.125"
                          rx="3.125"
                          cy="11"
                          cx="11"
                          stroke="#000"
                          fill="#000"
                        />
                      </SvgIcon>
                    }
                    icon={
                      <SvgIcon>
                        <ellipse
                          strokeWidth="1.2px"
                          ry="10"
                          rx="10"
                          cy="11"
                          cx="11"
                          stroke="#000"
                          fill="#fff"
                        />
                      </SvgIcon>
                    }
                    data-testid={`radio-${country.code}`}
                  />
                }
                label={country.name}
                key={country.code}
              />
            );
          })}
        </RadioGroup>
      </StyledSessionOptionsContainer>
    </StyledFilterSessionContainer>
  );
}
