import { FileType } from "identity-admin-mfe/modules/user-management/services/SegmentService";
import { IUserDetails } from "services/bff-service";

export interface FormatUserEditingSubmittedParams {
  user?: IUserDetails;
  country: string;
  selectedSupportedCountries: string[];
  selectedPermissionGroups: string[];
  logisticIDs: string[];
  deliveryIDs: string[];
  companyIds: string[];
  routeID: string;
  permissions: {
    hasUserWritePermission: boolean;
    hasUserBlockPermission: boolean;
  };
}

export enum EnumFilterValue {
  PERMISSION_GROUP = `Permission group`,
  SUPPORTED_COUNTRY = `Supported country`,
  COMPANY_ID = `Company Id`,
}

export interface IPermissionFormatUserBlocked {
  canDisassociteAccountsOfUser: boolean;
  hasPermissionToBlock: boolean;
  hasUserWritePermission: boolean;
}

export interface INewUploadSubmitted {
  fileTitle: string;
  fileDescription: string;
  rowsNumber: number;
  fileType: FileType;
  vendorId?: string;
}

export interface IUserData {
  country: string;
  userApplication: string;
  userId: string;
}
