import styled from "styled-components";

export const DotSpinnerContainer = styled.div`
  display: flex;
  gap: 4px;
  padding: 8px;
  @keyframes one {
    0% {
      background: #737373;
    }
    10% {
      background: #000;
    }
    50% {
      background: #737373;
    }
    100% {
      background: #737373;
    }
  }
  @keyframes two {
    0% {
      background: #737373;
    }
    50% {
      background: #000;
    }
    100% {
      background: #737373;
    }
  }
  @keyframes three {
    0% {
      background: #737373;
    }
    50% {
      background: #737373;
    }
    90% {
      background: #000;
    }
    100% {
      background: #737373;
    }
  }
  & div:nth-child(1) {
    animation: one 1s ease infinite;
  }
  & div:nth-child(2) {
    animation: two 1s ease infinite;
  }
  & div:nth-child(3) {
    animation: three 1s ease infinite;
  }
`;

export const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
`;
