import { IGetUserDetailsParams } from "identity-admin-contracts/user-management";
import { axiosClient } from "@shared/api";

export const postUserBlock = ({
  appName,
  country,
  userId,
}: IGetUserDetailsParams) =>
  axiosClient.post(
    `/user-management/v2/block/${userId}`,
    {},
    {
      params: {
        appName,
        country,
      },
    },
  );
