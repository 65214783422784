import {
  Paragraph,
  SegmentedControl,
  SegmentedControlOptions,
} from "@hexa-ui/components";
import {
  useEnvService,
  useGetEnvPropPerCluster,
} from "admin-portal-shared-services";
import {
  CopiedCriteria,
  TabName,
} from "identity-admin-mfe/modules/user-management/services/AnalyticService";
import DefaultEmptyStateFigure from "assets/bees-exclamation.svg";
import { EmptyState } from "components/EmptyState";
import { SegmentService } from "helpers";
import { formatDate } from "helpers/FormatDate/FormatDate";
import { Env } from "interfaces/IEnv";
import React, { useEffect, useState } from "react";
import { ApprovalFlowService } from "services/approvalFlow/ApprovalFlowService";
import {
  AprovalRequestStatusEnum,
  IApprovalFlowRequesters,
} from "services/approvalFlow/types";
import { getAuditLogs } from "services/auditLog/AuditLogService";
import { AuditLogsItem, AuditLogsItemTypes } from "../AuditLogsHistory/item";
import { OutputCopy } from "../OutputCopy/OutputCopy";
import { AuditLogsListFormat } from "./AuditLogsListFormat";
import { SkeletonAuditLogs } from "./SkeletonLoader";
import { Container, SubTitle } from "./styles";
import { AuditLogsProps, IAuditLogsItem } from "./types";

const getTabsByEnv = (isProd: boolean): SegmentedControlOptions[] => {
  const tabs: SegmentedControlOptions[] = [
    {
      label: "Completed",
      value: "completed",
    },
  ];
  if (isProd) {
    tabs.unshift({
      label: "Pending",
      value: "pending",
    });
  }
  return tabs;
};

export const AuditLogsList = ({
  userEditedId,
}: AuditLogsProps): JSX.Element => {
  const approvalRequesters = new ApprovalFlowService();
  const [auditLogs, setAuditLogs] = useState<IAuditLogsItem[] | undefined>([]);
  const [approvalPendingLogs, setApprovalPendingLogs] = useState<
    IApprovalFlowRequesters[]
  >([]);
  const [approvalCompletedLogs, setApprovalCompletedLogs] = useState<
    IApprovalFlowRequesters[]
  >([]);
  const isApprovalFlowEnabled = Boolean(
    useGetEnvPropPerCluster("approvalService")
  );

  const sortedAuditLogs = [...(auditLogs || [])].sort(
    (audit, approval) =>
      new Date(approval?.createdAt) - new Date(audit?.createdAt)
  );
  const sortedApprovalCompletedLogs = [...(approvalCompletedLogs || [])].sort(
    (audit, approval) =>
      new Date(approval?.updatedAt) - new Date(audit?.updatedAt)
  );

  const auditLogsWithOrigin = sortedAuditLogs.map((log) => ({
    ...log,
    origin: "auditLogs",
  }));
  const approvalCompletedLogsWithOrigin = sortedApprovalCompletedLogs.map(
    (log) => ({
      ...log,
      origin: "approvalCompletedLogs",
    })
  );

  const mergedAllLogs = [
    ...auditLogsWithOrigin,
    ...approvalCompletedLogsWithOrigin,
  ];

  const mergedLogs = mergedAllLogs.sort((audit, approval) => {
    const auditList = audit?.updatedAt || audit.createdAt;
    const approvalList = approval?.updatedAt || approval.createdAt;
    return new Date(approvalList) - new Date(auditList);
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tabSelected, setTabSelected] = useState<
    "completed" | "pending" | string
  >("completed");
  const envService = useEnvService();
  const isProdEnvironment =
    (envService.getEnv() === Env.PROD && isApprovalFlowEnabled) ||
    isApprovalFlowEnabled;
  const tabNameSelected = {
    completed: TabName.Completed,
    pending: TabName.Pending,
  };

  const handleGetApprovalRequesters = async () => {
    const requesters = await approvalRequesters
      .getRequesters(userEditedId as string)
      .then((res) => res?.data?.content);

    const approvalFlowPendingStatusList = requesters?.filter(
      (log: IApprovalFlowRequesters) =>
        log.status === AprovalRequestStatusEnum.PENDING ||
        log.status === AprovalRequestStatusEnum.PROCESSING
    );

    const approvalFlowCompletedStatusList = requesters?.filter(
      (log: IApprovalFlowRequesters) =>
        log.status === AprovalRequestStatusEnum.COMPLETED
    );

    setApprovalPendingLogs(approvalFlowPendingStatusList);
    setApprovalCompletedLogs(approvalFlowCompletedStatusList);
  };

  const handleGetAuditLogs = async () => {
    setIsLoading(true);
    const auditLogsData = await getAuditLogs({
      entity: "USERS",
      entityId: userEditedId,
    });
    if (auditLogsData !== undefined) {
      const logs = AuditLogsListFormat(auditLogsData);
      setAuditLogs(logs);
    } else {
      setAuditLogs(undefined);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (userEditedId) {
      handleGetAuditLogs();
    }

    if (userEditedId && isProdEnvironment) handleGetApprovalRequesters();
  }, [userEditedId, isProdEnvironment]);

  return (
    <Container>
      <SubTitle>
        <Paragraph size="basis">
          The audit log saves information for a period of 30 days.
        </Paragraph>
      </SubTitle>
      <SegmentedControl
        options={getTabsByEnv(isProdEnvironment)}
        defaultValue={tabSelected}
        onValueChange={(value) => {
          setTabSelected(value);
          const tabNameKey = value as keyof typeof tabNameSelected;
          SegmentService.recentActivitiesTabSelected(
            tabNameSelected[tabNameKey]
          );
        }}
        css={{ marginBottom: "1rem" }}
      />

      {tabSelected === "completed" && (
        <>
          {isLoading && <SkeletonAuditLogs />}
          {!isLoading && (
            <>
              {auditLogs?.length === 0 && !approvalCompletedLogs?.length && (
                <EmptyState
                  figure={DefaultEmptyStateFigure}
                  message="No recent activity information."
                />
              )}
              {auditLogs === undefined && (
                <EmptyState
                  figure={DefaultEmptyStateFigure}
                  message="Unable to retrieve recent activity information, please try again later."
                />
              )}

              <div>
                {mergedLogs.map(
                  (
                    log: (IAuditLogsItem & IApprovalFlowRequesters) | undefined
                  ) => {
                    return log?.origin === "auditLogs" ? (
                      <AuditLogsItem
                        key={log?.id}
                        type={log.operation}
                        header={log.fieldLabel}
                        body={log.data}
                        footer={
                          <>
                            <OutputCopy
                              color="#757575"
                              text={log.actionOwnerId}
                              label={`Requested by ${log.actionOwnerName} on ${log.creationDate}`}
                              criteria={CopiedCriteria.Id}
                            />
                            <OutputCopy
                              color="#757575"
                              text={log.actionOwnerId}
                              label={`Approved by System on ${log.creationDate}`}
                              criteria={CopiedCriteria.Id}
                            />
                          </>
                        }
                      />
                    ) : (
                      <AuditLogsItem
                        key={log?.id}
                        type={"INSERT" as AuditLogsItemTypes}
                        header={
                          log?.data?.new?.authorizationGroups &&
                          log?.data?.new?.authorizationGroups.added?.length > 0
                            ? `Added ${log?.data?.new?.authorizationGroups?.added?.length} group(s):`
                            : ""
                        }
                        body={log?.data?.new.authorizationGroups.added?.join(
                          ", "
                        )}
                        footer={
                          <>
                            <OutputCopy
                              color="#757575"
                              text={log?.user.id}
                              label={`Requested by ${
                                log?.data?.requester?.name
                              } on ${formatDate(log?.createdAt, "MMM dd, p")}`}
                              criteria={CopiedCriteria.Id}
                            />
                            <OutputCopy
                              color="#757575"
                              text={log?.user?.id}
                              label={`Approved by ${
                                log?.replier?.name
                              } on ${formatDate(log?.updatedAt, "MMM dd, p")}`}
                              criteria={CopiedCriteria.Id}
                            />
                          </>
                        }
                      />
                    );
                  }
                )}
              </div>
            </>
          )}
        </>
      )}

      {tabSelected === "pending" &&
        isProdEnvironment &&
        !approvalPendingLogs?.length && (
          <EmptyState
            figure={DefaultEmptyStateFigure}
            message="No recent activity information."
          />
        )}

      {tabSelected === "pending" && approvalPendingLogs?.length > 0 && (
        <>
          {isLoading && <SkeletonAuditLogs />}

          {!isLoading &&
            approvalPendingLogs.map((log) => (
              <AuditLogsItem
                key={log?.id}
                type={"INSERT" as AuditLogsItemTypes}
                header={
                  log.data?.new?.authorizationGroups &&
                  log.data?.new?.authorizationGroups?.added?.length > 0
                    ? `Added ${log?.data?.new?.authorizationGroups?.added?.length} group(s):`
                    : ""
                }
                body={log.data?.new.authorizationGroups.added?.join(", ")}
                footer={
                  <OutputCopy
                    color="#757575"
                    text={log?.user?.id}
                    label={`Requested by ${
                      log?.data?.requester?.name
                    } on ${formatDate(log?.createdAt, "MMM dd, p")}`}
                    criteria={CopiedCriteria.Id}
                  />
                }
              />
            ))}
        </>
      )}
    </Container>
  );
};
