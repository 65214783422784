import { Plus, Trash2 } from "@hexa-ui/icons";
import { TextButton, IconButton } from "@hexa-ui/components";
import { useIntl } from "react-intl";

import {
  AllSupportedCountryKeys,
  allSupportedCountries,
  supportedCountries as countries,
  sortCountryListAlphabetically,
} from "country-utils";
import {
  AuthorizationGroup,
  AuthorizationGroupService,
  PostGroup,
} from "services/authorizationGroup/AuthorizationGroupService";
import { Chip, TextField } from "@material-ui/core";
import {
  IApprovalFlowRequestsParams,
  NewData,
} from "services/approvalFlow/types";
import React, {
  ChangeEvent,
  MouseEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import { SanitizeText, SegmentService } from "helpers";
import { TOAST_SEVERITY, useToast } from "components/Toast";
import {
  getUserEditableData,
  getIsUserRegistered,
  putUserData,
} from "identity-admin-mfe/modules/user-management/api/functions";
import {
  useEnvService,
  useGetEnvPropPerCluster,
  useHasPermission,
} from "admin-portal-shared-services";
import {
  useIsGlobalManager,
  useLoggedUser,
  useUserGroupsList,
  useUserSupportedCountries,
} from "hooks/useIsGlobalManager/useIsGlobalManager";

import { Alert } from "@material-ui/lab";
import { ApprovalFlowService } from "services/approvalFlow/ApprovalFlowService";
import { ChipsField } from "components/ChipsField";
import { Env } from "interfaces/IEnv";
import { IUserDetails } from "services/bff-service";
import { Input } from "components/Input";
import { SegmentFormatData } from "helpers/segmentService/segmentFormatData";
import { SingleSelect } from "components/SingleSelect";
import { SnackBar } from "components/Snackbar/Snackbar";
import { UserService } from "services/user/UserService";
import { X } from "@admin-portal-shared-components/icons";
import { postAuditLogEditUser } from "services/auditLog/AuditLogService";
import { sortByName } from "helpers/Sort/Sorts";
import { isValidEmail } from "helpers/emailValidations/emailValidations";
import { separateGroupsByApprovers } from "./approval-flow/separateGroupsByApprovers";

import { userEditMetadataFormat } from "./auditLogUserMetadataFormat";
import {
  AlertContainer,
  Autocomplete,
  DialogEditUser,
  FormContainer,
  FormControlField,
  Loading,
  LoadingBackground,
  ModalButton,
  ModalButtonClose,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "./styles";

interface IModalEditUser {
  country: string;
  user: IUserDetails | undefined;
  openModal: boolean;
  handleButtonCloseModal: () => void;
  handleEditUser(event: MouseEvent<HTMLButtonElement>): void;
  handleCancel(event: MouseEvent<HTMLButtonElement>): void;
}

enum EnumAttributeField {
  BDR_ID = "BDR_ID",
  DELIVERY_CENTER_IDS = "DELIVERY_CENTER_ID",
  LOGISTIC_OPERATOR_IDS = "LOGISTIC_OPERATOR_ID",
  ROUTE = "ROUTE",
}

const attributeFieldOptions = [
  { label: "BDR ID", value: EnumAttributeField.BDR_ID },
  {
    label: "Delivery Center ID",
    value: EnumAttributeField.DELIVERY_CENTER_IDS,
  },
  {
    label: "Logistic Operator ID",
    value: EnumAttributeField.LOGISTIC_OPERATOR_IDS,
  },
  { label: "Route ID", value: EnumAttributeField.ROUTE },
];

const messageTypeErrorEditUser = (messageAttribute: string) => ({
  ADD: {
    PERMISSION_GROUP: `Permission group ${messageAttribute} couldn’t be added`,
    SUPPORTED_COUNTRY: `Supported country ${messageAttribute} couldn’t be added`,
    COMPANY_ID: `Company Id ${messageAttribute} couldn’t be added`,
    DELIVERY_CENTER_ID: `Delivery center Id ${messageAttribute} couldn’t be added`,
    LOGISTIC_OPERATOR_ID: `Logistic operator Id ${messageAttribute} couldn’t be added`,
    ROUTE: `Route Id ${messageAttribute} couldn’t be added`,
    BDR_ID: `BDR Id ${messageAttribute} couldn’t be added`,
  },
  REMOVE: {
    PERMISSION_GROUP: `Permission group ${messageAttribute} couldn’t be removed`,
    SUPPORTED_COUNTRY: `Supported country ${messageAttribute} couldn’t be removed`,
    COMPANY_ID: `Company Id ${messageAttribute} couldn’t be removed`,
    ROUTE_ID: `Route Id ${messageAttribute} couldn’t be removed`,
    DELIVERY_CENTER_ID: `Delivery center Id ${messageAttribute} couldn’t be removed`,
    LOGISTIC_OPERATOR_ID: `Logistic operator Id ${messageAttribute} couldn’t be removed`,
    ROUTE: `Route Id ${messageAttribute} couldn’t be removed`,
    BDR_ID: `BDR Id ${messageAttribute} couldn’t be removed`,
  },
});

const authorizationGroupService = new AuthorizationGroupService();
const userService = new UserService();
const approvalFlowService = new ApprovalFlowService();

type IErrorValidationFields = {
  manager: string;
  supervisors: { [x: string]: string };
};

export function ModalEditUser({
  user,
  country,
  openModal,
  handleButtonCloseModal,
  handleEditUser,
  handleCancel,
}: IModalEditUser): JSX.Element | null {
  const { formatMessage } = useIntl();
  const isApprovalFlowEnabled = Boolean(
    useGetEnvPropPerCluster("approvalService")
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [authorizationGroups, setAuthorizationGroups] = useState<
    AuthorizationGroup[]
  >([]);
  const [supportedCountries, setSupportedCountries] = useState<string[]>([]);
  const [selectedPermissionGroups, setSelectedPermissionGroups] = useState<
    string[]
  >([]);
  const [managedBy, setManagedBy] = useState<string | null>(null);
  const [actualManager, setActualManager] = useState<string | null>(null);
  const [supervisorsValues, setSupervisorsValues] = useState<
    { id: string; email: string }[]
  >([]);
  const [errorValidationFields, setErrorValidationFields] =
    useState<IErrorValidationFields>({ manager: "", supervisors: {} });

  const [oldSelectedGroups, setOldSelectedGroups] = useState<string[]>([]);
  const [selectedSupportedCountries, setSelectedSupportedCountries] = useState<
    string[]
  >([]);
  const [oldSelectedSupportedCountries, setOldSelectedSupportedCountries] =
    useState<string[]>([]);
  const [companyIds, setCompanyIds] = useState<string[]>([]);
  const [oldSelectedCompanyIds, setOldSelectedCompanyIds] = useState<string[]>(
    []
  );
  const [hasChange, setHasChange] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [requestCounts, setRequestCounts] = useState<number>(0);
  const [openAlertCloseModal, setOpenAlertCloseModal] =
    useState<boolean>(false);
  const isGlobalManager = useIsGlobalManager();
  const loggedUser = useLoggedUser();
  const groupsList = useUserGroupsList();
  const supportedCountriesList = useUserSupportedCountries();
  const hasUserBlockPermission = useHasPermission("IdentityFE.User.Block");
  const hasUserWritePermission = useHasPermission([
    "IdentityFE.User.Write",
    "Identity.User.Write",
  ]);
  const [selectedAttribute, setSelectedAttribute] = useState<string>("");
  const [isAttributesFieldVisible, setAttributesFieldVisible] = useState<
    string[]
  >([]);
  const [bdrId, setBdrId] = useState<string>("");
  const [oldBdrID, setOldBdrID] = useState<string>("");
  const [logisticIDs, setLogisticIDs] = useState<string[]>([]);
  const [oldLogisticIDs, setOldLogisticIDs] = useState<string[]>([]);
  const [deliveryIDs, setDeliveryIDs] = useState<string[]>([]);
  const [oldDeliveryIDs, setOldDeliveryIDs] = useState<string[]>([]);
  const [routeID, setRouteID] = useState<string>("");
  const [oldRouteID, setOldRouteID] = useState<string>("");
  const envService = useEnvService();
  const { setToastOptions } = useToast();
  const targetUserSupportedCountries = useMemo(
    () => user?.supportedCountries || [],
    [user?.supportedCountries]
  );
  const hasHierarchyErrors =
    [
      errorValidationFields.manager,
      ...Object.values(errorValidationFields.supervisors),
    ].filter(Boolean).length > 0;

  const changeCompanyIds = (ids: string[]) => {
    const sanitizedCompanyIds = ids.map((id: string) => SanitizeText(id));
    setCompanyIds(sanitizedCompanyIds);
  };

  const handleChangeFormState = <T extends unknown>(
    value: T,
    callback: (v: T) => void
  ) => {
    callback(value);
    setHasChange(true);
  };

  const handleSelectedAttribute = (value: string) => {
    setSelectedAttribute(value);

    if (!isAttributesFieldVisible.includes(value))
      setAttributesFieldVisible([...isAttributesFieldVisible, value]);
  };

  const handleFilterAuthorizationGroupsValues = () =>
    authorizationGroups.map((group) => {
      return group.app ? `${group.app}.${group.name}` : group.name;
    });

  const isAuthorizationOptionDisabled = (option: unknown) => {
    const [groupName, app] = (option as string).split(".").reverse();
    const isAppOwner = groupsList?.includes(`${app}.Owners`);
    const isAppAuthManager = groupsList?.includes(`${app}.Auth Managers`);
    const isDefaultGroup =
      groupName === "Auth Managers" ||
      groupName === "Auth Viewers" ||
      groupName === "Auth Approvers" ||
      groupName === "Owners";

    if (isGlobalManager) return false;

    if (isDefaultGroup) return !isAppOwner;

    return !isAppAuthManager;
  };

  const handleDisabledSupportedCountriesValues = (option: unknown) => {
    return (
      !isGlobalManager && !supportedCountriesList?.includes(option as string)
    );
  };

  const setSelectedValues = () => {
    if (!user) return;

    const {
      groups = [],
      supportedCountries: initialCountries = [],
      vendors = [],
      routeId = "",
      bdrId: customBdrId = "",
      deliveryCenterIds = [],
      logisticOperatorIds = [],
    } = user;

    setSelectedPermissionGroups(groups);
    setOldSelectedGroups(groups);

    setSelectedSupportedCountries(initialCountries);
    setOldSelectedSupportedCountries(initialCountries);

    setCompanyIds(vendors);
    setOldSelectedCompanyIds(vendors);

    setBdrId(customBdrId);
    setOldBdrID(customBdrId);

    setRouteID(routeId);
    setOldRouteID(routeId);

    setDeliveryIDs(deliveryCenterIds);
    setOldDeliveryIDs(deliveryCenterIds);

    setLogisticIDs(logisticOperatorIds);
    setOldLogisticIDs(logisticOperatorIds);

    setAttributesFieldVisible([
      ...(deliveryCenterIds?.length
        ? [EnumAttributeField.DELIVERY_CENTER_IDS]
        : []),
      ...(logisticOperatorIds?.length
        ? [EnumAttributeField.LOGISTIC_OPERATOR_IDS]
        : []),
      ...(routeId ? [EnumAttributeField.ROUTE] : []),
      ...(bdrId ? [EnumAttributeField.BDR_ID] : []),
    ]);
  };

  const postAttributeFields = async () => {
    if (!user) return [];

    const isNewRouteIDValid = routeID && routeID !== oldRouteID;

    const isNewBdrIdValid = bdrId && bdrId !== oldBdrID;

    const logisticIDsToBeAdded = logisticIDs.filter(
      (logisticID) => !oldLogisticIDs.includes(logisticID)
    );

    const deliveryIDsToBeAdded = deliveryIDs.filter(
      (deliveryID) => !oldDeliveryIDs.includes(deliveryID)
    );

    const formattedRouteID = {
      type: EnumAttributeField.ROUTE,
      userId: user.id,
      value: routeID,
    };

    const formattedBdrID = {
      type: EnumAttributeField.BDR_ID,
      userId: user.id,
      value: bdrId,
    };

    const feedback: string[] = [];
    const DTO = [
      ...logisticIDsToBeAdded.map((logisticID) => ({
        type: EnumAttributeField.LOGISTIC_OPERATOR_IDS,
        userId: user.id,
        value: logisticID,
      })),
      ...deliveryIDsToBeAdded.map((deliveryID) => ({
        type: EnumAttributeField.DELIVERY_CENTER_IDS,
        userId: user.id,
        value: deliveryID,
      })),
    ];

    if (!routeID && !bdrId && !DTO.length) return [];

    const wasDTOupdated = await userService.postMetadataSeveralValues(
      country,
      DTO
    );
    if (!wasDTOupdated) {
      feedback.push(
        ...deliveryIDsToBeAdded.map(
          (id) => messageTypeErrorEditUser(id).ADD.DELIVERY_CENTER_ID
        ),
        ...logisticIDsToBeAdded.map(
          (id) => messageTypeErrorEditUser(id).ADD.LOGISTIC_OPERATOR_ID
        )
      );
    } else {
      DTO.forEach(() => feedback.push(""));
    }

    if (isNewRouteIDValid) {
      const wasRouteIDupdated = oldRouteID
        ? await userService.updateMetadataEntry(country, formattedRouteID)
        : await userService.postMetadataSeveralValues(country, [
            formattedRouteID,
          ]);

      if (!wasRouteIDupdated) {
        feedback.push(messageTypeErrorEditUser(routeID).ADD.ROUTE);
      } else {
        feedback.push("");
      }
    }

    if (isNewBdrIdValid) {
      const feedbackMessage = await handleCreateOrUpdateBdrId(formattedBdrID, {
        type: EnumAttributeField.BDR_ID,
        userId: user.id,
        value: oldBdrID,
      });

      feedback.push(feedbackMessage);
    }

    return feedback;
  };

  const handleCreateOrUpdateBdrId = async (
    formattedBdrID: {
      type: string;
      userId: string;
      value: string;
    },
    formattedOldBdrID?: {
      type: string;
      userId: string;
      value: string;
    }
  ): Promise<string> => {
    if (formattedOldBdrID?.value) {
      const wasDeleteSuccessful = await userService.deleteMetadataSeveralValues(
        country,
        [formattedOldBdrID]
      );

      if (!wasDeleteSuccessful)
        return messageTypeErrorEditUser(bdrId).ADD.BDR_ID;
    }

    const wasBdrIdDupdated = await userService.postMetadataSeveralValues(
      country,
      [formattedBdrID]
    );

    return wasBdrIdDupdated ? "" : messageTypeErrorEditUser(bdrId).ADD.BDR_ID;
  };

  const handleEditManagerAndSupervisors = async (): Promise<string[]> => {
    const supervisorEmails: string[] = supervisorsValues.reduce(
      (acc: string[], supervisor: { email: string; id: string }) => {
        if (supervisor.email !== "") {
          acc.push(supervisor.email);
        }
        return acc;
      },
      []
    );

    return putUserData({
      userId: user?.id as string,
      userApplication: user?.application,
      userCountry: user?.country,
      managedBy: managedBy && managedBy.length > 0 ? managedBy : null,
      supervisors: supervisorEmails,
    }).then(
      () => [],
      (error) => {
        return `The follow error ocurred on supervisors/manager field: ${
          error.response?.data.message ??
          error.response?.data.response.userMessage
        }`;
      }
    );
  };

  const deleteAttributeFields = async () => {
    if (!user) return [];

    const additionalDataToBeRemoved = [];
    const additionalErrorMessages = [];
    const wasRouteIdDeletedByUser = !routeID && oldRouteID;
    const wasBdrIdDeletedByUser = !bdrId && oldBdrID;

    const logisticIDsToBeDeleted = oldLogisticIDs.filter(
      (logisticID) => !logisticIDs.includes(logisticID)
    );

    const deliveryIDsToBeDeleted = oldDeliveryIDs.filter(
      (deliveryID) => !deliveryIDs.includes(deliveryID)
    );

    const formattedRouteID = {
      type: EnumAttributeField.ROUTE,
      userId: user.id,
      value: routeID,
    };

    const formattedBdrID = {
      type: EnumAttributeField.BDR_ID,
      userId: user.id,
      value: bdrId,
    };

    const DTO = [
      ...logisticIDsToBeDeleted.map((logisticID) => ({
        type: EnumAttributeField.LOGISTIC_OPERATOR_IDS,
        userId: user.id,
        value: logisticID,
      })),
      ...deliveryIDsToBeDeleted.map((deliveryID) => ({
        type: EnumAttributeField.DELIVERY_CENTER_IDS,
        userId: user.id,
        value: deliveryID,
      })),
    ];

    if (wasRouteIdDeletedByUser) {
      additionalDataToBeRemoved.push(formattedRouteID);
      additionalErrorMessages.push(
        messageTypeErrorEditUser(oldRouteID).REMOVE.ROUTE
      );
    }
    if (wasBdrIdDeletedByUser) {
      additionalDataToBeRemoved.push(formattedBdrID);
      additionalErrorMessages.push(
        messageTypeErrorEditUser(oldBdrID).REMOVE.BDR_ID
      );
    }

    const wasDataRemoved = await userService.deleteMetadataSeveralValues(
      country,
      [...DTO, ...additionalDataToBeRemoved]
    );

    return wasDataRemoved
      ? Array.from(
          { length: DTO.length + additionalDataToBeRemoved.length },
          () => ""
        )
      : [
          ...deliveryIDsToBeDeleted.map(
            (id) => messageTypeErrorEditUser(id).REMOVE.DELIVERY_CENTER_ID
          ),
          ...logisticIDsToBeDeleted.map(
            (id) => messageTypeErrorEditUser(id).REMOVE.LOGISTIC_OPERATOR_ID
          ),
          ...additionalErrorMessages,
        ];
  };

  const saveAuthorizationGroups = async (groupName: string) => {
    const appName = groupName.trim().split(".").reverse();
    const authGroup: PostGroup = {
      app: appName[1],
      group: appName[0],
      userId: user?.id,
    };
    const isSaveSuccess =
      await authorizationGroupService.postAuthorizationGroups(
        authGroup,
        country
      );
    if (isSaveSuccess) {
      return "";
    }

    return messageTypeErrorEditUser(`${String(groupName).trim()}`).ADD
      .PERMISSION_GROUP;
  };

  const removeAuthorizationGroups = async (groupName: string) => {
    const appName = groupName.trim().split(".").reverse();
    const autGroup: PostGroup = {
      app: appName[1],
      group: appName[0],
      userId: user?.id,
    };
    const isDeletedSuccess =
      await authorizationGroupService.deleteAuthorizationGroups(
        autGroup,
        country
      );
    if (isDeletedSuccess) {
      return "";
    }

    return messageTypeErrorEditUser(`${String(groupName).trim()}`).REMOVE
      .PERMISSION_GROUP;
  };

  const saveSupportedCountry = async (supportedCountry: string) => {
    const isSavedSuccess = await userService.postMetadataByType(
      "SUPPORTED_COUNTRY",
      user?.id,
      country,
      supportedCountry
    );
    if (isSavedSuccess) {
      return "";
    }
    return messageTypeErrorEditUser(
      `${allSupportedCountries[supportedCountry as AllSupportedCountryKeys]}`
    ).ADD.SUPPORTED_COUNTRY;
  };

  const removeSupportedCountry = async (supportedCountry: string) => {
    const isDeletedSuccess = await userService.deleteMetadataByType(
      "SUPPORTED_COUNTRY",
      user?.id,
      country,
      supportedCountry
    );
    if (isDeletedSuccess) {
      return "";
    }
    return messageTypeErrorEditUser(
      `${allSupportedCountries[supportedCountry as AllSupportedCountryKeys]}`
    ).REMOVE.SUPPORTED_COUNTRY;
  };

  const saveCompanyIds = async (companyId: string) => {
    const isSavedSuccess = await userService.postMetadataByType(
      "VENDOR",
      user?.id,
      country,
      companyId
    );
    if (isSavedSuccess) {
      return "";
    }
    return messageTypeErrorEditUser(`${companyId}`).ADD.COMPANY_ID;
  };

  const removeCompanyIds = async (companyId: string) => {
    const isDeletedSuccess = await userService.deleteMetadataByType(
      "VENDOR",
      user?.id,
      country,
      companyId
    );

    if (isDeletedSuccess) {
      return "";
    }
    return messageTypeErrorEditUser(`${companyId}`).REMOVE.COMPANY_ID;
  };

  const handleCloseModal = () => {
    if (!hasChange) {
      handleButtonCloseModal();
    } else {
      setOpenAlertCloseModal(true);
    }
  };

  const createApprovalFlowRequests = async (newItems: NewData) => {
    const payload: IApprovalFlowRequestsParams = {
      entity: "USER",
      country,
      title: `Change user - ${
        user?.displayName.split(" ")[0] ?? user?.displayName
      } | ${user?.email} | ${user!.id}`,
      data: {
        target: {
          id: user!.id,
          country: user!.country,
          appName: user!.application,
        },
        new: newItems,
        current: {
          ...(bdrId && { bdrId }),
          ...(routeID && { routeId: routeID }),
          isBlock: !user?.accountEnabled,
        },
      },
    };

    try {
      return await approvalFlowService.createRequesters(payload);
    } catch (error) {
      return new Error(`No was possible to create the requests: ${error}`);
    }
  };

  const canEditMyOwnUser = () => {
    const targetUserId = user?.id;
    const loggedUserId = loggedUser?.userId;
    const isMyUser = targetUserId === loggedUserId;

    return isGlobalManager && isMyUser;
  };

  const saveEditUser = async (event: MouseEvent<HTMLButtonElement>) => {
    if (hasHierarchyErrors) return;

    const analyticsDataEditSubmit =
      SegmentFormatData.formatUserEditingSubmitted({
        companyIds,
        country,
        deliveryIDs,
        logisticIDs,
        routeID,
        permissions: {
          hasUserBlockPermission,
          hasUserWritePermission,
        },
        selectedPermissionGroups,
        selectedSupportedCountries,
        user,
      });

    SegmentService.userEditingSubmitted(analyticsDataEditSubmit);
    setIsLoading(true);

    const removeGroups: string[] = oldSelectedGroups.filter(
      (oldGroup: string) => selectedPermissionGroups.indexOf(oldGroup) === -1
    );
    const newGroups: string[] = selectedPermissionGroups.filter(
      (group) => oldSelectedGroups.indexOf(group) === -1
    );

    const removeSupportedCountries: string[] =
      oldSelectedSupportedCountries.filter(
        (oldSuportedCountry: string) =>
          selectedSupportedCountries.indexOf(oldSuportedCountry) === -1
      );
    const newSupportedCountries: string[] = selectedSupportedCountries.filter(
      (supportedCountry) =>
        oldSelectedSupportedCountries.indexOf(supportedCountry) === -1
    );

    const removeCompanyIdItems: string[] = oldSelectedCompanyIds.filter(
      (oldSuportedCountries: string) =>
        companyIds.indexOf(oldSuportedCountries) === -1
    );
    const newCompanyIds: string[] = companyIds.filter(
      (companyIdItem) => oldSelectedCompanyIds.indexOf(companyIdItem) === -1
    );

    const { skipApprovalFlowGroupList, approvalFlowGroupList } =
      separateGroupsByApprovers({
        userGroupsLogged: groupsList || [],
        addedGroups: newGroups,
        isApprovalFlowEnabled,
        callback: canEditMyOwnUser,
      });

    if (
      isApprovalFlowEnabled &&
      approvalFlowGroupList.length > 0 &&
      !canEditMyOwnUser()
    )
      createApprovalFlowRequests({
        authorizationGroups: {
          added: [...approvalFlowGroupList],
        },
      });

    const requests = [
      handleEditManagerAndSupervisors(),
      ...skipApprovalFlowGroupList.map(async (group) =>
        saveAuthorizationGroups(group)
      ),
      ...removeGroups.map((group) => removeAuthorizationGroups(group)),
      ...newSupportedCountries.map(async (supportedCountry) =>
        saveSupportedCountry(supportedCountry)
      ),
      ...removeSupportedCountries.map(async (supportedCountry) =>
        removeSupportedCountry(supportedCountry)
      ),
      ...newCompanyIds.map(async (companyIdItem) =>
        saveCompanyIds(companyIdItem)
      ),
      ...removeCompanyIdItems.map((companyIdItem) =>
        removeCompanyIds(companyIdItem)
      ),
      ...(await postAttributeFields()),
      ...(await deleteAttributeFields()),
    ];

    setRequestCounts(requests.length);

    Promise.all(requests)
      .then((messages: string[]) => {
        setHasChange(false);
        const errorsMsg: string[] = messages.filter((msg) => msg.length > 0);
        if (errorsMsg.length === 0) {
          handleEditUser(event);
        } else {
          setErrorMessages(errorsMsg);
        }
        setIsLoading(false);

        const analyticsDataEditSubmitResult =
          SegmentFormatData.formatUserEditingSubmissionResult(
            analyticsDataEditSubmit,
            errorsMsg
          );
        SegmentService.userEditingSubmissionResult(
          analyticsDataEditSubmitResult
        );
        const userEditMetadata = userEditMetadataFormat({
          wasApprovedBySystem: envService.getEnv() !== Env.PROD,
          newGroups: skipApprovalFlowGroupList,
          removedGroups: removeGroups,
          newSupportedCountries,
          removedSupportedCountries: removeSupportedCountries,
          newCompanyIds,
          removedCompanyIds: removeCompanyIdItems,
          bdrId,
          oldBdrId: oldBdrID,
          deliveryIds: deliveryIDs,
          oldDeliveryIds: oldDeliveryIDs,
          routeId: routeID,
          oldRouteId: oldRouteID,
          logisticIds: logisticIDs,
          oldLogisticIds: oldLogisticIDs,
          errorMessages: errorsMsg,
        });

        postAuditLogEditUser(country, userEditMetadata, user?.id);
      })
      .catch((error) => {
        setErrorMessages([error.message]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    setSelectedValues();

    getUserEditableData({ userId: user?.id as string })
      .then((response) => {
        if (response.data.manager) {
          setActualManager(response.data.manager.email);
        } else {
          setActualManager("");
        }

        if (response.data.supervisors?.length > 0) {
          setSupervisorsValues(response.data.supervisors);
        } else {
          setSupervisorsValues([{ id: window.crypto.randomUUID(), email: "" }]);
        }
      })
      .catch(() => {
        setActualManager("");
        setSupervisorsValues([{ id: window.crypto.randomUUID(), email: "" }]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const setMergedSuppCountriesList = () => {
      const userSuppCountries: string[] = supportedCountriesList || [];
      const mergedSuppCountries = sortCountryListAlphabetically([
        ...new Set([...userSuppCountries, ...targetUserSupportedCountries]),
      ]);
      setSupportedCountries(mergedSuppCountries);
    };

    const fetchAuthorizationGroups = async () => {
      setIsLoading(true);
      const { data } = await authorizationGroupService.getAuthorizationGroups();

      if (!data.length) {
        setToastOptions({
          type: TOAST_SEVERITY.ERROR,
          position: "top",
          showCloseButton: true,
          duration: 8000,
          message: "Error loading groups",
        });
      }

      setAuthorizationGroups(data.sort(sortByName));

      setIsLoading(false);
    };

    fetchAuthorizationGroups();

    if (isGlobalManager) {
      setSupportedCountries(
        Object.keys(
          user?.application === "b2b" ? countries : allSupportedCountries
        )
      );
    } else {
      setMergedSuppCountriesList();
    }
  }, [
    isGlobalManager,
    setToastOptions,
    supportedCountriesList,
    user?.application,
    user?.id,
    targetUserSupportedCountries,
  ]);

  const isValidEmails = async (email: string) => {
    if (!isValidEmail(email)) {
      return formatMessage({
        id: "editUserModal_invalidEmailError",
      });
    }

    setIsLoading(true);
    const isUserAdminRegistered = await getIsUserRegistered({
      userEmail: email,
      adminOnly: true,
    });
    setIsLoading(false);

    if (!isUserAdminRegistered.data.exists) {
      return formatMessage({
        id: "editUserModal_nonExistentEmailError",
      });
    }
    return "";
  };

  const validateSupervisorFields = async (email: string) => {
    if (!email) return "";

    const supervisorIsEqualPreviousManager =
      email.toLowerCase() === actualManager?.toLowerCase();
    const supervisorIsEqualNewManager =
      email.toLowerCase() === managedBy?.toLowerCase();
    const newManagerWasInformed = Boolean(managedBy);
    if (
      newManagerWasInformed
        ? supervisorIsEqualNewManager
        : supervisorIsEqualPreviousManager
    ) {
      return formatMessage({
        id: "editUserModal_managerEqualSupervisorError",
      });
    }
    return isValidEmails(email);
  };

  const validateManagerField = async (email: string) => {
    if (!email && !managerFieldIsRequired) return "";

    const hasPreviousManagerDefined =
      Boolean(actualManager) && managedBy !== actualManager;

    const IsManagerFieldEmpty = managedBy !== null && !managedBy.length;

    if (hasPreviousManagerDefined && IsManagerFieldEmpty) {
      return formatMessage({ id: "editUSerModal_fieldIsRequiredError" });
    }
    return isValidEmails(email);
  };

  const isAddSupervisorsButtonDisabled = useMemo(
    () =>
      supervisorsValues.some((supervisor) => supervisor.email === "") ||
      Object.values(errorValidationFields.supervisors).filter(Boolean)?.length >
        0,
    [errorValidationFields.supervisors, supervisorsValues]
  );

  const managerFieldIsRequired = Boolean(actualManager) && actualManager !== "";

  return (
    <DialogEditUser
      open={openModal}
      onClose={handleCloseModal}
      data-testid="edit modal"
      className="modal-edit-user"
      maxWidth="lg"
    >
      <ModalHeader>
        <ModalTitle>{formatMessage({ id: "editUserModal_title" })}</ModalTitle>
        <ModalButtonClose onClick={handleCloseModal} data-testid="close-modal">
          <X size="medium" />
        </ModalButtonClose>
      </ModalHeader>
      <FormContainer>
        <FormControlField width="100%">
          {actualManager !== null && (
            <Input
              id="managed_by"
              label={`${formatMessage({
                id: "editUserModal_managerFieldLabel",
              })} (${formatMessage({
                id: managerFieldIsRequired
                  ? "editUserModal_requiredField"
                  : "editUserModal_optionalField",
              })})`}
              placeholder={formatMessage({
                id: "editUserModal_managerFieldPlaceholder",
              })}
              width="100%"
              defaultValue={actualManager}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const newEmail = e.target.value;
                const wasEmailErased =
                  newEmail === "" ||
                  newEmail === null ||
                  newEmail === undefined;

                if (wasEmailErased && !managerFieldIsRequired) {
                  setErrorValidationFields((errors) => ({
                    ...errors,
                    manager: "",
                  }));
                }
                if (newEmail !== actualManager) {
                  setHasChange(true);
                }
                return setManagedBy(newEmail);
              }}
              onBlur={async () => {
                const errorField =
                  managedBy || (managedBy === "" && managerFieldIsRequired)
                    ? await validateManagerField(managedBy)
                    : undefined;

                if (errorField !== undefined) {
                  setErrorValidationFields((previousValues) => ({
                    ...previousValues,
                    manager: errorField,
                  }));
                }
              }}
              required={managerFieldIsRequired}
              errorText={errorValidationFields?.manager}
              hasError={Boolean(errorValidationFields?.manager)}
            />
          )}

          {supervisorsValues.map((supervisor, index) => (
            <div
              key={supervisor.id}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <Input
                id="supervisor"
                label={
                  index === 0
                    ? formatMessage({
                        id: "editUserModal_supervisorFieldLabel",
                      })
                    : undefined
                }
                placeholder={formatMessage({
                  id: "editUserModal_supervisorFieldPlaceholder",
                })}
                width="100%"
                defaultValue={supervisor.email}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const newEmail = e.target.value;
                  setSupervisorsValues((previousSupervisors) =>
                    previousSupervisors.map((previousSupervisor) => {
                      const wasEmailErased =
                        newEmail === "" ||
                        newEmail === null ||
                        newEmail === undefined;

                      if (wasEmailErased) {
                        setErrorValidationFields((previousErrors) => {
                          return {
                            ...previousErrors,
                            supervisors: {
                              ...previousErrors.supervisors,
                              [supervisor.id]: "",
                            },
                          };
                        });
                      }
                      return previousSupervisor.id === supervisor.id
                        ? { ...previousSupervisor, email: newEmail }
                        : previousSupervisor;
                    })
                  );
                  setHasChange(true);
                }}
                onBlur={async () => {
                  const errorField = await validateSupervisorFields(
                    supervisor.email
                  );

                  if (errorField !== undefined) {
                    setErrorValidationFields((oldValues) => ({
                      ...oldValues,
                      supervisors: {
                        ...oldValues.supervisors,
                        [supervisor.id]: errorField,
                      },
                    }));
                  }
                }}
                required={false}
                hasError={Boolean(
                  errorValidationFields?.supervisors[supervisor.id]
                )}
                errorText={errorValidationFields?.supervisors[supervisor.id]}
              />
              {index !== 0 && (
                <IconButton
                  icon={Trash2}
                  style={{
                    marginTop: errorValidationFields.supervisors[supervisor.id]
                      ? "1.25rem"
                      : undefined,
                  }}
                  onClick={() => {
                    setSupervisorsValues((previousSupervisors) => {
                      const newSupervisors = previousSupervisors.filter(
                        (_, i) => i !== index
                      );
                      const quantityNewSupervisors = newSupervisors.filter(
                        (supervisorValue) => Boolean(supervisorValue.email)
                      )?.length;
                      if (quantityNewSupervisors < previousSupervisors.length) {
                        setHasChange(true);
                      }
                      return newSupervisors;
                    });
                    setErrorValidationFields((previousErrors) => {
                      return {
                        ...previousErrors,
                        supervisors: {
                          ...previousErrors.supervisors,
                          [supervisor.id]: "",
                        },
                      };
                    });
                  }}
                  variant="inherit"
                />
              )}
            </div>
          ))}

          <TextButton
            icon={Plus}
            iconPosition="leading"
            css={{
              color: "#0F7FFA",
              marginRight: "auto",
            }}
            disabled={isAddSupervisorsButtonDisabled}
            onClick={() => {
              setSupervisorsValues((previousValues) => [
                ...previousValues,
                { id: window.crypto.randomUUID(), email: "" },
              ]);
            }}
          >
            {formatMessage({
              id: "editUserModal_addNewSupervisorButton",
            })}
          </TextButton>

          <Autocomplete
            multiple
            id="selected-permission-groups"
            data-testid="permission-group-select"
            disableCloseOnSelect
            disableClearable
            aria-label="choose-group"
            options={handleFilterAuthorizationGroupsValues()}
            getOptionDisabled={isAuthorizationOptionDisabled}
            value={selectedPermissionGroups}
            onChange={(_, values) =>
              handleChangeFormState(
                values as string[],
                setSelectedPermissionGroups
              )
            }
            getOptionLabel={(option) => (option as string) || ""}
            renderOption={(option) => option}
            renderTags={(tagValue, getTagProps) => {
              return tagValue?.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={option as string}
                  label={option}
                  disabled={isAuthorizationOptionDisabled(option)}
                />
              ));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={formatMessage({
                  id: "userDetailsPage_editUserModalLabelPermissionGroups",
                })}
                placeholder={formatMessage({
                  id: "userDetailsPage_editUserModalLabelPermissionGroups",
                })}
              />
            )}
          />
        </FormControlField>
      </FormContainer>

      <FormContainer>
        <FormControlField width="100%">
          <Autocomplete
            multiple
            disableCloseOnSelect
            disableClearable
            id="supported-countries"
            options={sortCountryListAlphabetically(supportedCountries)}
            getOptionDisabled={handleDisabledSupportedCountriesValues}
            onChange={(_, values) =>
              handleChangeFormState(
                values as string[],
                setSelectedSupportedCountries
              )
            }
            getOptionLabel={(value) =>
              allSupportedCountries[value as AllSupportedCountryKeys] || value
            }
            value={selectedSupportedCountries}
            renderOption={(option) =>
              allSupportedCountries[option as AllSupportedCountryKeys] || option
            }
            renderTags={(tagValue, getTagProps) => {
              return tagValue?.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={option as string}
                  label={
                    allSupportedCountries[option as AllSupportedCountryKeys] ||
                    option
                  }
                  disabled={handleDisabledSupportedCountriesValues(option)}
                />
              ));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={formatMessage({
                  id: "userDetailsPage_editUserModalLabelSupportedCountries",
                })}
                data-testid="supported-countries"
                placeholder={formatMessage({
                  id: "userDetailsPage_editUserModalLabelSupportedCountries",
                })}
              />
            )}
          />
        </FormControlField>
      </FormContainer>

      <FormContainer>
        <FormControlField width="100%">
          <ChipsField
            chipList={companyIds}
            setChipList={(ids) => handleChangeFormState(ids, changeCompanyIds)}
            inputId="companyIds"
            inputLabel={formatMessage({
              id: "userDetailsPage_editUserModalLabelCompanyIds",
            })}
            description={formatMessage({
              id: "userDetailsPage_editUserModalDescriptionCompanyIds",
            })}
          />
        </FormControlField>
      </FormContainer>

      {isAttributesFieldVisible.map((attributeField) => {
        return (
          <fieldset key={attributeField}>
            {attributeField.includes(EnumAttributeField.BDR_ID) && (
              <FormContainer>
                <Input
                  id="bdrId"
                  label="BDR ID"
                  width="100%"
                  value={bdrId}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChangeFormState(e.target.value, setBdrId)
                  }
                />
              </FormContainer>
            )}

            {attributeField.includes(
              EnumAttributeField.DELIVERY_CENTER_IDS
            ) && (
              <FormContainer>
                <FormControlField width="100%">
                  <ChipsField
                    chipList={deliveryIDs}
                    setChipList={(ids) =>
                      handleChangeFormState(ids, setDeliveryIDs)
                    }
                    inputId="deliveryCenterIds"
                    inputLabel="Delivery Center IDs"
                    description="Use commas to separate values"
                  />
                </FormControlField>
              </FormContainer>
            )}

            {attributeField.includes(
              EnumAttributeField.LOGISTIC_OPERATOR_IDS
            ) && (
              <FormContainer>
                <FormControlField width="100%">
                  <ChipsField
                    chipList={logisticIDs}
                    setChipList={(ids) =>
                      handleChangeFormState(ids, setLogisticIDs)
                    }
                    inputId="logisticOperatorIds"
                    inputLabel="Logistic Operator IDs"
                    description="Use commas to separate values"
                  />
                </FormControlField>
              </FormContainer>
            )}

            {attributeField.includes(EnumAttributeField.ROUTE) && (
              <FormContainer>
                <Input
                  id="routeId"
                  label="Route ID"
                  width="100%"
                  value={routeID}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChangeFormState(e.target.value, setRouteID)
                  }
                />
              </FormContainer>
            )}
          </fieldset>
        );
      })}

      <FormContainer>
        <SingleSelect
          label="Add attribute field"
          width="220px"
          value={selectedAttribute}
          options={attributeFieldOptions}
          placeholder="Select..."
          disabled={
            isAttributesFieldVisible.length === attributeFieldOptions.length
          }
          onChange={(value) => handleSelectedAttribute(value)}
        />
      </FormContainer>

      {errorMessages.length > 0 && (
        <AlertContainer data-testid="alert-messages">
          {requestCounts - errorMessages.length > 0 && (
            <Alert severity="success">
              {requestCounts - errorMessages.length} of {requestCounts} changes
              updated successfully.
            </Alert>
          )}
          <Alert severity="error">
            <pre>{errorMessages.join(`, \n`)}</pre>
          </Alert>
        </AlertContainer>
      )}
      <ModalFooter>
        <ModalButton
          variant="outline"
          onClick={handleCancel}
          data-testid="cancel modal edit user"
        >
          {formatMessage({ id: "userDetailsPage_editUserModalCancelButton" })}
        </ModalButton>
        <ModalButton
          disabled={!hasChange || hasHierarchyErrors}
          onClick={saveEditUser}
          data-testid="confirm save"
        >
          {formatMessage({
            id: "userDetailsPage_editUserModalSaveChangesButton",
          })}
        </ModalButton>
      </ModalFooter>
      {isLoading && (
        <LoadingBackground>
          <Loading data-testid="loader" color="inherit" />
        </LoadingBackground>
      )}
      <SnackBar
        open={openAlertCloseModal}
        onClose={() => setOpenAlertCloseModal(false)}
        message={formatMessage({ id: "editUserModal_pendingChangesSnackbar" })}
        severity="warning"
      />
    </DialogEditUser>
  );
}
