import { LocalStorageService } from "./LocalStorageService";
import { SERVICE_NAME } from "./LocalStorageService.consts";
import { ILocalStorageService } from "./LocalStorageService.types";

export const useLocalStorageService = (): ILocalStorageService => {
  if (!window.services) window.services = {};

  if (!window.services[SERVICE_NAME]) {
    window.services[SERVICE_NAME] = new LocalStorageService();
  }

  return window.services[SERVICE_NAME] as ILocalStorageService;
};
