export const applications = {
  adminportal: "Bees Hub",
  cmsadmin: "Bees CMS",
  deliveradmin: "Bees Deliver",
  force: "Force BDR",
  oncustomer: "Bees Grow",
  social: "Bees Social",
  supplier: "Bees Partner",
} as const;

export const dtcCustomerApplications = {
  dtc_courier: "Dtc Courier",
  dtc_seller: "Dtc Seller",
  dtc_consumer: "Dtc Consumer",
} as const;

export const allApplications = {
  ...applications,
  ...dtcCustomerApplications,
} as const;

export type ApplicationKeys = keyof typeof applications | "b2b";