import { useHasPermission } from "admin-portal-shared-services";
import { IconButton } from "@hexa-ui/components";
import { Copy as CopyIcon, Mail } from "@hexa-ui/icons";
import React, { useState } from "react";
import { IAccount } from "interfaces";
import { TOAST_SEVERITY, useToast } from "components/Toast";
import { BffService } from "services/bff-service";
import { SegmentService, getDecodedToken } from "helpers";
import {
  IIsAbleToResendInviteProps,
  IUserInviteActionsProps,
  UserInviteActionsContainer,
  EnvironmentEnum,
} from ".";
import { StatusEnum } from "../Status/types";

export const getHostEnv = (env: EnvironmentEnum): boolean => {
  return window.location.host.includes(env);
};

export const isAbleToResendInvite = ({
  user: { appName, country, hasPermission },
}: IIsAbleToResendInviteProps): boolean => {
  const countriesAllowed = ["BR", "ZA"].includes(country);
  const isUsEnv = getHostEnv(EnvironmentEnum.US);
  const isDtcEnv = getHostEnv(EnvironmentEnum.DTC);

  const isCustomerUser = appName === "b2b";
  const isAdminUser = !isCustomerUser;
  const isDtcAdminUser = isAdminUser && isDtcEnv;
  const isGlobalCustomerUser = isCustomerUser && countriesAllowed;

  return (
    hasPermission &&
    (isGlobalCustomerUser || isAdminUser || isDtcAdminUser || isUsEnv)
  );
};

export const isAbleToCopyRegistrationLink = (
  hasPermission: boolean
): boolean => {
  const isUsEnv = getHostEnv(EnvironmentEnum.US);
  const isDtcEnv = getHostEnv(EnvironmentEnum.DTC);

  const isRestrictedEnv = isDtcEnv || isUsEnv;

  if (isRestrictedEnv) return false;

  return hasPermission;
};

export const UserInviteActions = ({
  user,
  accounts,
}: IUserInviteActionsProps): JSX.Element | null => {
  const { setToastOptions } = useToast();
  const { name: loggedUserName } = getDecodedToken();
  const [isLoading, setIsLoading] = useState({
    resendInvite: false,
    copyRegistrationLink: false,
  });

  const [newInvitedId, setNewInvitedId] = useState<string | undefined>(
    user.inviteId
  );
  const customerInvitePermission = useHasPermission(
    "IdentityFE.CustomerUser.Invite"
  );
  const adminInvitePermission = useHasPermission("IdentityFE.AdminUser.Invite");

  const isCustomerUser = [
    "dtc_courier",
    "dtc_seller",
    "dtc_consumer",
    "b2b",
  ].includes(user.application);

  const hasAccounts = isCustomerUser && accounts.length === 0;

  const hasPermissionToResendInvite = () => {
    return isCustomerUser ? customerInvitePermission : adminInvitePermission;
  };

  const hasPermissionToRegistrationLinkInvite = useHasPermission(
    "IdentityFE.RegistrationLink.Copy"
  );

  const userInviteParams = {
    user: {
      appName: user?.application,
      country: user?.country,
      hasPermission: hasPermissionToResendInvite(),
    },
  };

  const isUserStatusPending = user?.status === StatusEnum.PENDING;

  if (!isUserStatusPending) return null;

  const handleResendInvite = async () => {
    const bffService = new BffService();
    const vendorId = accounts[0]?.vendorId;
    const accountListName = accounts.map((account: IAccount) => account.name);

    try {
      setIsLoading({ ...isLoading, resendInvite: true });

      let invitedId = "";

      if (user.application === "b2b") {
        invitedId = await bffService.resendCustomerInvite({
          userId: user?.id,
          country: user?.country,
          appName: user?.application,
          invitedUserName: user?.displayName,
          wholesalerId: vendorId,
          wholesalerName: vendorId,
          accountsName: accountListName,
          requesterUserName: loggedUserName,
        });
      } else {
        invitedId = await bffService.resendAdminInvite({
          userId: user?.id,
          country: user?.country,
          appName: user?.application,
          invitedUserName: user?.displayName,
          requesterUserName: "",
        });
      }

      setNewInvitedId(invitedId);
      setToastOptions({
        type: TOAST_SEVERITY.SUCCESS,
        message: "Invite sent successfully",
        duration: 3000,
      });
      SegmentService.resendUserInvitation({
        country: user.country,
        userApplication: user.application,
        userId: user.id,
      });
    } catch (error) {
      setToastOptions({
        type: TOAST_SEVERITY.ERROR,
        message: `Invitation couldn't be sent`,
        duration: 3000,
      });
    } finally {
      setIsLoading({ ...isLoading, resendInvite: false });
    }
  };

  const handleCopyRegistrationLink = async () => {
    const bffService = new BffService();

    try {
      setIsLoading({ ...isLoading, copyRegistrationLink: true });

      const registrationLink = await bffService.getRegistrationLink({
        userId: user?.id,
        inviteId: newInvitedId as string,
        userCountry: user?.country,
        userApp: user?.application,
      });

      if (registrationLink) {
        navigator.clipboard.writeText(registrationLink);

        setToastOptions({
          type: TOAST_SEVERITY.SUCCESS,
          message: "Activation URL copied",
          duration: 3000,
        });

        SegmentService.copyUserRegistrationLink({
          country: user.country,
          userApplication: user.application,
          userId: user.id,
        });
      }
    } catch (error) {
      setToastOptions({
        type: TOAST_SEVERITY.ERROR,
        message: `Activation URL copied couldn't be copied`,
        duration: 3000,
      });
    } finally {
      setIsLoading({ ...isLoading, copyRegistrationLink: false });
    }
  };

  return (
    <UserInviteActionsContainer>
      {isAbleToResendInvite(userInviteParams) && (
        <IconButton
          variant="secondary"
          size="small"
          icon={Mail}
          style={{ marginRight: "16px" }}
          title="Resend Invite"
          loading={isLoading.resendInvite}
          disabled={
            isLoading.resendInvite ||
            Boolean(newInvitedId) ||
            !isAbleToResendInvite(userInviteParams) ||
            hasAccounts
          }
          onClick={handleResendInvite}
        />
      )}
      {isAbleToCopyRegistrationLink(hasPermissionToRegistrationLinkInvite) && (
        <IconButton
          variant="secondary"
          size="small"
          icon={CopyIcon}
          title="Registration Link"
          loading={isLoading.copyRegistrationLink}
          onClick={handleCopyRegistrationLink}
          disabled={
            !newInvitedId ||
            isLoading.resendInvite ||
            isLoading.copyRegistrationLink ||
            !isAbleToCopyRegistrationLink(hasPermissionToRegistrationLinkInvite)
          }
        />
      )}
    </UserInviteActionsContainer>
  );
};
