import { FileType } from "identity-admin-mfe/modules/user-management/services/AnalyticService";
import { SingleSelect } from "components/SingleSelect";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { useUserMetadata, Vendor } from "admin-portal-shared-services";
import { SegmentService } from "helpers";
import { VendorSelectorWrapper } from "./styles";

export const VendorSelector = (): JSX.Element => {
  const [selectedVendorId, setSelectedVendorId] = useState<string>();
  const { data, isLoading } = useUserMetadata();
  const vendors: Vendor[] = data?.vendors ?? [];
  const vendorOptions = vendors.map((vendor: Vendor) => ({
    label: vendor.displayName,
    value: vendor.id,
  }));

  const [CSVData, setCSVData] = useState<string[][]>([]);
  const [isDownloadEnabled, setIsDownloadEnabled] = useState<boolean>(false);

  const generateCSV = (vendorId: string) => {
    const vendor = vendors?.find(
      (vendorInstance) => vendorInstance.id === vendorId
    );

    if (!vendor) return;

    const CSVContent: string[][] = [
      [
        "user",
        "country",
        "vendorAccountId",
        "vendorId",
        "vendorName",
        "action",
      ],
      [
        "e-mail or phone",
        vendor.country ?? "",
        "vendorAccountId value",
        vendor.id ?? "",
        vendor.displayName ?? "",
        "ADD",
      ],
      [
        "e-mail or phone",
        vendor.country ?? "",
        "vendorAccountId value",
        vendor.id ?? "",
        vendor.displayName ?? "",
        "REMOVE",
      ],
    ];

    setSelectedVendorId(vendorId);
    setCSVData(CSVContent);
    setIsDownloadEnabled(true);
  };

  const registerSegmentEvent = () => {
    SegmentService.bulkActionsTemplateDownloadedModal(
      FileType.EditAdminUser,
      selectedVendorId
    );
  };

  return (
    <VendorSelectorWrapper>
      <SingleSelect
        label="Select vendor"
        hint="Choose a vendor to enable template download"
        options={vendorOptions.map((vendor) => ({
          label: vendor.label ?? "",
          value: vendor.value ?? "",
        }))}
        value={selectedVendorId}
        onChange={generateCSV}
        disabled={vendorOptions.length === 0 || isLoading}
      />
      <CSVLink
        enclosingCharacter=""
        data={CSVData}
        aria-disabled={!isDownloadEnabled}
        tabIndex={isDownloadEnabled ? 0 : -1}
        onClick={registerSegmentEvent}
      >
        Download template
      </CSVLink>
    </VendorSelectorWrapper>
  );
};
