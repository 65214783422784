import React from "react";
import { StyledCircleSkeleton, StyledRectangleSkeleton } from "./styles";

export function UserDetailsSkeleton(): JSX.Element {
  return (
    <>
      <div
        style={{ minWidth: "864px", width: "-webkit-fill-available" }}
        data-testid="user-details-skeleton"
      >
        <div
          style={{
            width: "406px",
            height: "72px",
            marginTop: "24px",
            marginLeft: "32px",
            display: "flex",
          }}
        >
          <StyledCircleSkeleton width="72px" height="72px" />
          <div style={{ marginLeft: "16px", alignSelf: "center" }}>
            <StyledRectangleSkeleton height="32px" width="318px" />
            <StyledRectangleSkeleton
              height="20px"
              width="217px"
              style={{ marginTop: "4px" }}
            />
          </div>
        </div>
        <div style={{ marginTop: "24px", marginLeft: "33px" }}>
          <StyledRectangleSkeleton height="20px" width="53px" />
          <StyledRectangleSkeleton
            height="20px"
            width="253px"
            style={{ marginTop: "2px" }}
          />
        </div>
        <div style={{ marginTop: "18px", marginLeft: "33px" }}>
          <StyledRectangleSkeleton height="20px" width="46px" />
          <StyledRectangleSkeleton
            height="20px"
            width="135px"
            style={{ marginTop: "2px" }}
          />
        </div>
        <div style={{ marginTop: "18px", marginLeft: "33px" }}>
          <StyledRectangleSkeleton height="20px" width="68px" />
          <StyledRectangleSkeleton
            height="20px"
            width="159px"
            style={{ marginTop: "2px" }}
          />
        </div>
        <div style={{ marginLeft: "33px" }}>
          <div
            style={{
              marginTop: "26px",
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
            }}
          >
            <div>
              <StyledRectangleSkeleton height="20px" width="80px" />
              <StyledRectangleSkeleton
                height="20px"
                width="68px"
                style={{ marginTop: "2px" }}
              />
            </div>
            <div>
              <StyledRectangleSkeleton height="20px" width="131px" />
              <StyledRectangleSkeleton
                height="20px"
                width="186px"
                style={{ marginTop: "2px" }}
              />
            </div>
            <div>
              <StyledRectangleSkeleton height="20px" width="131px" />
              <StyledRectangleSkeleton
                height="20px"
                width="186px"
                style={{ marginTop: "2px" }}
              />
            </div>
          </div>
          <div
            style={{
              marginTop: "18px",
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
            }}
          >
            <div>
              <StyledRectangleSkeleton height="20px" width="80px" />
              <StyledRectangleSkeleton
                height="20px"
                width="218px"
                style={{ marginTop: "2px" }}
              />
            </div>
            <div>
              <StyledRectangleSkeleton height="20px" width="103px" />
              <StyledRectangleSkeleton
                height="20px"
                width="218px"
                style={{ marginTop: "2px" }}
              />
            </div>
            <div>
              <StyledRectangleSkeleton height="20px" width="131px" />
              <StyledRectangleSkeleton
                height="20px"
                width="221px"
                style={{ marginTop: "2px" }}
              />
            </div>
          </div>
        </div>
        <div style={{ marginTop: "26px", marginLeft: "28px" }}>
          <StyledRectangleSkeleton height="24px" width="222px" />
          <StyledRectangleSkeleton
            height="24px"
            width="299px"
            style={{ marginTop: "16px" }}
          />
          <StyledRectangleSkeleton
            height="24px"
            width="158px"
            style={{ marginTop: "16px" }}
          />
          <StyledRectangleSkeleton
            height="24px"
            width="199px"
            style={{ marginTop: "16px" }}
          />
        </div>
      </div>
    </>
  );
}
