import { Box, Typography } from "@material-ui/core";
import styled from "styled-components";

export const StyledContainer = styled(Box)`
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  max-width: 100%;
  width: 100%;
  margin-top: 24px;
  height: auto;
  display: flex;
`;

export const StyledTitle = styled(Typography)`
  padding: 24px 24px 16px 24px;
  font: 600 24px/32px "Barlow";
`;

export const Title = styled.h1`
  font-size: 40px;
  line-height: 48px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px auto;
  padding: 0;
`;

export const StyledTableContainer = styled.div`
  width: 100%;
`;

export const StyledScreen = styled.div`
  display: grid;
  grid-template-rows: 56px 112px 1fr;
  height: 100vh;
`;

export const StyledHeaderActions = styled.div`
  display: flex;
  align-items: center;
`;
