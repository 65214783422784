import { useAuthenticationService as authService } from "admin-portal-shared-services";
import axios, { AxiosInstance } from "axios";
import { getToken } from "helpers";
import { getApiHost } from "services/host/HostService";
import { v4 } from "uuid";
import { IApprovalFlowRequesters, IApprovalFlowRequestsParams } from "./types";

export class ApprovalFlowService {
  private axiosInstance: AxiosInstance | any;

  private BASE_URL = getApiHost();

  constructor() {
    this.axiosInstance = axios.create();
  }

  async getRequesters(
    entityId: string
  ): Promise<{ data: { content: IApprovalFlowRequesters[] } }> {
    const URL = `${this.BASE_URL}/approval-requests?searchValue=${entityId}`;

    const headers = {
      requestTraceId: v4(),
      Authorization: getToken(),
    };

    const { data } = await authService()
      .enhancedAxios(this.axiosInstance)
      .get(URL, { headers });

    return data;
  }

  async createRequesters(
    payload: IApprovalFlowRequestsParams
  ): Promise<number> {
    const URL = `${this.BASE_URL}/approval-requests`;

    const headers = {
      Authorization: getToken(),
      requestTraceId: v4(),
    };

    const { status } = await authService()
      .enhancedAxios(this.axiosInstance)
      .post(URL, payload, { headers });

    return status;
  }
}
