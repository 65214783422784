import { styled } from "@bees/vision-tokens";

export const Container = styled("div", {
  width: "38rem",
  height: "38rem",
});

export const SubTitle = styled("div", {
  marginTop: "$4",
  marginBottom: "$6",
});
