import { SupportedCountryKeys } from "country-utils";

export enum UserContactTypes {
  emailAddress = "emailAddress",
  phoneNumber = "phoneNumber",
}

export enum UserTypes {
  Customer = "Customer",
}

export interface IUser {
  id: string;
  displayName: string;
  givenName: string;
  surname: string;
  contacts: IContact[];
  country: SupportedCountryKeys;
  accountEnabled: boolean;
  createdDateTime: string;
  status: string;
  groups: string[];
  customData: ICustomItem;
  invitationAcceptedDate: string;
  invitationDate: string;
  userType: UserTypes;
  appName: string;
  accountsCount: number;
}

interface IContact {
  type: UserContactTypes;
  value: string;
  origin: string;
}

interface ICustomItem {
  supportedCountries: string[];
  accounts: string[];
  vendors: string[];
  deliveryCenterIds: string[];
  logisticOperatorIds: string[];
  routeId: string;
  bdrId: string;
}
