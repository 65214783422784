import { useAuthenticationService as authService } from "admin-portal-shared-services";
import axios, { AxiosInstance } from "axios";
import { getToken } from "helpers";
import { getDecodedToken } from "helpers/Token/token";
import { getApiHost } from "services/host/HostService";
import { v4 } from "uuid";

export interface AuthorizationGroup {
  app: string | null;
  description?: string | null;
  name: string;
}
export interface PostGroup {
  app: string | null;
  group: string;
  userId: string | undefined;
}

export class AuthorizationGroupService {
  private axiosInstance: AxiosInstance | any;

  constructor() {
    this.axiosInstance = axios.create();
  }

  public async getAuthorizationGroups(): Promise<{
    data: AuthorizationGroup[];
  }> {
    const { country, appName } = getDecodedToken();
    const baseUrl = getApiHost({ country, appName });
    const URL = `${baseUrl}/groups`;

    const headers = {
      requestTraceId: v4(),
      Authorization: getToken(),
    };

    try {
      const { data } = await authService()
        .enhancedAxios(this.axiosInstance)
        .get(URL, { headers });
      return data;
    } catch {
      return { data: [] };
    }
  }

  public async postAuthorizationGroups(
    group: PostGroup,
    country: string
  ): Promise<boolean> {
    const baseUrl = getApiHost();
    const URL = `${baseUrl}/user-mfe/users/permissions/groups/`;

    const headers = {
      country,
      requestTraceId: v4(),
      Authorization: getToken(),
    };

    const payload = {
      app: group.app,
      group: group.group,
      userId: group.userId,
    };

    try {
      const { status } = await authService()
        .enhancedAxios(this.axiosInstance)
        .post(URL, payload, { headers });
      return status === 201;
    } catch {
      return false;
    }
  }

  public async deleteAuthorizationGroups(
    group: PostGroup,
    country: string
  ): Promise<boolean> {
    const baseUrl = getApiHost();
    const URL = `${baseUrl}/user-mfe/users/permissions/groups/`;

    const headers = {
      country,
      requestTraceId: v4(),
      Authorization: getToken(),
    };

    const payload = {
      app: group.app,
      group: group.group,
      userId: group.userId,
    };

    try {
      const { status } = await authService()
        .enhancedAxios(this.axiosInstance)
        .delete(URL, { headers, data: payload });
      return status === 200 || status === 204;
    } catch (err) {
      return false;
    }
  }
}
