import { StyledTableContainerHeader } from "components/Filter/styles";
import React from "react";
import { useIntl } from "react-intl";
import { Logo } from "./Logo/Logo";
import { Container, Description } from "./styles";

interface Props {
  searched: string | null;
}

export function UserTableEmpty({ searched }: Props): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <>
      <StyledTableContainerHeader>
        {formatMessage({ id: "userTableEmpty_header" })}
      </StyledTableContainerHeader>
      <Container>
        <Logo />
        <Description>
          {formatMessage({ id: "userTableEmpty_description" }, { searched })}
        </Description>
      </Container>
    </>
  );
}
