import { AuditLogsItemTypes } from "pages/UserDetails/Components/AuditLogsHistory/item";

export enum OperationTypeEnum {
  INSERT = "INSERT",
  DELETE = "DELETE",
  UPDATE = "UPDATE",
}

export enum AprovalRequestStatusEnum {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  REJECTED = "REJECTED",
}

export interface IApprovalFlowRequesters {
  id: string;
  serviceName: string;
  operation: AuditLogsItemTypes;
  entity: string;
  title: string;
  country: string;
  user: {
    id: string;
    name: string;
    email: string;
  };
  data: Data;
  groupsToView: string[];
  groupsToApproveRequest: string[];
  vendorId: string;
  createdAt: string;
  updatedAt: string;
  status: AprovalRequestStatusEnum;
  replier: {
    email: string;
    id: string;
    name: string;
  };
  rejectReason: string;
}

interface User {
  id: string;
  name: string;
  email: string;
}

interface TargetRequester {
  id: string;
  name: string;
  country: string;
  appName: string;
}

interface IActionsList<T = string> {
  added: T[];
  deleted?: T[];
}
export interface NewData<T = string> {
  authorizationGroups: IActionsList<T>;
  supportedCountries?: IActionsList<T>;
  accounts?: IActionsList<T>;
  vendor?: IActionsList<T>;
  logisticOperatorIds?: IActionsList<T>;
  deliveryCenterIds?: IActionsList<T>;
  bdrId?: string;
  routeId?: string;
  isBlock?: string;
}

interface CurrentData {
  bdrId?: string;
  routeId?: string;
  isBlock: boolean;
}

interface Data {
  target: TargetRequester;
  requester?: TargetRequester;
  new: NewData;
  current: CurrentData;
}

export interface IApprovalFlowRequestsParams {
  serviceName?: string;
  operation?: string;
  entity: "USER" | "ACCOUNT";
  title: string;
  rejectReason?: string;
  user?: User;
  replier?: User;
  data?: Data;
  country?: string;
  groupsToView?: string[];
  groupsToApproveRequest?: string[];
}
