import {
  Button,
  FileUploader,
  Heading,
  TextArea,
  FileValidated as ValidatedFile,
} from "@hexa-ui/components";
import { FileType } from "identity-admin-mfe/modules/user-management/services/AnalyticService";
import csvTemplate from "assets/csv/bulk-actions-admin-users-csv-template.csv";
import { Dialog } from "components/Dialog";
import { TOAST_SEVERITY, useToast } from "components/Toast";
import { SegmentService, getDecodedToken } from "helpers";
import { countFileRows, reader } from "helpers/FileStream/FileStream";
import React, { useState } from "react";
import {
  BulkActionsEventCodes,
  postBatchActionsFile,
} from "services/batchActions";
import { VendorSelector } from "./VendorSelector";
import { Alert, DownloadTemplate, InputFileContainer } from "./styles";
import { DialogUploadFileProps, FileTypes, FileUploadErrors } from "./types";

const MAX_FILE_SIZE_ALLOWED = 30720; // 30KB
const ROW_LIMIT = {
  AUTHORIZATION: 200,
  INVITATION: 200,
};

export const DialogUploadFile = ({
  open,
  onClose,
  onConfirm,
  fileType = FileTypes.Authorization,
}: DialogUploadFileProps): JSX.Element => {
  const { setToastOptions } = useToast();
  const { userId } = getDecodedToken();
  const [file, setFile] = useState<File>();
  const [fileDescription, setFileDescription] = useState<string>("");
  const [fileUploadError, setFileUploadError] = useState<
    FileUploadErrors | undefined
  >(undefined);

  const fileTypeSegment =
    fileType === FileTypes.Authorization
      ? FileType.EditAdminUser
      : FileType.ManageCustomerUser;

  const handleCancelFileUpload = (hasCancelButtonBeenPressed: boolean) => {
    SegmentService.newUploadedCanceled(
      hasCancelButtonBeenPressed,
      fileTypeSegment
    );
    handleCloseFileDialog();
  };

  const handleCloseFileDialog = () => {
    onClose();
    setFile(undefined);
    setFileUploadError(undefined);
    setFileDescription("");
  };

  const handleOnInteractOutside = () => {
    setToastOptions({
      type: TOAST_SEVERITY.WARNING,
      showCloseButton: true,
      message: "Close upload file modal?",
      duration: 5000,
      actions: [
        {
          action: () => handleCancelFileUpload(false),
          name: "Close",
        },
      ],
    });
  };

  const getVendorId = async (csv: File) => {
    const parsedFileData = await reader(csv);
    const vendorId = parsedFileData.split(",")[8];
    return vendorId;
  };

  const handleUploadFile = async () => {
    if (!file) return;

    const fileName = `${userId}-${new Date().getTime()}.csv`;
    const code =
      fileType === FileTypes.Authorization
        ? BulkActionsEventCodes.AuthEvent
        : BulkActionsEventCodes.InvitationEvent;
    const result = await postBatchActionsFile({
      file,
      fileName,
      description: fileDescription,
      code,
    });

    const rowsNumber = await countFileRows(file);

    SegmentService.newUploadSubmitted({
      fileTitle: file.name,
      fileDescription,
      rowsNumber,
      fileType: fileTypeSegment,
      vendorId:
        fileTypeSegment === FileType.ManageCustomerUser
          ? await getVendorId(file)
          : "",
    });
    if (result) {
      onConfirm(fileName);
      setToastOptions({
        type: TOAST_SEVERITY.SUCCESS,
        message: "Upload done successfully",
        duration: 5000,
      });
    } else {
      setToastOptions({
        type: TOAST_SEVERITY.ERROR,
        message: "Unavailable service. Please try again later.",
        duration: 5000,
      });
    }

    handleCloseFileDialog();
  };

  const isValidFile = async (
    preValidatedFile: ValidatedFile
  ): Promise<boolean> => {
    const { file: fileContent, valid, errors } = preValidatedFile;
    const hasTooManyLines = await countFileRows(fileContent).then(
      (rowCount) => rowCount >= ROW_LIMIT[fileType]
    );
    if (!valid || hasTooManyLines) {
      SegmentService.fileUploaded(false, fileTypeSegment);
      setFileUploadError({
        message: hasTooManyLines
          ? `The attached CSV file exceeds the ${ROW_LIMIT[fileType]} rows limit.`
          : errors?.toString() ?? "",
      });

      return false;
    }

    SegmentService.fileUploaded(true, fileTypeSegment);
    setFileUploadError(undefined);
    return true;
  };

  const handleOnChangeFile = async ([preValidatedFile]: ValidatedFile[]) => {
    const { file: fileContent } = preValidatedFile;
    const isValid = await isValidFile(preValidatedFile);

    if (isValid) setFile(fileContent);
  };

  const handleTemplateDownload = () =>
    SegmentService.bulkActionsTemplateDownloadedModal(FileType.EditAdminUser);

  return (
    <Dialog
      open={open}
      contentCss={{ width: "688px" }}
      title={<Heading size="H1">Upload CSV file</Heading>}
      onClose={() => handleCancelFileUpload(false)}
      onEscapeKeyDown={handleOnInteractOutside}
      onInteractOutside={handleOnInteractOutside}
      actions={{
        btnSecundary: (
          <Button
            onClick={() => handleCancelFileUpload(true)}
            size="medium"
            variant="secondary"
          >
            Cancel
          </Button>
        ),
        btnPrimary: (
          <Button
            disabled={
              !file || fileUploadError !== undefined || !fileDescription
            }
            onClick={handleUploadFile}
            size="medium"
            variant="primary"
          >
            Upload
          </Button>
        ),
      }}
    >
      <>
        <Alert
          message={`There is a limit of ${ROW_LIMIT[fileType]} rows per CSV file.`}
        />

        {fileType === "INVITATION" && <VendorSelector />}

        <InputFileContainer>
          <FileUploader
            behaviour="replace"
            accept=".csv"
            type={file?.name ? "file" : "document"}
            maxFileSize={MAX_FILE_SIZE_ALLOWED}
            maxFiles={1}
            fakeUploading
            message={
              file?.name
                ? `${file?.name} - ${file?.size / 1000}KB`
                : "Drop your CSV file here or"
            }
            onChange={handleOnChangeFile}
            error={fileUploadError}
          />
        </InputFileContainer>
        <TextArea
          id="text-area-with-label"
          characterCounter
          onChange={(event) => setFileDescription(event.target.value)}
          maxLength={100}
          label="Description"
          placeholder="Example: Remove inactive users from application. "
          width="100%"
        />
        {fileType === FileTypes.Authorization && (
          <DownloadTemplate
            href={csvTemplate}
            onClick={handleTemplateDownload}
            download
          >
            Download template
          </DownloadTemplate>
        )}
      </>
    </Dialog>
  );
};
