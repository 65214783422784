import styled from "styled-components";

export const Container = styled.button`
  height: 40px;
  background-color: #000;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 400 16px/24px "Work Sans";
  padding-left: 8px;
  padding-right: 24px;
  border: none;
  border-radius: 20px;
  white-space: nowrap;
  cursor: pointer;
  margin-right: 16px;
`;

export const ContainerIcon = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ffff00;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  & svg {
    fill: #000;
  }
`;
