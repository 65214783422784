export enum TabsBatchActionEnum {
  editAdminUsers = "EDIT_ADMIN_USERS",
  manageCustomerUsers = "MANAGE_CUSTOMER_USERS",
}

export interface DialogUploadFileProps {
  activeTab: TabsBatchActionEnum;
}

export type ICsvTemplates = {
  [TabsBatchActionEnum.editAdminUsers]: string;
  [TabsBatchActionEnum.manageCustomerUsers]: string;
};
