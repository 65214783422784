import { styled } from "@bees/vision-tokens";
import { Card } from "@hexa-ui/components";

export const HeaderActions = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "1.875rem",
});

export const HeaderTitle = styled("h2", {
  fontFamily: "$heading",
  fontSize: "2rem",
  fontWeight: "$semibold",
  lineHeight: "$8",
});

export const Wrapper = styled("div", {});

export const Panel = styled(Card, {
  padding: "$4",
  marginTop: "$4",
  minHeight: "20rem",
});
