import { styled } from "@bees/vision-tokens";

export const Chip = styled("div", {
  display: "inline-flex",
  alignItems: "center",
  border: "none",
  borderRadius: "$4",
  padding: "$2 $4",
  variants: {
    isDisabled: {
      true: {
        color: "$neutral50",
        backgroundColor: "$neutral20",

        svg: {
          fill: "$neutral30",
        },
        "&:hover": {
          backgroundColor: "$neutral20",
        },
      },
      false: {
        color: "$neutral100",
        backgroundColor: "$semanticInfoBackground",

        svg: {
          fill: "$semanticInfoBasis",
        },
        "&:hover": {
          opacity: 0.8,
        },
      },
    },
  },

  "&> *:not(:last-child)": {
    marginRight: "$2",
  },

  button: {
    display: "flex",
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    "&:disabled": {
      cursor: "text",
    },
  },
});
