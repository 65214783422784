import { styled } from "@bees/vision-tokens";
import { Paragraph } from "@hexa-ui/components";

export const HeaderActions = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  marginTop: "$2",
});

const IconCicle = {
  background: "$brandAccentExcitingText",
  borderRadius: "50%",
  width: "calc(1rem/2)",
  height: "calc(1rem/2)",
  content: "",
  position: "absolute",
  left: "0.939rem",
};

const CustomParagraph = styled(Paragraph, {
  display: "flex",
  alignItems: "center",
  paddingLeft: "1.25rem",
});

export const SucessfulIcon = styled(CustomParagraph, {
  "&:before": {
    ...IconCicle,
    background: "$semanticSuccessBasis",
  },
});

export const PartiallySucessfulIcon = styled(CustomParagraph, {
  "&:before": {
    ...IconCicle,
    background: "$semanticWarningBasis",
  },
});

export const FailIcon = styled(CustomParagraph, {
  "&:before": {
    ...IconCicle,
    background: "$semanticErrorBasis",
  },
});

export const UpdateTimeDisplay = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  margin: "$4 0",
});
