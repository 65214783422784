import { IAccount } from "interfaces/IAccount";
import { IUserDetails } from "services/bff-service";

export interface IUserInviteActionsProps {
  user: IUserDetails;
  accounts: IAccount[];
}

export interface IIsAbleToResendInviteProps {
  user: {
    appName: string;
    country: string;
    hasPermission: boolean;
  };
}

export enum EnvironmentEnum {
  DTC = "dtc",
  US = "us",
}
