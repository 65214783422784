import React from "react";
import { useIntl } from "react-intl";
import searchImg from "../../../../assets/search.png";
import { SearchImg, SearchText, StyledContainer } from "./styles";

export function SearchMessage(): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <StyledContainer>
      <SearchImg src={searchImg} />
      <SearchText>{formatMessage({ id: "userPage_searchMessage" })}</SearchText>
    </StyledContainer>
  );
}
