import {
  IGetChartStructureParams,
  IGetChartStructureResponse,
} from "identity-admin-contracts/user-management";
import { axiosClient } from "@shared/api";

export const getChartStructure = async ({
  country,
  application,
  id,
}: IGetChartStructureParams) =>
  axiosClient.get<IGetChartStructureResponse>(
    `/user-management/chart-structure`,
    {
      params: { country, application, id },
    },
  );
