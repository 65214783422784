import { Box, Checkbox, TableHead, TableRow } from "@material-ui/core";
import { StyleAvatar } from "components";
import { StyledTableContainerHeader } from "components/Filter/styles";
import { DotSpinner } from "components/Spinners/DotSpinner/DotSpinner";
import { initialLetters, SegmentService } from "helpers";
import {
  ApplicationKeys,
  allApplications,
} from "helpers/Applications/applications";
import { SegmentFormatData } from "helpers/segmentService/segmentFormatData";
import { IUser } from "interfaces";
import { USER_TYPES } from "pages/Users/consts";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { UserService } from "services/user/UserService";
import { StatusEnum } from "pages/UserDetails/Components/Status/types";
import { UserTableEmpty } from "../UserTableEmpty/UserTableEmpty";
import { UserListSkeleton } from "../UserTableSkeleton/UserTableSkeleton";
import {
  StyledStatus,
  StyledStatusContainer,
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableContainer,
  StyledTableRow,
} from "./styles";

interface IProp {
  country: string;
  value: string;
  selectedApps: string[];
  userType: string;
}

export const UserTable = (props: IProp): JSX.Element => {
  const intl = useIntl();
  const { country, value, selectedApps, userType } = props;
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [data, setData] = useState<IUser[]>([]);
  const history = useHistory();
  const isUserTypeCustomer = userType === USER_TYPES.CUSTOMER;
  const handleRowClick = (user: IUser, index: number) => {
    const trackData = SegmentFormatData.formatUserClicked({
      userCounts: data?.length || 0,
      clickedItemPosition: index + 1,
    });
    SegmentService.userClicked(trackData, country);

    setSelectedRows([user.id]);

    history.push({
      pathname: "/users/user-details",
      search: new URLSearchParams({
        value: user.id,
        country: user.country,
        appName: user.appName,
      }).toString(),
    });
  };

  const handleCheckboxClick = async (
    accountId: string,
    e: React.MouseEvent<HTMLElement>
  ) => {
    e.stopPropagation();
    const isRowAlreadySelected = selectedRows.includes(accountId);
    if (!isRowAlreadySelected) setSelectedRows((prev) => [...prev, accountId]);
    else setSelectedRows((prev) => prev.filter((id) => id !== accountId));
  };

  useEffect(() => {
    setIsFetching(true);
    setData([]);
    const userService = new UserService();
    const fetchData = () => {
      Promise.allSettled(
        (selectedApps as ApplicationKeys[]).map((appName: ApplicationKeys) =>
          userService.searchUsers(country, { searchQuery: value, appName })
        )
      )
        .then((results) => {
          const promisesResults = results.filter(
            (result) => result.status === "fulfilled"
          );
          const values = promisesResults.map(
            (appNameData) => appNameData.value
          );
          setData(values.flat());
        })
        .finally(() => setIsFetching(false));
    };
    fetchData();
  }, [country, selectedApps, value]);

  useEffect(() => {
    if (value && isFetching) {
      const dataAnalytics = SegmentFormatData.formatUserSearchCriteria(value);
      SegmentService.userSearchCriteria(dataAnalytics);
    }
    if (value && !isFetching) {
      const dataAnalytics = SegmentFormatData.formatUserListViewed({
        country,
        search_criteria: value,
        users: data,
      });
      SegmentService.userListViewed(dataAnalytics);
    }
  }, [value, country, data, isFetching]);

  const SkeletonTable = () => (
    <>
      <StyledTableContainerHeader>
        <DotSpinner /> {intl.formatMessage({ id: "userPage_loadingResults" })}
      </StyledTableContainerHeader>
      <StyledTableContainer>
        <StyledTable
          size="small"
          stickyHeader
          aria-label="sticky table"
          data-testid="user-table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell align="left" width="300px">
                {intl.formatMessage({ id: "userPage_tableColumnNameUserList" })}
              </StyledTableCell>
              <StyledTableCell align="left" width="240px">
                {intl.formatMessage({
                  id: "userPage_tableColumnEmailUserList",
                })}
              </StyledTableCell>
              <StyledTableCell align="left">
                {intl.formatMessage({
                  id: "userPage_tableColumnPhoneUserList",
                })}
              </StyledTableCell>
              {isUserTypeCustomer ? (
                <StyledTableCell align="left">Accounts</StyledTableCell>
              ) : (
                <StyledTableCell align="left">Application</StyledTableCell>
              )}
              <StyledTableCell align="left">
                {intl.formatMessage({
                  id: "userPage_tableColumnStatusUserList",
                })}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <UserListSkeleton active={false} />
        </StyledTable>
      </StyledTableContainer>
    </>
  );

  function getStatus(user: IUser) {
    return user.accountEnabled ? user.status : StatusEnum.BLOCKED;
  }

  return (
    <>
      {isFetching && <SkeletonTable />}
      {data.length > 0 && !isFetching && (
        <>
          <StyledTableContainerHeader data-testid="result counter">
            {data &&
              intl.formatMessage(
                { id: "userPage_results" },
                { amountOfResults: data.length }
              )}
          </StyledTableContainerHeader>
          <StyledTableContainer>
            <StyledTable
              size="small"
              stickyHeader
              aria-label="sticky table"
              data-testid="user-table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell />
                  <StyledTableCell align="left" width="300px">
                    {intl.formatMessage({
                      id: "userPage_tableColumnNameUserList",
                    })}
                  </StyledTableCell>
                  <StyledTableCell align="left" width="240px">
                    {intl.formatMessage({
                      id: "userPage_tableColumnEmailUserList",
                    })}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {intl.formatMessage({
                      id: "userPage_tableColumnPhoneUserList",
                    })}
                  </StyledTableCell>
                  {isUserTypeCustomer ? (
                    <StyledTableCell align="left">Accounts</StyledTableCell>
                  ) : (
                    <StyledTableCell align="left">Application</StyledTableCell>
                  )}
                  <StyledTableCell align="left">
                    {intl.formatMessage({
                      id: "userPage_tableColumnStatusUserList",
                    })}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <StyledTableBody>
                <>
                  {data.map((user: IUser, index) => (
                    <StyledTableRow
                      key={user?.id}
                      data-id={user?.id}
                      $isActive={selectedRows.includes(user.id)}
                      onClick={() => handleRowClick(user, index)}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          size="small"
                          data-checkbox
                          id={user.id}
                          data-testid={`user-checkbox-${user.id}`}
                          checked={selectedRows.includes(user?.id)}
                          onClick={(e) => handleCheckboxClick(user.id, e)}
                        />
                        <StyleAvatar data-avatar>
                          {initialLetters(user?.displayName)}
                        </StyleAvatar>{" "}
                      </StyledTableCell>
                      <StyledTableCell align="left" className="font-bold">
                        <Box component="span">{user?.displayName}</Box>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {user.contacts.filter(
                          (contact) => contact.type === "emailAddress"
                        ).length > 0 && (
                          <Box component="span">
                            {
                              user?.contacts.filter(
                                (contact) => contact.type === "emailAddress"
                              )[0].value
                            }
                          </Box>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Box component="span">
                          {user.contacts.filter(
                            (contact) => contact.type === "phoneNumber"
                          ).length > 0 && (
                            <>
                              {
                                user?.contacts.filter(
                                  (contact) => contact.type === "phoneNumber"
                                )[0].value
                              }
                            </>
                          )}
                        </Box>
                      </StyledTableCell>
                      {isUserTypeCustomer ? (
                        <StyledTableCell align="left">
                          {user.accountsCount}
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell align="left">
                          {
                            allApplications[
                              user.appName as keyof typeof allApplications
                            ]
                          }
                        </StyledTableCell>
                      )}
                      <StyledTableCell align="left">
                        <StyledStatusContainer>
                          <StyledStatus status={getStatus(user)} />
                          <p>
                            {intl.formatMessage({
                              id: `userDetailsPage_userStatus_${getStatus(
                                user
                              )}`,
                            })}
                          </p>
                        </StyledStatusContainer>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </>
              </StyledTableBody>
            </StyledTable>
          </StyledTableContainer>
        </>
      )}
      {data.length === 0 && <UserTableEmpty searched={value} />}
    </>
  );
};
