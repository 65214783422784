import { Clock } from "@admin-portal-shared-components/icons";
import {
  Breadcrumb,
  Button,
  Heading,
  LoadingBuzz,
  Paragraph,
  Tooltip,
} from "@hexa-ui/components";
import { ChartTree, Info } from "@hexa-ui/icons";
import { useEnvService, useHasPermission } from "admin-portal-shared-services";
import { useDialog } from "components/Dialog";
import { EmptyState } from "components/EmptyState";
import { StyledAppName } from "components/Shared/styles";
import { TOAST_SEVERITY, useToast } from "components/Toast";
import { SegmentService } from "helpers";
import { ApplicationKeys } from "helpers/Applications/applications";
import { SegmentFormatData } from "helpers/segmentService/segmentFormatData";
import { IAccount } from "interfaces/IAccount";
import { Env } from "interfaces/IEnv";
import queryString from "query-string";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { postAuditLogUserStatus } from "services/auditLog/AuditLogService";
import { BffService, IUserDetails } from "services/bff-service";
import { UserService } from "services/user/UserService";
import { AxiosError } from "axios";
import { AuditLogsList } from "./Components/AuditLogsList";
import { UserAccounts } from "./Components/UserAccounts/UserAccounts";
import { UserListInfo } from "./Components/UserInfo/UserInfo";
import { ModalEditUser } from "./Modais/ModalEditUser/ModalEditUser";
import {
  ButtonsContainer,
  CardContainer,
  Header,
  InnerCardContainer,
  LoaderContainer,
  StyledScreen,
  TitleModalAuditLogs,
} from "./styles";

const CANCEL_BUTTON = {
  WAS_PRESSED: true,
  WAS_NOT_PRESSED: false,
};

const userService = new UserService();
const bffService = new BffService();

export const UserDetails = (): JSX.Element => {
  const { openDialog, closeDialog } = useDialog();
  const { formatMessage } = useIntl();
  const { setToastOptions } = useToast();
  const hasUserBlockPermission = useHasPermission(
    ["IdentityFE.User.Block", "Identity.User.Block"],
    "AND"
  );
  const hasUserWritePermission = useHasPermission(
    ["IdentityFE.User.Write", "IdentityFE.GroupUser.Write"],
    "OR"
  );
  const canDisassociteAccountsOfUser = useHasPermission(
    "IdentityFE.UserPOC.Write"
  );

  const history = useHistory();
  const location = useLocation();
  const envService = useEnvService();
  const params = queryString.parse(location.search) as Record<string, string>;
  const { appName, value: userId, country: userCountry } = params;
  const [isLoading, setIsLoading] = React.useState(false);
  const [modalEditUser, setModalEditUser] = React.useState(false);
  const [user, setUser] = React.useState<IUserDetails | undefined>();
  const [hasPermissionByCountry, setHasPermissionByCountry] =
    React.useState<boolean>(true);
  const [userAccountList, setUserAccountList] = React.useState<IAccount[]>([]);
  const [hasRequestError, setHasRequestError] = React.useState<boolean>(false);
  const isChartButtonVisible = useHasPermission("IdentityFE.UserChart.Read");

  React.useEffect(() => {
    if (!appName && user?.application) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("appName", user?.application.toString());
      const newSearch = `?${searchParams.toString()}`;

      if (location.search !== newSearch) {
        window.history.pushState({}, "", newSearch);
      }
    }
  }, [appName, location.search, user?.application]);

  const handleOpenModalAuditLogs = (): void => {
    openDialog({
      title: (
        <TitleModalAuditLogs size="H2">
          Recent activity
          <Tooltip
            placement="right"
            text="To access all activities, go to BEES Audit logs"
          >
            <Info fr={undefined} size="large" />
          </Tooltip>
        </TitleModalAuditLogs>
      ),
      body: <AuditLogsList userEditedId={user?.id} />,
      onClose: closeDialog,
      onEscapeKeyDown: closeDialog,
      onInteractOutside: closeDialog,
    });
    SegmentService.recentActivitiesViewed();
  };

  const handleOpenModalBlockUser = (): void => {
    openDialog({
      title: (
        <Heading size="H2">
          {formatMessage({ id: "userDetailsPage_modalBlockUserTitle" })}
        </Heading>
      ),
      body: (
        <Paragraph>
          {formatMessage(
            { id: "userDetailsPage_modalBlockUserDescription" },
            { userName: user?.displayName?.split(" ")[0] }
          )}
        </Paragraph>
      ),
      onClose: () => handleCancelBlockUser(CANCEL_BUTTON.WAS_NOT_PRESSED),
      onEscapeKeyDown: () =>
        handleCancelBlockUser(CANCEL_BUTTON.WAS_NOT_PRESSED),
      onInteractOutside: () =>
        handleCancelBlockUser(CANCEL_BUTTON.WAS_NOT_PRESSED),
      actions: {
        btnSecundary: (
          <Button
            onClick={() => handleCancelBlockUser(CANCEL_BUTTON.WAS_PRESSED)}
            size="medium"
            variant="secondary"
          >
            {formatMessage({
              id: "userDetailsPage_modalBlockUserCancelButton",
            })}
          </Button>
        ),
        btnPrimary: (
          <Button onClick={handleBlockUser} size="medium" variant="primary">
            {formatMessage({ id: "userDetailsPage_modalBlockUserBlockButton" })}
          </Button>
        ),
      },
    });
  };
  const handleOpenModalUnblockUser = (): void => {
    openDialog({
      title: (
        <Heading size="H2">
          {formatMessage({ id: "unblockUserModal_title" })}
        </Heading>
      ),
      body: (
        <Paragraph>
          {formatMessage(
            { id: "unblockUserModal_subtitle" },
            { userName: user?.displayName?.split(" ")[0] }
          )}
        </Paragraph>
      ),
      onClose: () => handleCancelUnblockUser(CANCEL_BUTTON.WAS_NOT_PRESSED),
      onEscapeKeyDown: () =>
        handleCancelUnblockUser(CANCEL_BUTTON.WAS_NOT_PRESSED),
      onInteractOutside: () =>
        handleCancelUnblockUser(CANCEL_BUTTON.WAS_NOT_PRESSED),
      actions: {
        btnSecundary: (
          <Button
            onClick={() => handleCancelUnblockUser(CANCEL_BUTTON.WAS_PRESSED)}
            size="medium"
            variant="secondary"
          >
            {formatMessage({ id: "unblockUserModal_cancelButton" })}
          </Button>
        ),
        btnPrimary: (
          <Button onClick={handleUnblockUser} size="medium" variant="primary">
            {formatMessage({ id: "unblockUserModal_unblockButton" })}
          </Button>
        ),
      },
    });
  };

  const handleOpenModalEditUser = (): void => {
    setModalEditUser(true);
    SegmentService.userEditingStarted();
  };

  const getUserDetails = useCallback(async () => {
    const isCustomerUser = [
      "dtc_courier",
      "dtc_seller",
      "dtc_consumer",
      "b2b",
    ].includes(appName);

    try {
      setIsLoading(true);
      const response = await bffService.getUserDetails({
        country: isCustomerUser ? userCountry : undefined,
        userId,
        appName: appName as ApplicationKeys,
      });

      const { data, status } = response;

      if (status === 200) {
        setUser(data);
      }

      return response;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 403) {
          setHasPermissionByCountry(false);
        }
        setHasRequestError(true);
      }
    } finally {
      setIsLoading(false);
    }
  }, [appName, userId, userCountry]);

  React.useEffect(() => {
    getUserDetails().then((result) => {
      if (!result || !result?.data) {
        return;
      }
      const dataAnalytics = SegmentFormatData.formatUserDetailsViewed(
        result.data,
        {
          hasUserBlockPermission,
          hasUserWritePermission,
        }
      );
      SegmentService.userDetailsViewed(dataAnalytics);
    });
  }, [getUserDetails, hasUserBlockPermission, hasUserWritePermission]);

  const handleBlockUser = async (): Promise<void> => {
    setIsLoading(true);
    closeDialog();

    await userService
      .userBlock(userCountry, userId, true, user?.application)
      .then(() => {
        postAuditLogUserStatus({
          userCountry,
          status: "INACTIVE",
          editedUserId: userId,
          wasApprovedBySystem: envService.getEnv() !== Env.PROD,
        });
        setTimeout(() => {
          getUserDetails().then((response) => {
            const userDetail: IUserDetails = response.data;
            /** @TODO: Validate formatUserBlocked */
            const userBlockedData = SegmentFormatData.formatUserBlocked(
              userDetail,
              {
                canDisassociteAccountsOfUser,
                hasPermissionToBlock: hasUserBlockPermission,
                hasUserWritePermission,
              }
            );
            SegmentService.userBlocked(userBlockedData);
          });
        }, 500);

        setToastOptions({
          type: TOAST_SEVERITY.SUCCESS,
          showCloseButton: true,
          duration: 8000,
          message: formatMessage({
            id: "userDetailsPage_snackbarSuccessufullyBlockUser",
          }),
        });
      })
      .catch(() =>
        setToastOptions({
          type: TOAST_SEVERITY.ERROR,
          showCloseButton: true,
          duration: 8000,
          message: formatMessage({
            id: "userDetailsPage_snackbarFailureBlockUser",
          }),
        })
      );
  };

  const handleUnblockUser = async (): Promise<void> => {
    setIsLoading(true);
    closeDialog();

    await userService
      .userBlock(userCountry, userId, false, user?.application)
      .then(() => {
        postAuditLogUserStatus({
          userCountry,
          status: "ACTIVE",
          editedUserId: userId,
          wasApprovedBySystem: envService.getEnv() !== Env.PROD,
        });
        setToastOptions({
          type: TOAST_SEVERITY.SUCCESS,
          showCloseButton: true,
          duration: 8000,
          message: formatMessage({
            id: "userDetailsPage_snackbarSuccessufullyUnblockUser",
          }),
        });
        setTimeout(() => {
          getUserDetails().then((response) => {
            const userDetail: IUserDetails = response.data;
            /* @TODO: Validation formatUserBlocked */
            const userBlockedData = SegmentFormatData.formatUserBlocked(
              userDetail,
              {
                canDisassociteAccountsOfUser,
                hasPermissionToBlock: hasUserBlockPermission,
                hasUserWritePermission,
              }
            );
            SegmentService.userUnblocked(userBlockedData);
          });
        }, 500);
      })
      .catch(() =>
        setToastOptions({
          type: TOAST_SEVERITY.ERROR,
          showCloseButton: true,
          duration: 8000,
          message: formatMessage({
            id: "userDetailsPage_snackbarFailureUnblockUser",
          }),
        })
      )
      .finally(() => setTimeout(getUserDetails, 500));
  };

  const handleCancelBlockUser = (wasCancelButtonPressed: boolean) => {
    SegmentService.userBlockCanceled(wasCancelButtonPressed);
    closeDialog();
  };

  const handleCancelUnblockUser = (wasCancelButtonPressed: boolean) => {
    SegmentService.userUnblockCanceled(wasCancelButtonPressed);
    closeDialog();
  };

  const handleEditUser = () => {
    setModalEditUser(false);

    setToastOptions({
      type: TOAST_SEVERITY.SUCCESS,
      showCloseButton: true,
      duration: 6000,
      message: formatMessage({ id: "userDetailsPage_savedChangesSnackbar" }),
    });

    getUserDetails();
  };

  const handleCloseEditModal = () => {
    setModalEditUser(false);
    SegmentService.userEditingCanceled();
  };

  if (!hasPermissionByCountry) {
    return (
      <EmptyState
        title="You do not have permission to see this user."
        message="Please, get in touch with our support team."
      />
    );
  }

  if (hasRequestError) {
    return (
      <EmptyState
        title="An unexpected error occurred."
        message="Please, get in touch with our support team."
      />
    );
  }

  if (isLoading || !user) {
    return (
      <LoaderContainer>
        <LoadingBuzz />
      </LoaderContainer>
    );
  }

  const goToUserChart = () => {
    history.push({
      pathname: `/users/user-chart/${userId}/${userCountry}/${appName}`,
    });
    SegmentService.userChartViewed();
  };

  return (
    <StyledScreen>
      <StyledAppName>BEES User Management</StyledAppName>
      <Header>
        <div>
          <Breadcrumb.Root>
            <Breadcrumb.HomeItem asChild>
              <a href="/users">Home</a>
            </Breadcrumb.HomeItem>
            <Breadcrumb.Item asChild isCurrentPage>
              <a href="/users/user-details">
                {formatMessage({ id: "userDetailsPage_title" })}
              </a>
            </Breadcrumb.Item>
          </Breadcrumb.Root>
        </div>
        <ButtonsContainer>
          {user?.accountEnabled && hasUserBlockPermission && (
            <Button
              size="medium"
              variant="secondary"
              onClick={handleOpenModalBlockUser}
            >
              {formatMessage({ id: "userDetailsPage_blockButton" })}
            </Button>
          )}

          {!user?.accountEnabled && hasUserBlockPermission && (
            <Button
              size="medium"
              variant="secondary"
              onClick={handleOpenModalUnblockUser}
            >
              {formatMessage({ id: "userDetailsPage_unblockButton" })}
            </Button>
          )}
          {hasUserWritePermission && (
            <Button
              size="medium"
              variant="secondary"
              type="button"
              data-testid="edit user"
              onClick={handleOpenModalEditUser}
            >
              {formatMessage({ id: "userDetailsPage_editButton" })}
            </Button>
          )}
          <Button
            onClick={handleOpenModalAuditLogs}
            leading
            size="medium"
            variant="secondary"
            icon={Clock}
          >
            Recent activity
          </Button>
          {isChartButtonVisible ? (
            <Button
              leading
              size="medium"
              variant="secondary"
              icon={ChartTree}
              onClick={goToUserChart}
            >
              User Chart
            </Button>
          ) : null}
        </ButtonsContainer>
      </Header>

      <CardContainer>
        <InnerCardContainer>
          <UserListInfo
            user={user}
            isLoading={isLoading}
            accounts={userAccountList}
          />
          <UserAccounts
            user={user}
            userAccountList={(value) => {
              setUserAccountList(value);
            }}
          />
        </InnerCardContainer>
      </CardContainer>
      {modalEditUser && (
        <ModalEditUser
          user={user}
          country={userCountry}
          openModal={modalEditUser}
          handleButtonCloseModal={handleCloseEditModal}
          handleCancel={handleCloseEditModal}
          handleEditUser={handleEditUser}
        />
      )}
    </StyledScreen>
  );
};
