import {
  userDetailsContextType,
  userDetailsParamsType,
} from "contexts/useUserDetails/types";
import { IUser } from "interfaces";
import { useCallback, useMemo, useState } from "react";
import { UserService } from "services/user";

export const useGetUserDetails = (): userDetailsContextType => {
  const [userDetails, setUserDetails] = useState<IUser>();
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const fetchData = useCallback(async (params: userDetailsParamsType) => {
    setIsLoading(true);
    const { userId, country, appName } = params;
    const userService = new UserService();
    try {
      const { data } = await userService.userDetails(country, {
        userId,
        appName,
      });
      setUserDetails(data);
    } catch (err) {
      setError(err as Error);
    }
    setIsLoading(false);
  }, []);

  const userDetailsMemo = useMemo(
    () => ({ userDetails, error, isLoading, fetchData }),
    [userDetails, error, isLoading, fetchData]
  );
  return userDetailsMemo;
};
