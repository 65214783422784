import { Checkbox } from "@material-ui/core";
import styled, { css } from "styled-components";

interface Props {
  active?: boolean;
  disabled?: boolean;
}

const changeForAvatar = css`
  & [data-checkbox] {
    display: none;
  }

  & [data-avatar] {
    display: flex;
  }
`;

const changeForCheckbox = css`
  & [data-checkbox] {
    display: block;
  }

  & [data-avatar] {
    display: none;
  }
`;

const selected = css`
  ${changeForCheckbox}
  background-color: #f0ecfc;

  &:hover {
    background-color: #f0ecfc;
  }
`;

const fieldDisabled = css`
  color: rgba(0, 0, 0, 0.3);
  pointer-events: none;
`;

export const AccountContainer = styled.div`
  margin-top: 1.5rem;
`;

export const AccountHeader = styled.div`
  margin: 0 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  & h3 {
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    color: rgba(0, 0, 0, 0.9);
    line-height: 1.5rem;
    margin-bottom: 1rem;

    & > span {
      display: flex;
      align-items: center;
      justify-content: center;
      font: 600 0.75rem/1.125rem "Work sans", sans-serif;
      width: 1.5rem;
      height: 1.5rem;
      color: rgba(0, 0, 0, 0.9);
      background-color: #f0ecfc;
      border-radius: 50%;
      margin-left: 0.25rem;
    }
  }
`;

export const AccountHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SelectAll = styled.label<Props>`
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.006rem;
  color: rgba(0, 0, 0, 0.9);

  ${({ disabled }) => disabled && fieldDisabled}
`;

export const ButtonRemove = styled.button`
  font: 400 0.875rem/1rem "Work Sans", sans-serif;
  background-color: #000;
  color: #fff;
  border: 0;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
`;

export const AccountList = styled.ul`
  list-style: none;
`;

export const AccountListItem = styled.li<Props>`
  width: 100%;
  height: 4.5rem;
  display: flex;
  padding: 0 2rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  transition: background-color 200ms linear;

  ${changeForAvatar}

  & div {
    margin-left: 1rem;

    & h4 {
      font: 600 1rem/1.5rem "Work sans", sans-serif;
      letter-spacing: 0.006rem;
      color: rgba(0, 0, 0, 0.9);
    }

    & p {
      font-size: 0.75rem;
      line-height: 1.25rem;
      letter-spacing: 0.006rem;
      color: rgba(0, 0, 0, 0.55);
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);

    ${changeForCheckbox}
  }

  ${({ active }) => active && selected}
`;

export const EmptySpace = styled.div`
  color: rgba(0, 0, 0, 0.55);
  font: 400 0.875rem/1.25rem "Work sans";
  margin: 1rem 1rem;
`;

export const CustomCheckbox = styled(Checkbox)`
  svg {
    fill: #000;
    color: #fff;
  }
`;
