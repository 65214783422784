import { MessageMap } from "./types";

export const ptBR: MessageMap = {
  disassociateButton_dialog_body:
    "Este usuário não poderá mais comprar para {amountOfAccounts, plural, one {esta conta.} other {as contas selecionadas.}}",
  disassociateButton_dialog_cancelButton: "Cancelar",
  disassociateButton_dialog_confirmButton: "Excluir",
  disassociateButton_dialog_title:
    "Excluir {amountOfAccounts, plural, one {esta conta} other {estas contas}}?",
  editUSerModal_fieldIsRequiredError: "Este campo é obrigatório",
  editUserModal_addNewSupervisorButton: "Adicione supervisor",
  editUserModal_cancelButton: "Cancelar",
  editUserModal_invalidEmailError: "Insira um e-mail válido.",
  editUserModal_managerEqualSupervisorError:
    "Não é possível atribuir ao mesmo usuário as funções de supervisor e gerente",
  editUserModal_managerFieldLabel: "Gerenciado por",
  editUserModal_managerFieldPlaceholder: "Insira o e-mail do gerente",
  editUserModal_nonExistentEmailError:
    "Este e-mail não existe ou não pôde ser validado.",
  editUserModal_optionalField: "opcional",
  editUserModal_pendingChangesSnackbar: "Existem alterações pendentes",
  editUserModal_requiredField: "obrigatório",
  editUserModal_saveButton: "Salvar alterações",
  editUserModal_supervisorFieldLabel: "Supervisor",
  editUserModal_supervisorFieldPlaceholder: "Insira o e-mail do supervisor",
  editUserModal_title: "Editar informações de usuário",
  test_missing: "Apenas opção em português brasileiro disponível",
  test_translate: "Tradução",
  unblockUserModal_cancelButton: "Cancelar",
  unblockUserModal_subtitle:
    "{userName} terá acesso a todas as plataformas do BEES.",
  unblockUserModal_title: "Desbloquear acesso do usário?",
  unblockUserModal_unblockButton: "Desbloquear",
  userAccounts_emptyState: "Nenhuma conta associada a este usuário.",
  userAccounts_list_accountId: "Account ID {accountId}",
  userAccounts_selectAll: "Selecionar todas",
  userAccounts_snackbarFailureRemoveAccounts:
    "Não foi possível excluir {amountOfAccounts, plural, one {# a conta} other {# as contas}}",
  userAccounts_snackbarFailureToRemoveAnyAccounts:
    "Não foi possível excluir a(s) conta(s)",
  userAccounts_snackbarSuccessfullyRemoveAccounts:
    "{amountOfAccounts, plural, one {# conta excluída} other {# contas excluídas}} com sucesso",
  userAccounts_title: "Contas associadas",
  userDetailsPage_blockButton: "Bloquear",
  userDetailsPage_customer: "Cliente",
  userDetailsPage_disassociateButton: "Excluir",
  userDetailsPage_dtc: "DTC",
  userDetailsPage_editButton: "Editar",
  userDetailsPage_editUserModalCancelButton: "Cancelar",
  userDetailsPage_editUserModalDescriptionCompanyIds:
    "Use vírgulas para separar valores",
  userDetailsPage_editUserModalLabelCompanyIds: "IDs da empresa (opcional)",
  userDetailsPage_editUserModalLabelPermissionGroups: "Grupos permitidos",
  userDetailsPage_editUserModalLabelSupportedCountries: "Países permitidos",
  userDetailsPage_editUserModalSaveChangesButton: "Salvar mudanças",
  userDetailsPage_hub: "ONE",
  userDetailsPage_modalBlockUserBlockButton: "Bloquear",
  userDetailsPage_modalBlockUserCancelButton: "Cancelar",
  userDetailsPage_modalBlockUserDescription:
    "{userName} perderá o acesso a todas as plataformas do BEES.",
  userDetailsPage_modalBlockUserTitle: "Bloquear acesso do usuário?",
  userDetailsPage_savedChangesSnackbar:
    "Alterações de informações de usuário salvas.",
  userDetailsPage_snackbarFailureBlockUser:
    "Não foi possível bloquear o usuário no momento",
  userDetailsPage_snackbarFailureUnblockUser:
    "Não foi possível desbloquear o usuário momento",
  userDetailsPage_snackbarSuccessufullyBlockUser:
    "Usuário bloqueado com sucesso",
  userDetailsPage_snackbarSuccessufullyUnblockUser:
    "Usuário desbloqueado com sucesso",
  userDetailsPage_title: "Detalhes do usuário",
  userDetailsPage_unblockButton: "Desbloquear",
  userDetailsPage_userInfoLabelBdrId: "BDR ID",
  userDetailsPage_userInfoLabelCompanyIds: "IDs da empresa",
  userDetailsPage_userInfoLabelCountry: "País",
  userDetailsPage_userInfoLabelCreatedAt: "Criado em",
  userDetailsPage_userInfoLabelDeliveryCenterIds: "Delivery center ID",
  userDetailsPage_userInfoLabelEmail: "E-mail",
  userDetailsPage_userInfoLabelLastInvitedAt: "Último convite em",
  userDetailsPage_userInfoLabelLogisticOperatorIds: "Logistic operator ID",
  userDetailsPage_userInfoLabelPermissionGroups: "Grupos permitidos",
  userDetailsPage_userInfoLabelPhone: "Telefone",
  userDetailsPage_userInfoLabelRegisteredAt: "Registrado em",
  userDetailsPage_userInfoLabelRouteId: "Route ID",
  userDetailsPage_userInfoLabelSupportedCountries: "Países permitidos",
  userDetailsPage_userInfoLabelUserId: "ID do usuário",
  userDetailsPage_userInfoLabelUserStatus: "Status do usuário",
  userDetailsPage_userStatus_ACTIVE: "Ativo",
  userDetailsPage_userStatus_BLOCKED: "Bloqueado",
  userDetailsPage_userStatus_DEACTIVATED: "Desativado",
  userDetailsPage_userStatus_PENDING: "Pendente",
  userDetailsPage_userInfoTooltipCopy: "Copiar",
  userPage_addUsers: "Adicionar usuários",
  userPage_administrator: "Administrador",
  userPage_allCountries: "Todos os países",
  userPage_allRecentActivities: "Todas as atividades recentes",
  userPage_category: "Categoria",
  userPage_clearAll: "Limpar tudo",
  userPage_country: "País",
  userPage_customer: "Cliente",
  userPage_dragFileHere: "Arraste um arquivo aqui ou",
  userPage_exportCSV: "Exportar CSV",
  userPage_filterAccessDenied:
    "Você não tem acesso a um ou mais filtros aplicados. Todos os filtros foram redefinidos.",
  userPage_filterBy: "Filtrar por",
  userPage_loadingResults: "Carregando resultados...",
  userPage_noFiltersApplied: "Nenhum filtro aplicado",
  userPage_recentSearches: "Buscas recentes",
  userPage_results:
    "Mostrando {amountOfResults, plural, one {# resultado} other {# resultados}}",
  userPage_search: "Buscar por ID, nome, e-mail ou telefone",
  userPage_searchMessage:
    "Use o campo de pesquisa acima para encontrar um usuário específico.",
  userPage_selectFile: "Selecione",
  userPage_settings: "Ajustes",
  userPage_showMore: "Mostrar mais",
  userPage_tableColumnEmailUserList: "E-mail",
  userPage_tableColumnNameUserList: "Nome",
  userPage_tableColumnPhoneUserList: "Telefone",
  userPage_tableColumnStatusActiveUserList: "Ativo",
  userPage_tableColumnStatusBlockedUserList: "Bloqueado",
  userPage_tableColumnStatusUserList: "Status",
  userPage_title: "Usuários",
  userPage_wholesalerBranch: "Atacadista/Filial",
  userTableEmpty_description:
    'Nenhum usuário encontrado com o nome "{searched}". Experimente mudar os filtros.',
  userTableEmpty_header: "Nenhum resultado encontrado",
};
