import { Skeleton } from "@material-ui/lab";
import styled from "styled-components";

export const StyledRectangleSkeleton = styled(Skeleton)`
  transform: scale(1, 1);
  border-radius: 0.5rem;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`;

export const StyledCircleSkeleton = styled(Skeleton)`
  transform: scale(1, 1);
  border-radius: 50%;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`;
