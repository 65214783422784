import { createGenerateClassName, StylesProvider } from "@material-ui/core";
import { PropsPerClusterProvider } from "admin-portal-shared-services";
import { AppConfig } from "AppConfig";
import { DialogProvider } from "components/Dialog";
import { ToastProvider } from "components/Toast";
import React, { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { Spinner } from "supplier-portal-shared-components";
import { GlobalStyle } from "./global/global";
import { IntlProvider } from "./i18n/provider";

type AppProps = {
  segmentKey: Record<"default" | "Restricted_US", string>;
};

export const App = (appProps: AppProps): JSX.Element => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });
  const generateClassName = createGenerateClassName({
    productionPrefix: "admin-portal-identity-user-mfe",
    seed: "admin-portal-identity-user-mfe",
  });

  return (
    <PropsPerClusterProvider propValues={appProps}>
      <QueryClientProvider client={queryClient}>
        <IntlProvider>
          <GlobalStyle />
          <StylesProvider generateClassName={generateClassName}>
            <BrowserRouter>
              <Suspense
                fallback={<Spinner show dataTest="transition-spinner" />}
              >
                <ToastProvider>
                  <DialogProvider>
                    <AppConfig />
                  </DialogProvider>
                </ToastProvider>
              </Suspense>
            </BrowserRouter>
          </StylesProvider>
        </IntlProvider>
      </QueryClientProvider>
    </PropsPerClusterProvider>
  );
};
